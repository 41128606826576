const uiGrid = require('ng-file-upload');

(function(){
	'use strict';

	angular.module('TrainingPostApplication', [
		'ngFileUpload',
		// AIT modules
		'LocalConfig', 'RemoteConfig'
	])

    .controller('TrainingPostApplicationController', [
    	'$scope', '$log', '$resource', '$routeParams', 'Upload',
    	'APP_CONFIG', 'ServerRemoteConfigFactory', 'UrlConfigFactory','$sce',
		function($scope, $log, $resource, $routeParams, Upload, APP_CONFIG, ServerRemoteConfigFactory, UrlConfigFactory,$sce) {

			// Init Vars
			var FILES_MIN = 1;
			var FILES_MAX = 8;


			var uploadUrl = UrlConfigFactory.getTrainingPostApplicationUploadUrl() + '?orgUnitId=' + $routeParams.organisationId;
			$scope.organisationName = $routeParams.organisationName;

			$scope.dropSupported = false;

			// Permission Check
			$scope.activityOverlay = true;
			$scope.userEditUsers = false;

			var userPermissionCheckResource = UrlConfigFactory.getUserPermissionCheckResource();
			var paramsPermission = {orgUnitId:$routeParams.organisationId, permission:"EDIT_USERS"};
			userPermissionCheckResource.get(paramsPermission, function( result ) {
				if (result.ok && result.payload) {
					$scope.userEditUsers = true;
				}
				$scope.activityOverlay = false;
			});


			// Check User Roles
			// Für später, wenn nicht nur Sys-Admins etc. bearbeiten dürfen
			// Statt $scope.userEditUsers wird dann $scope.userAddTraininpost verwendet
			$scope.submit = function() {
				var files =[];
				var continueForEach = true;

				$scope.isLoading = true;

				console.log("submit applicationFiles=", $scope.applicationFiles );
				angular.forEach($scope.applicationFiles, function(file, fileKey) {
					if (continueForEach) {
						if (file.selected) {
							files.push(file.selected[0]);
						} else if (file.required) {
							// throw error;
							$log.error('Required Files Missing!');
							continueForEach = false;
						}
					}
				});

		        if (continueForEach && files && files.length >= FILES_MIN && files.length <= FILES_MAX) {
		                Upload.upload({
		                    url: uploadUrl,
		                    file: files,
		                    method: 'POST',
		                    fileFormDataName: ['file0', 'file1', 'file2', 'file3', 'file4', 'file5', 'file6', 'file7']
		                }).then(function (response) {
			                $scope.isLoading = false;
							$scope.isFinished = true;
			                if (response.data.ok) {
		                    	$log.debug("Upload erfolgreich");
		                    }
		                    else if (response.data.errors){
								$scope.errorMessage = $sce.trustAsHtml( response.data.errors.join("<br>") );
		                    }
		                    else {
			                    $scope.errorMessage = $sce.trustAsHtml('Bei der Übermittlung ist ein unbekannter Fehler aufgetreten.<br>Bitte versuchen Sie es erneut. Falls dieser Fehler wiederholt auftreten sollte, wenden Sie sich bitte an Ihren Systemadministrator.');
		                    }
		                },function ( resp ) {
			                $scope.isLoading = false;
							$scope.isFinished = true;
							console.log("error: resp=", resp);
			                $scope.errorMessage = $sce.trustAsHtml('Bei der Übermittlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.<br>Falls dieser Fehler wiederholt auftreten sollte, wenden Sie sich bitte an Ihren Systemadministrator.');
		                },function ( evt ) {
		                    console.log("upload event:" , evt);
                        });
		        }
		        else {
			        $scope.isLoading = false;
					$scope.isFinished = true;
					$scope.errorMessage = $sce.trustAsHtml('Sie haben nicht alle erforderlichen Unterlagen übermittelt.');
		        }
		    };

		    $scope.reset = function(form) {
				if (form) {
					form.$setPristine();
					form.$setUntouched();
				}

				$scope.applicationFiles = {
					0 : {
						label : 'Antrag',
						selected : null,
						rejected : null,
						check : '',
						required : true
					},
					1 : {
						label : 'Nachweis der Personal- und Abteilungsstruktur',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},
					2 : {
						label : 'Ausbildungskonzept',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},
					3 : {
						label : 'Rahmenbedingungen von abteilungs- oder organisationseinheitenübergreifender Tätigkeit',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},
					4 : {
						label : 'Nachweis für die Übernahme von Tätigkeiten gemäß §15 GuKG',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},

					5 : {
						label : 'Nachweis des medizinischen Leistungsspektrums',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},
					6 : {
						label : 'gegebenenfalls: Kooperationsvereinbarung',
						selected : null,
						rejected : null,
						check : '',
						required : false
					},
					7 : {
						label : 'Bestätigungsnachweis Sonderkrankenanstalten',
						selected : null,
						rejected : null,
						check : '',
						required : false
					}
				};

				$scope.errorMessage = $sce.trustAsHtml('');
				$scope.isLoading = false;
				$scope.isFinished = false;
			};

			$scope.checkFile = function(fileKey) {
				if($scope.applicationFiles[fileKey].selected && $scope.applicationFiles[fileKey].selected[0] && $scope.applicationFiles[fileKey].selected[0].size < 5242880) {
					$scope.applicationFiles[fileKey].check = 'ok';
				}
				else if ($scope.applicationFiles[fileKey].rejected && $scope.applicationFiles[fileKey].rejected[0] && $scope.applicationFiles[fileKey].rejected[0].size < 5242880) {
					$scope.applicationFiles[fileKey].check = 'filetype';
				}
				else if ($scope.applicationFiles[fileKey].rejected && $scope.applicationFiles[fileKey].rejected[0] && $scope.applicationFiles[fileKey].rejected[0].size > 5242880) {
					$scope.applicationFiles[fileKey].check = 'filesize';
				}
				else {
					$scope.applicationFiles[fileKey].check = '';
				}
			};

			// Init Form
			$scope.reset();
		}
	])

	;

})();
