// ASV Bootstrap Components

(function(){
    'use strict';

    angular.module('Bootstrap',[
    	'ui.bootstrap',
    	'LocalConfig'
    ])

    // Set Bootstrap datePicker config options
    .config([
    	'APP_CONFIG', 'datepickerPopupConfig',
    	function(APP_CONFIG, datepickerPopupConfig) {
			datepickerPopupConfig.datepickerPopup = APP_CONFIG.dateFormat;
			datepickerPopupConfig.currentText = "Heute";
			datepickerPopupConfig.clearText = "Löschen";
			datepickerPopupConfig.closeText = "Schließen";
			datepickerPopupConfig.closeOnDateSelection = true;
    	}
    ])

    .config([
    	'datepickerConfig',
    	function(datepickerConfig) {
			datepickerConfig.datepickerMode = "day";
			datepickerConfig.showWeeks = false;
			datepickerConfig.startingDay = 1;
    	}
    ])

    // BUGFIX FOR INITIALLY DISPLAYING WRONG DATE FORMAT
    // https://github.com/angular-ui/bootstrap/issues/2659
	.directive('datepickerPopup',
		function (){
			return {
				restrict: 'EAC',
				require: 'ngModel',
				link: function(scope, element, attr, controller) {
					//remove the default formatter from the input directive to prevent conflict
					controller.$formatters.shift();
				}
			};
		}
	)

    // Set Custom Templates for Bootstrap
    .config([
    	'$provide',
        function ($provide) {
          $provide.decorator('accordionGroupDirective', ['$delegate', function($delegate) {
            $delegate[0].templateUrl = '/modules/asv/Components/Bootstrap/Templates/AccordionGroup.html';
            return $delegate;
          }]);

          $provide.decorator('datepickerPopupWrapDirective', ['$delegate', function($delegate) {
            $delegate[0].templateUrl = '/modules/asv/Components/Bootstrap/Templates/DatePickerPopup.html';
            return $delegate;
          }]);

          $provide.decorator('daypickerDirective', ['$delegate', function($delegate) {
            $delegate[0].templateUrl = '/modules/asv/Components/Bootstrap/Templates/DatePickerDay.html';
            return $delegate;
          }]);

          $provide.decorator('monthpickerDirective', ['$delegate', function($delegate) {
            $delegate[0].templateUrl = '/modules/asv/Components/Bootstrap/Templates/DatePickerMonth.html';
            return $delegate;
          }]);

          $provide.decorator('yearpickerDirective', ['$delegate', function($delegate) {
            $delegate[0].templateUrl = '/modules/asv/Components/Bootstrap/Templates/DatePickerYear.html';
            return $delegate;
          }]);
        }
    ])

    // Common Toggle Instance Factory
    .factory('CommonToggleFactory', [
    	function() {
	        // Init
	        function CommonToggleFactory() {
	            this.state = false;
	        }

	        CommonToggleFactory.prototype = {
	            // Change State
	            toggleState: function($event) {
	                $event.preventDefault();
	                $event.stopPropagation();

	                this.state = !this.state;
	            }
	        };

	        return CommonToggleFactory;
    	}
    ])

    ;

})();