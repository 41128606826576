// ASV Start Screen
(function(){
    'use strict';

    angular.module('LinkGenerator',[
    	'LocalConfig'
    ])

    // Init Controller
    .factory('LinkGeneratorFactory', [
	    '$location',
    	'ROUTE_CONFIG',
		function($location, ROUTE_CONFIG) {

		return {
			getLocation : function(target, params) {

				if (target && target !== '') {
					var targetPath = ROUTE_CONFIG[target].path;

					for(var param in params)
			        {
				      var regExp = new RegExp('(:'+param+'\\??)', "gi");

						/* mh: we have to remove slashes since Routing does not like slashes in params, even when escaped */
			          targetPath = targetPath.replace(regExp, encodeURIComponent( (String( params[param])).replace(/\//g, '')));
			        }

					// Remove unused and optional Params
			        targetPath = this.getBaseUrl(targetPath);

					return '' + targetPath;
				}

				return '';
			},
			getUrl : function(target, params) {
				return '#' + this.getLocation(target, params);
			},
			getBaseUrl : function (targetPath) {
				// Remove unused and optional Params
				return targetPath.replace(/(\/:\w+\??)/gi, '');
			},
			isActiveLink : function(target, params) {
				return $location.path().indexOf(this.getLocation(target, params)) > -1;
			}
		};
	}])

	;

})();