(function(){
    'use strict';

    angular.module('OverviewUserData',[
    	'ngResource',
    	'LocalConfig','RemoteConfig','FilterFunctions'
    ])

    .factory('OverviewUserDataFactory', [
        function() {
			var data = {};

			return {
				getOrgUnitId : function() {
    				return data.orgUnitId;
    			},
    			setOrgUnitId : function(orgUnitId) {
    				data.orgUnitId = orgUnitId;
    			},
    			getDate : function() {
    				return data.date;
    			},
    			setDate : function(date) {
    				data.date = date;
    			},
				isTrainingPostInactive : function(trainingPost) {
					var date = data.date;
					var start = new Date(trainingPost.validFrom);
					start.setHours(0, 0, 0, 0);
					if (date < start) {
						return true;
					}
					if (trainingPost.validUntil) {
						var end = new Date(trainingPost.validUntil);
						end.setHours(0, 0, 0, 0);
						return date > end;
					}
					return false;
				},
    			getEditMode : function() {
    				return data.editMode;
    			},
    			setEditMode : function(editMode) {
    				data.editMode = editMode;
    			},
    			getFilterItems : function() {
    				return data.filterItems;
    			},
    			setFilterItems : function(filterItems) {
    				data.filterItems = filterItems;
    			},
    			getFilterNumItems : function() {
    				return data.filterNumItems;
    			},
    			setFilterNumItems : function(filterNumItems) {
    				data.filterNumItems = filterNumItems;
    			},
    			getTrainingPostState : function() {
    				return data.trainingPostState;
    			},
    			setTrainingPostState : function(trainingPostState) {
    				data.trainingPostState = trainingPostState;
    			},
    			getSectionState : function() {
    				return data.sectionState;
    			},
    			setSectionState : function(sectionState) {
    				data.sectionState = sectionState;
    			},
    			reset : function() {
	    			data = {
						orgUnitId : 0,
						date : new Date(),
						editMode : false,
						filterItems : {},
						filterNumItems : 0,
						trainingPostState : {},
						sectionState : {}
					};
    			}
    		};
    	}
    ]);

})();
