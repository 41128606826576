(function(){
    'use strict';

	angular.module('TrainingPosts', [
		'ui.bootstrap',
		'Bootstrap','LocalConfig','FilterFunctions','RemoteConfig','OverviewData', 'OverviewFilter', 'OverviewModals','asv.Progressbar', 'Analytics'
	])

	.controller('TrainingPostController', [
		'$rootScope', '$scope', '$sce', '$filter', '$modal', '$log', '$timeout', '$location',
		'APP_CONFIG', 'SERVER_CONFIG', 'FilterFunctionsService', 'ServerRemoteConfigFactory', 'OverviewDataFactory', 'OverviewFilterFactory', 'UserRemoteConfigFactory', 'CommonToggleFactory','LinkGeneratorFactory', 'OverviewUserDataFactory','AnalyticsFactory',
		function($rootScope, $scope, $sce, $filter, $modal, $log, $timeout, $location, APP_CONFIG, SERVER_CONFIG, FilterFunctionsService, ServerRemoteConfigFactory, OverviewDataFactory, OverviewFilterFactory, UserRemoteConfigFactory,CommonToggleFactory,LinkGeneratorFactory,OverviewUserDataFactory,AnalyticsFactory) {

			// Load dataFactory
	        var dataFactory = OverviewDataFactory;

	        // Load OverviewFilter
	        $scope.overviewFilter = OverviewFilterFactory;
			$scope.overviewUserDataFactory = OverviewUserDataFactory;

	        // Load OverviewUserFactory Values
	        $scope.trainingPostState = OverviewUserDataFactory.getTrainingPostState();

			// Set Defaults
			$scope.dateFormat = APP_CONFIG.dateFormat;
			$scope.dateFormatLong = APP_CONFIG.dateFormatLong;

			//$scope.editMode = false;
			$scope.occupancyCommentSuggestions = SERVER_CONFIG.occupancyCommentSuggestions;
			$scope.plussingTrainingPost = false;

			$scope.isInactive = function(trainingPost) {
				return $scope.overviewUserDataFactory.isTrainingPostInactive(trainingPost);
			};

			// Load OverviewUserFactory Values
	        $scope.sectionState = OverviewUserDataFactory.getSectionState();

	        function initTrainingPost(trainingPost) {
		        if (!$scope.trainingPostState[trainingPost.id]) {
			        $scope.trainingPostState[trainingPost.id] = {
				        isOpen : false
				    };
			    }

		        trainingPost.firstBuild = false;
		        trainingPost.occupancies = [];
				trainingPost.occupancyUpdates = null;

                trainingPost.validationResultCount = {
	                'OK' : 0,
	                'INFO' : 0,
	                'WARNING' : 0,
	                'ERROR' : 0,
	                'FATAL' : 0,
	                'PENDING' : 0
	            };
	        }

	        function runFilter(selectedItems) {
				// check if we have a filter with expandOnFilter=true
				var adaptExpansionStateOfTrainingPostsAndSections = false;
				angular.forEach(selectedItems,function( item ) {
					angular.forEach(item,function( filterArray, filterKey ) {
						for ( var i=0;i<filterArray.length;i++ ) {
							if ( filterArray[i].expandOnFilter ) {
								adaptExpansionStateOfTrainingPostsAndSections = true;
								break;
							}
						}
					});
				});
		        // Filter Occupancies
				angular.forEach($scope.trainingPosts, function(trainingPost, trainingPostsKey) {
					$scope.trainingPosts[trainingPostsKey].occupancies = $filter('OverviewFilter')($scope.trainingPosts[trainingPostsKey].occupancies ,selectedItems, 'occupancy');

					if ( adaptExpansionStateOfTrainingPostsAndSections ) {
						// Open TrainingPost if Occupancies have Result
						if (angular.isDefined(selectedItems.occupancy) && !$scope.trainingPosts[trainingPostsKey].occupancies.allHidden) {
							$scope.trainingPostState[$scope.trainingPosts[trainingPostsKey].id].isOpen = true;
							$scope.sectionState[$scope.section.id].isOpen = true;
						}
					}
				});
				if ( adaptExpansionStateOfTrainingPostsAndSections ) {
					// Store Open/Close States
					OverviewUserDataFactory.setTrainingPostState($scope.trainingPostState);
				}
				// Filter trainingPosts
				$scope.trainingPosts = $filter('OverviewFilter')($scope.trainingPosts,selectedItems,'trainingPost');
				if ( adaptExpansionStateOfTrainingPostsAndSections ) {
					// Open Section if TrainingPosts have Result
					if (angular.isDefined(selectedItems.trainingPost) && !$scope.trainingPosts.allHidden) {
						$scope.sectionState[$scope.section.id].isOpen = true;
					}

					// Store Open/Close States
					OverviewUserDataFactory.setSectionState($scope.sectionState);
				}
	        }

	        // Events
			$scope.$on('OverviewEditModeChanged', function (event, editMode) {
	             $scope.editMode = editMode;
	        });

			// Filter Occupancies & TrainingPosts
	        $scope.$on('OverviewFilterSettingsChanged', function (event, selectedItems) {
				runFilter(selectedItems);
		    });

            // Handle ValidationUpdates
            $scope.$on('ValidationUpdates', function (event) {
				angular.forEach($scope.trainingPosts, function(trainingPost, trainingPostsKey) {
	                trainingPost.validationResultCount = {
		                'OK' : 0,
		                'INFO' : 0,
		                'WARNING' : 0,
		                'ERROR' : 0,
		                'FATAL' : 0,
		                'PENDING' : 0
		            };
                    angular.forEach(trainingPost.occupancies, function(occupancy, occupancyKey) {
                        var validationEntryForThisOccupancy = dataFactory.getValidationByOccupancyId( occupancy.id );
                        occupancy.validation = validationEntryForThisOccupancy;
                        occupancy.displayValues = $scope.getOccupancyDisplayValues( occupancy );

                        if (occupancy.validation && occupancy.validation.status === 'COMPLETED') {
							trainingPost.validationResultCount[SERVER_CONFIG.violationCodes[occupancy.validation.maxSeverity]]++;
						}
						else if (occupancy.validation && occupancy.validation.status === 'PENDING') {
							trainingPost.validationResultCount.PENDING++;
						}
                    });
                });
                runFilter($scope.overviewFilter.filterExpressions);
			});

            $scope.$on('OccupancyUpdates', function (event, myOccupancyUpdates) {
				angular.forEach($scope.trainingPosts, function(myTrainingPost, trainingPostsKey) {
                    var occupancyUpdatesForThisTrainingPost = myOccupancyUpdates[myTrainingPost.id];
                    if (occupancyUpdatesForThisTrainingPost) {
                        $scope.updateTrainingPost(myTrainingPost, occupancyUpdatesForThisTrainingPost );
						if ( myTrainingPost.plusable ) {
							dataFactory.buildTrainingposts( false,$scope.section,null );
						}
                    }
                });
			});

			$scope.saveOpenState = function (trainingPostId, isOpen) {
				$scope.trainingPostState[trainingPostId].isOpen = isOpen;
				OverviewUserDataFactory.setTrainingPostState($scope.trainingPostState);
			};

            // If Initial Setup
	        $scope.updateTrainingPost = function(trainingPost, occupancyUpdates) {
		        var allocationDiff = 0;
		        var occupanciesUpdateDiff = 0;
		        var hasChanges = false;

		        if (trainingPost.firstBuild) {
					initTrainingPost(trainingPost);
				}
				var filterDate = $scope.overviewUserDataFactory.getDate();
				angular.forEach(occupancyUpdates, function(occupancyUpdate) {
					var occupancyIndex = null;
					var currentDate = $filter('date')(filterDate, APP_CONFIG.serverDateFormat);
					if (occupancyUpdate.status ==="ADD") {
						trainingPost.occupancies.push(occupancyUpdate.occupancy);
						allocationDiff += dataFactory.getAllocationDiff( occupancyUpdate.occupancy,currentDate);
						if (SERVER_CONFIG.occupancyStateCodes[occupancyUpdate.occupancy.status] === "DRAFT") {
							occupanciesUpdateDiff++;
						}
						hasChanges = true;
					}
					else if (occupancyUpdate.status ==="MODIFY") {
						occupancyIndex = FilterFunctionsService.getObjectIndexById(trainingPost.occupancies, occupancyUpdate.predecessor, 'id');
						if (occupancyIndex !== -1) {
							allocationDiff -= dataFactory.getAllocationDiff( trainingPost.occupancies[occupancyIndex],currentDate );
							if (SERVER_CONFIG.occupancyStateCodes[trainingPost.occupancies[occupancyIndex].status] === "DRAFT") {
								occupanciesUpdateDiff--;
							}
							$scope.initOccupancy(occupancyUpdate.occupancy);
							trainingPost.occupancies[occupancyIndex] = occupancyUpdate.occupancy;
							allocationDiff += dataFactory.getAllocationDiff(occupancyUpdate.occupancy,currentDate);

							if (SERVER_CONFIG.occupancyStateCodes[occupancyUpdate.occupancy.status] === "DRAFT") {
								occupanciesUpdateDiff++;
							}
							hasChanges = true;
						}
					}
					else if (occupancyUpdate.status ==="DELETE") {
						occupancyIndex = FilterFunctionsService.getObjectIndexById(trainingPost.occupancies, occupancyUpdate.occupancy.id, 'id');
						if (occupancyIndex !== -1) {
							allocationDiff -=  dataFactory.getAllocationDiff( trainingPost.occupancies[occupancyIndex], currentDate );
							trainingPost.occupancies.splice(occupancyIndex, 1);
							if (SERVER_CONFIG.occupancyStateCodes[occupancyUpdate.occupancy.status] === "DRAFT") {
								occupanciesUpdateDiff--;
							}
							hasChanges = true;
						}
					}
				});
				// Update Toolbar Values
                if (occupanciesUpdateDiff !== 0) {
	                var countData = {
		                'UPDATE' : occupanciesUpdateDiff
	                };
	                $rootScope.$broadcast('OverviewUpdateToolbarCount', countData);
                }
                // Reset Validation
                if (!trainingPost.firstBuild && hasChanges) {
                	dataFactory.resetValidationResult();
                }
				//else if (trainingPost.firstBuild) {
	            	runFilter($scope.overviewFilter.filterExpressions);
	            //}
	        };

	        $scope.initOccupancy = function(occupancy) {
		        occupancy.showComment = new CommonToggleFactory();
		        occupancy.inlineEdit =  {
			        fields : {
				        comment :{
					        value : '',
					        edit : false,
					        validate : function(value) {
						        return value;
					        }
				        },
				        to : {
					        value : null,
					        edit : false,
					        toggle : new CommonToggleFactory(),
					        validate : function (value) {
						        return $filter('date')(new Date(value),APP_CONFIG.serverDateFormat);
					        }
				        },
				        allocation : {
					        value : 0,
					        edit : false,
					        validate : function (value) {
						        return parseInt(value,10);
					        }
				        }
				    },
			        toggle : function ($event, occupancy, field) {
				        $event.preventDefault();
						$event.stopPropagation();

				        occupancy.inlineEdit.fields[field].edit = !occupancy.inlineEdit.fields[field].edit;

				        if (occupancy.inlineEdit.fields[field].edit) {
							occupancy.inlineEdit.fields[field].value = angular.copy(occupancy[field]);
						}
			        },
			        keyUp : function ($event, occupancy, field) {
				        // When ESC is pressed
				        if ($event.keyCode === 27) {
					        occupancy.inlineEdit.cancel($event, occupancy, field);
					    }
					    // When ENTER is pressed
					    else if ($event.keyCode === 13) {
						     occupancy.inlineEdit.submit($event, occupancy, field);
					    }
			        },
			        submit : function ($event, occupancy, field) {
				        var evalValue = occupancy.inlineEdit.fields[field].validate(occupancy.inlineEdit.fields[field].value);

				        if (evalValue !== occupancy[field]) {
				        	var updatedOccupany = angular.copy(occupancy);

							updatedOccupany[field] = evalValue;

					        var occupancyUpdatePromise = dataFactory.updateOccupancy(updatedOccupany);

					        // If Server Response then update List
			                occupancyUpdatePromise.$promise.then(function (result) {
			                    if (result.ok) {
			                        dataFactory.forcePolling();

			                        occupancy.inlineEdit.cancel($event, occupancy, field);
			                    }
							});
						}
						else {
							occupancy.inlineEdit.cancel($event, occupancy, field);
						}
			        },
			        cancel : function ($event, occupancy, field) {
					    occupancy.inlineEdit.fields[field].edit = false;
			        },
			        setValue : function (occupancy, field, value) {
				        occupancy.inlineEdit.fields[field].value = value;
			        }
		        };
	        };

	        $scope.getOccupancyDisplayValues = function(occupancy) {
	            var dispValues = {
		            'state' : '',
		            'isActive' : false,
		            'isDraft' : false,
		            'noEdit' : false
	            };
                var status = occupancy.status;
				if (occupancy.revocation === true && SERVER_CONFIG.occupancyStateCodes[status] === "DRAFT") {
					dispValues.state = 'revoked inactive info';
					dispValues.isActive = false;
					dispValues.isDraft = true;
					dispValues.noEdit = true;
				} else  if (SERVER_CONFIG.occupancyStateCodes[status] === "ACTIVE") {
		            dispValues.state = "active";
		            dispValues.isActive = true;
		            dispValues.isDraft = false;
		            dispValues.noEdit = false;
	            } else if (SERVER_CONFIG.occupancyStateCodes[status] === "DRAFT") {
	                dispValues.state = "info";
	                dispValues.isActive = false;
		            dispValues.isDraft = true;
		            dispValues.noEdit = false;
	            } else if (SERVER_CONFIG.occupancyStateCodes[status] === "DELETE") {
	                dispValues.state = 'revoked inactive';
	                dispValues.isActive = false;
		            dispValues.isDraft = false;
		            dispValues.noEdit = true;
	            }
				return dispValues;
	        };
			$scope.openTrainingPostHistory = function (trainingPost) {
				$location.path(
					LinkGeneratorFactory.getLocation(
						'history',
						{
							'type' : 'trainingPost',
							'id' : trainingPost.oakKey
						}
					)
				);
			};

			$scope.openOccupancyHistory = function (occupancy) {
				$location.path(
					LinkGeneratorFactory.getLocation(
						'history',
						{
							'type' : 'occupancy',
							'id' : occupancy.id
						}
					)
				);
			};

			$scope.openTraineeHistory = function (trainee) {
				$location.path(
					LinkGeneratorFactory.getLocation(
						'history',
						{
							'type' : 'trainee',
							'id' : trainee.oakKey
						}
					)
				);
			};

			$scope.openTrainingPostInfo = function (organisation, section, trainingPost) {
				// Build Data for Modal
		        var data = {
			        organisation : {
				        label : organisation.label,
				        oakKey : organisation.oakKey
				    },
			        section : {
				        label : section.label,
				        oakKey : section.oakKey
				    },
			        trainingPost : {
				        id: trainingPost.id,
				        oakKey : trainingPost.oakKey,
				        trainingPostType : trainingPost.trainingPostType,
						validFrom : trainingPost.validFrom,
						validUntil : trainingPost.validUntil,
						subject : trainingPost.subject,
						gradeOfRecognition: trainingPost.gradeOfRecognition,
						durationInMonths: trainingPost.durationInMonths,
						comment: trainingPost.comment,
						maxAllocation : trainingPost.maxAllocation
					}
		        };

				// Open Modal Instance
	            var TrainingPostInfoInstance = $modal.open({
	              templateUrl: '/modules/asv/Overview/OverviewModalsTrainingPostInfo.html',
	              controller: 'OverviewModalsInfoModalController',
	              size: 'md',
	              backdrop: true,
	              resolve: {
	                data: function () {
	                  return data;
	                }
	              }
	            });
	        };

			$scope.getTrainingPostStatusFragment = function( trainingPost ) {

				var htmlFragment =
				'<span class="status-icon trainingPost-status-icon fatal' +  (trainingPost.validationResultCount.FATAL ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-minus-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon error' +  (trainingPost.validationResultCount.ERROR ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-times-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon warning' +  (trainingPost.validationResultCount.WARNING ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-exclamation-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon info' +  (trainingPost.validationResultCount.INFO ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-info-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon ok' +  (trainingPost.validationResultCount.OK ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-check-circle"></i>' +
				'</span>';

				var trustedHtmlFragment = $sce.trustAsHtml(htmlFragment);
				return trustedHtmlFragment;
			};

	        $scope.openOccupancyInfo = function (organisation, section, trainingPost, occupancy) {
		        var data = {
					id: occupancy.id,
			        organisation : {
				        id : organisation.id,
				        oakKey : organisation.oakKey,
				        label : organisation.label,
				        type : organisation.type
				    },
			        section : {
				        id : section.id,
				        oakKey : section.oakKey,
				        label : section.label
				    },
			        trainingPost : {
				        id: trainingPost.id,
				        oakKey : trainingPost.oakKey,
				        trainingPostType : trainingPost.trainingPostType,
						validFrom : trainingPost.validFrom,
						validUntil : trainingPost.validUntil,
						subject : trainingPost.subject,
						gradeOfRecognition : trainingPost.gradeOfRecognition,
						durationInMonths : trainingPost.durationInMonths,
						maxAllocation : trainingPost.maxAllocation
					},
					validation: occupancy.validation
		        };

	            var OccupancyInfoInstance = $modal.open({
	              templateUrl: '/modules/asv/Overview/OverviewModalsOccupancyInfo.html',
	              controller: 'OverviewModalsInfoModalController',
	              size: 'lg',
	              resolve: {
	                data: function () {
	                  return data;
	                }
	              }
	            });
	        };

	        $scope.newOccupancy = function (organisation, section, trainingPost) {
				// Get Data Objects for Current TrainingPost

		        // Build Data for Modal
		        var data = {
			        organisation : {
				        id : organisation.id,
				        oakKey : organisation.oakKey,
				        label : organisation.label,
				        type : organisation.type
				    },
			        section : {
				        id : section.id,
				        oakKey : section.oakKey,
				        label : section.label
				    },
			        trainingPost : {
				        id: trainingPost.id,
				        oakKey : trainingPost.oakKey,
				        trainingPostType : trainingPost.trainingPostType,
						validFrom : trainingPost.validFrom,
						validUntil : trainingPost.validUntil,
						subject : trainingPost.subject,
						gradeOfRecognition : trainingPost.gradeOfRecognition,
						durationInMonths : trainingPost.durationInMonths,
						maxAllocation : trainingPost.maxAllocation
					}
		        };

				// Open Modal
	            var NewOccupancyInstance = $modal.open({
	              templateUrl: '/modules/asv/Overview/OverviewModalsNewOccupancy.html',
	              controller: 'OverviewModalsNewOccupancyController',
	              size: 'lg',
	              backdrop: true,
	              resolve: {
	                data: function () {
	                  return data;
	                }
	              }
	            });

				// Handle Return
	            NewOccupancyInstance.result.then(function (occupancyData) {

		            // Save Data to Server
				  	var newOccupancyResult = dataFactory.newOccupancy(organisation.id, section.id, trainingPost.id, occupancyData);
					newOccupancyResult.$promise.then(function (result) {
                        // TODO: handle return values ok or error
						if (result.ok) {
							AnalyticsFactory.trackOccupancyCreation(occupancyData.orgUnit, result.payload);

							if (result.payload.splittingRecommended) {
								$log.debug("splitting recommended");
								var splitDialogData = result.payload;
								var SplitOccupancyInstance = $modal.open({
									templateUrl: '/modules/asv/Overview/OverviewModalsSplitOccupancy.html',
									controller: 'OverviewModalsSplitOccupancyController',
									size: 'lg',
									backdrop: 'static',
									resolve: {
										data: function () {
											return splitDialogData;
										}
									}
								});
							} else {
								dataFactory.forcePolling();
							}
						}
					});


	            }, function () {
	            });
	        };

			$scope.plusTrainingPost = function($event,trainingPost) {
				$event.preventDefault();
				$event.stopPropagation();
				trainingPost.plussing = true;
				trainingPost.actualPlusability = "";
				dataFactory.plusTrainingPost( trainingPost );
			};

			$scope.revokeOccupancy = function(trainingPost, organisation, occupancyId) {
				dataFactory.revokeOccupancy(trainingPost, organisation, occupancyId);
			};

	        $scope.deleteOccupancy = function (trainingPost, organisation, occupancyId) {
				var deletePromise = dataFactory.deleteOccupancy(trainingPost, organisation, occupancyId);
				// If Server Response then add to List
				deletePromise.$promise.then(function (result) {
					if (result.ok) {
						// TODO handle error result
					}
					dataFactory.forcePolling();
				});
			};
			
			$scope.getGradeOfRecognition = function (id) {
				return ServerRemoteConfigFactory.getGradeOfRecognitionById(id);
			};
		}
	])

	.directive('trainingPostsTag',
		function() {
		return {
			restrict : 'E',
			templateUrl: '/modules/asv/Components/TrainingPostsTag.html',
			controller : 'TrainingPostController',
			scope : {
				organisation : '=organisation',
				section : '=section',
				trainingPosts : '=trainingPosts',
				editMode : '=editMode'
			}
		};
	});

})();
