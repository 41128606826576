// ASV Overview
(function(){
    'use strict';

    angular.module('History',[
    	'ui.bootstrap',
    	'Bootstrap','LocalConfig','RemoteConfig', 'OrgUnitSearch', 'LinkGenerator'
    ])


    // Init Controller
    .controller('HistoryController', [
    	'$scope','$rootScope','$routeParams', '$location', '$modal', '$log', '$anchorScroll', '$timeout', '$route',
    	'APP_CONFIG', 'SERVER_CONFIG','UrlConfigFactory', 'ServerRemoteConfigFactory', 'LinkGeneratorFactory', 'CommonToggleFactory',
		function($scope,$rootScope, $routeParams, $location, $modal, $log, $anchorScroll, $timeout, $route,
				APP_CONFIG, SERVER_CONFIG, UrlConfigFactory, ServerRemoteConfigFactory, LinkGeneratorFactory, CommonToggleFactory) {

			// redirects to /trainee if the type is not specified
			if (!$routeParams.type || $routeParams.type === '') {
				$route.updateParams({'type': 'trainee'});
				return;
			}
			// Variables
			var historyResource = UrlConfigFactory.getHistoryResource();

			$rootScope.localHistory = $rootScope.localHistory || {};
			$rootScope.paramHistory = $rootScope.paramHistory || {};
			$scope.dateOfBirthOptions = {
				"datepickerMode" : "'year'"
			};

			// Init Date Picker Toggler
			$scope.dateOfBirthToggle = new CommonToggleFactory();

			$scope.reset = function(deleteData) {
				$scope.dataAvailable = false;
				$scope.occupancies = [];
				$scope.title = "";
				
				if (!deleteData && $scope.searchType !== 'occupancySet' && $rootScope.localHistory[$scope.searchType] ) {
					// use previous value
					$scope.searchValues = $rootScope.localHistory[$scope.searchType];
				} else {
					// init default
					$scope.searchValues = {
							trainee : {
							oakKey : null,
							firstName : null,
							lastName : null,
							dateOfBirth : null
						},
						trainingPost : {
							oakKey : null
						},
						occupancy : {
							id : null
						},
						occupancySet : {
							id : null
						}
					};
				}
				$scope.required = {
					trainee : {
						oakKey : true,
						firstName : true,
						lastName : true,
						dateOfBirth : true
					}
				};
				

				$scope.checkForm($routeParams.type);
			};

			$scope.updateSearch = function() {
				// update current form data
				$rootScope.localHistory[$routeParams.type] = $scope.searchValues;
				$rootScope.paramHistory[$routeParams.type] = angular.copy($routeParams);
				
				$scope.updateParams($scope.searchType, $scope.searchValues[$scope.searchType]);

				// special handling for Arzthistory search by name
				if ($scope.searchValues[$scope.searchType] && !$scope.searchValues[$scope.searchType].oakKey) {
					$scope.search();
				}
			};
			
			// is called when the type changes
			$scope.reloadHistory = function(args) {
				var defaultArgs = {
					'type' : $scope.searchType
				};

				var params = angular.extend(defaultArgs, args);

				// save current form data
				$rootScope.localHistory[$routeParams.type] = $scope.searchValues;
				$rootScope.paramHistory[$routeParams.type] = angular.copy($routeParams);
				
				// get last form data of target
				if (args) {
					var historyParams = $rootScope.paramHistory[args.type];
					params = angular.extend(defaultArgs, historyParams);
				}
				// override with explicit params
				params = angular.extend(defaultArgs, args);

				$location.path(
					LinkGeneratorFactory.getLocation(
						'history',
						params
					)
				);
			};

			$scope.resolveLinkParams = function( organisation ) {
				return {'type': $scope.searchType, 'id': organisation.id};
			};

			$scope.getTrainingPostTypeLabel = function( id ) {
				return ServerRemoteConfigFactory.getTrainingPostTypeById(id).label;
			};

			$scope.getTrainingPostSubjectLabel = function( id ) {
				return ServerRemoteConfigFactory.getSubjectById(id).label;
			};
			$scope.getGradeOfRecognition = function (id) {
				return ServerRemoteConfigFactory.getGradeOfRecognitionById(id);
			};

			$scope.getTrainingPostRecognitionLabel = function(gradeOfRecognition, durationInMonths) {
				return (durationInMonths ? (durationInMonths > 1 ? durationInMonths + " Monate" : durationInMonths + " Monat") : "")
					+ (gradeOfRecognition && gradeOfRecognition === 1 ? " vollanerkannt" : "")
					+ (gradeOfRecognition && gradeOfRecognition === 2 ? " teilanerkannt" : "");
			}

			$scope.getTrainingPostComment = function( comment ) {
				return comment ? comment:'-';
			};

			$scope.updateParams = function (type, request) {
				var value = null;
				switch (type) {
					case 'trainee':
					case 'trainingPost':
						value = request.oakKey;
						break;
					case 'occupancy':
						value = request.id;
					break;
				}

				if (value !== null) {
					$route.updateParams({'id': value});
				}
			};

	        $scope.search = function() {

		        var resourceParams = {
			        'type' : $scope.searchType
		        };

		        angular.forEach($scope.searchValues[$scope.searchType], function(value, key) {
			        resourceParams[key] = value;
		        });

		        if ($scope.searchType === 'trainee') {
		        	UrlConfigFactory.getTraineeHistoryResource().save($scope.searchValues[$scope.searchType], function( result ) {
		        		$scope.dataAvailable = true;
		        		$scope.title = result.title;
		        		$scope.trainingPost = result.trainingPost;
		        		$scope.occupancies = result.occupancies;
		        		$scope.organisation = result.organisation;
		        		$scope.section = result.section;
		        		$scope.occupancy = result.occupancy;
		        		$scope.trainee = result.trainee;
		        		$scope.notFound = result.notFound;
		        		$scope.orgUnitInfosByOccupancyId = result.orgUnitInfosByOccupancyId;
		        		$scope.occupancySetHistory = result.occupancySetHistory;
		        		$log.log(result);
		        	} );
		        } else {
		        	UrlConfigFactory.getHistoryResource().get(resourceParams, function( result ) {
		                $scope.dataAvailable = true;
		                $scope.title = result.title;
		                $scope.trainingPost = result.trainingPost;
		                $scope.occupancies = result.occupancies;
						$scope.organisation = result.organisation;
						$scope.section = result.section;
						$scope.occupancy = result.occupancy;
						$scope.trainee = result.trainee;
						$scope.notFound = result.notFound;
						$scope.orgUnitInfosByOccupancyId = result.orgUnitInfosByOccupancyId;
						$scope.occupancySetHistory = result.occupancySetHistory;
		                $log.log(result);
		            } );
	        	}
		    };

		    $scope.checkForm = function(type) {
			    if (type === 'trainee') {
					var checkVal = $scope.searchValues.trainee;
					
				    if (checkVal.oakKey) {
					    $scope.required.trainee = {
						    oakKey : true,
						    firstName : false,
							lastName : false,
							dateOfBirth : false
					    };
				    }
				    else if (!checkVal.oakKey && (checkVal.firstName || checkVal.lastName || checkVal.dateOfBirth)) {
					    $scope.required.trainee = {
						    oakKey : false,
						    firstName : true,
							lastName : true,
							dateOfBirth : true
					    };
				    }
				    else {
					    $scope.required.trainee = {
						    oakKey : true,
						    firstName : true,
							lastName : true,
							dateOfBirth : true
					    };
				    }
			    }
		    };

		    $scope.openOccupancyInfo = function (organisation, section, trainingPost, occupancy) {

				// TODO Daten Korrekt befüllen
		        var data = {
					id: occupancy.id,
			        organisation : {
				        id : organisation.id,
				        oakKey : organisation.oakKey,
				        label : organisation.label[0],
				        type : organisation.type
				    },
			        section : {
				        id : section.id,
				        oakKey : section.oakKey,
				        label : section.label[section.label.length-1]
				    },
			        trainingPost : {
				        id: trainingPost.id,
				        oakKey : trainingPost.oakKey,
				        trainingPostType : trainingPost.trainingPostType,
						validFrom : trainingPost.validFrom,
						validUntil : trainingPost.validUntil,
						subject : trainingPost.subject,
						gradeOfRecognition : trainingPost.gradeOfRecognition,
						durationInMonths : trainingPost.durationInMonths,
						maxAllocation : trainingPost.maxAllocation
					},
					validation: occupancy.validation
		        };

	            var OccupancyInfoInstance = $modal.open({
	              templateUrl: '/modules/asv/Overview/OverviewModalsOccupancyInfo.html',
	              controller: 'OverviewModalsInfoModalController',
	              size: 'lg',
	              resolve: {
	                data: function () {
	                  return data;
	                }
	              }
	            });
	        };

	        /**
	         * these 3 functions are a workaround since ng-init is not called when angular is the opinion that an element
	         * of an array is equal to the one it already has.
	         *
	         * @see http://stackoverflow.com/questions/15355122/angularjs-ngrepeat-with-nginit-ngrepeat-doesnt-refresh-rendered-value
	         */

	        $scope.getOccupancyStateClass = function(occupancy) {
	        	return $scope.getOccupancyDisplayValues(occupancy).state;
	        };

	        $scope.getOccupancyIconClass = function(occupancy) {
	        	return $scope.getOccupancyDisplayValues(occupancy).icon;
	        };

	        $scope.getOccupancyIconStateClass = function(occupancy) {
	        	return $scope.getOccupancyDisplayValues(occupancy).iconState;
			};

	        $scope.getOccupancyDisplayValues = function(occupancy) {

	            var dispValues = {
		            'state' : '',
		            'icon' : 'fa-pencil'
	            };

                var status = occupancy.status;

				if (occupancy.revocation === true && SERVER_CONFIG.occupancyStateCodes[status] === "DRAFT") {
					dispValues.state = 'revoked inactive info';
					dispValues.icon = 'fa-ban';
					dispValues.iconState = 'deleted';
				}
				else if (SERVER_CONFIG.occupancyStateCodes[status] === "ACTIVE") {
		            dispValues.state = "active";
	            }
	            else if (SERVER_CONFIG.occupancyStateCodes[status] === "DRAFT") {
	                dispValues.state = "info";
	            }
	            else if (SERVER_CONFIG.occupancyStateCodes[status] === "DELETE") {
	                dispValues.state = 'revoked inactive';
	                dispValues.icon = 'fa-ban';
	                dispValues.iconState = 'deleted';
	            }

				return dispValues;
	        };

			ServerRemoteConfigFactory.doWhenRemoteConfigLoaded( function(  ) {

				$scope.dateFormat = APP_CONFIG.dateFormat;
				$scope.dateFormatLong = APP_CONFIG.dateFormatLong;
				$scope.dateOfBirthMax = new Date();
				$scope.dateOfBirthMax.setFullYear($scope.dateOfBirthMax.getFullYear() - ServerRemoteConfigFactory.getConstraints().trainee.minAge);
				$scope.searchType = $routeParams.type || "trainee";
				$scope.maxLengthOakKey = ServerRemoteConfigFactory.getConstraints().trainee.maxLengthOakKey;

				$scope.reset();

				if ($routeParams.type) {
					$scope.searchType = $routeParams.type;

					$log.debug("search params: " + $routeParams.id + " - new: " + ($routeParams.id !== null) + " - old: " + ($routeParams.id != null) + " - newnew: " + (!!$routeParams.id));
					if ($routeParams.id) {
						if ($scope.searchType === "trainee") {
							$scope.searchValues.trainee.oakKey = $routeParams.id;
						}
						else if ($scope.searchType === "trainingPost") {
							$scope.searchValues.trainingPost.oakKey = $routeParams.id;
						}
						else if ($scope.searchType === "occupancy") {
							$scope.searchValues.occupancy.id = $routeParams.id;
						}
						else if ($scope.searchType === "occupancySet") {
							$scope.searchValues.occupancySet.id = $routeParams.id;
						}

						$scope.search();
					}
				}
			} );

    }])

    .directive('historyTrainingPost', [
		function() {
		return {
			restrict : 'E',
			templateUrl: '/modules/asv/History/HistoryTrainingPost.html'
		};
	}])

	.directive('historyOccupancy', [
		function() {
		return {
			restrict : 'E',
			templateUrl: '/modules/asv/History/HistoryOccupancy.html'
		};
	}])

	.directive('historyTrainee', [
		function() {
		return {
			restrict : 'E',
			controller: ['$scope', function($scope) {
				$scope.filterQuery = {};
				
				$scope.clearFilter = function() {
					$scope.filterQuery = {};
				};
				
				$scope.applyFilter = function() {
					$scope.filterQuery.status = 'ACTIVE';
				};
				
				$scope.applyFilter();
			}],
			templateUrl: '/modules/asv/History/HistoryTrainee.html'
		};
	}])

	.directive('historyOccupancySet', [
		function() {
		return {
			restrict : 'E',
			templateUrl: '/modules/asv/History/HistoryOccupancySet.html'
		};
	}])

	;

})();
