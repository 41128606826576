(function(){
	'use strict';

	angular.module('AsvUI', [
		// AIT modules
		'RemoteConfig'
	])

	// Directive to disable ngAnimate for certain Elements
	// Fixes Redraw Issues on already animated Elements
	.directive('noAnimate', ['$animate',
		function($animate) {
			return {
				restrict: 'A',
				link: function(scope, element, attrs) {
					$animate.enabled(false, element);
					scope.$watch(function() {
						$animate.enabled(false, element);
					});
				}
			};
		}
	])

	// Add to any Component for loading animation
	.directive('loadingOverlay', [
		function() {
			return {
				restrict: 'E',
				templateUrl: 'modules/asv/Components/LoadingOverlay.html',
				link: function(scope, element, attrs) {
					scope.loadingOverlayType = attrs.loadingOverlayType;
					scope.loadingOverlayText = attrs.loadingOverlayText;
				}
			};
		}
	])

	// Add to any input for autofocus
	.directive('autoFocus', [
		function() {
			return {
				restrict: 'A',
				link: function(scope, element, attrs) {
					element[0].focus();
				}
			};
		}
	])

	;

})();