// ASV Overviewfilter

(function(){
    'use strict';

    angular.module('OverviewFilter',[
    	'Bootstrap','LocalConfig','RemoteConfig','FilterFunctions','Allocation', 'OverviewUserData'
    ])

    // Set Constants
    .factory('OverviewFilterConfigFactory', [
    	'ServerRemoteConfigFactory', 'APP_CONFIG', 'AllocationFactory', 'OverviewUserDataFactory',
    	function(ServerRemoteConfigFactory, APP_CONFIG, AllocationFactory, OverviewUserDataFactory) {

		    // Get Applikation Data
	        var serverRemoteConfig = null;
	        serverRemoteConfig = ServerRemoteConfigFactory;

		    serverRemoteConfig.loadData();

			// $var will be the object specified in 'level'. e.g. 'level' trainingPost -> $var can be used like a trainingPost object 
	        var settings = {
	        	'textSearch' : {
	            	'label' : 'Suche',
	            	'infoText' : '$oLabel enthält "$value"',
	            	'defaultValueId' : 'doctor',
	            	'uniqueValues' : false,
	            	'options' : [{
	                	'id' : 'doctor',
	                	'label' : 'Arzt',
	                	'evalFunc' : function($var, $value) {
		                	return ($var.trainee.oakKey === $value || $var.trainee.displayName.toLowerCase().indexOf($value.toLowerCase()) >= 0);
		                },
	                	'level' : 'occupancy',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'section',
	                    'label' : 'Abteilung',
	                    'evalFunc' : function($var, $value) {
		                	return ($var.label.toLowerCase().indexOf($value.toLowerCase()) >= 0);
		                },
	                	'level' : 'section',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'discipline',
	                    'label' : 'Fach',
	                    'evalFunc' : function($var, $value) {
		                    var compString = serverRemoteConfig.getSubjectValueById($var.subject, 'id', 'label');
		                	return (compString.toLowerCase().indexOf($value.toLowerCase()) >= 0);
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'comment',
	                    'label' : 'Kommentar',
	                    'evalFunc' : function($var, $value) {
		                	return (angular.isDefined($var.comment) && $var.comment !== null && $var.comment.toLowerCase().indexOf($value.toLowerCase()) >= 0);
		                },
	                	'level' : 'occupancy',
						'expandOnFilter': true,
						'showInFilterBar': true
	            	}]
	        	},
	        	'trainingPostSearch' : {
	            	'label' : 'Stelle',
	            	'infoText' : '$cLabel $oLabel',
	            	'uniqueValues' : true,
	        	    'options' : [{
	                	'id' : 'hasErrors',
	                	'label' : 'mit Fehlern / Warnungen',
	                	'evalFunc' : function($var, $value) {
		                	if ($var.validationResultCount.PENDING !== 0 || ($var.validationResultCount.OK === 0 && $var.validationResultCount.INFO === 0 && $var.validationResultCount.WARNING === 0 && $var.validationResultCount.ERROR === 0 && $var.validationResultCount.FATAL === 0 && $var.validationResultCount.PENDING === 0 && $var.occupancies.length !== 0)) {
			                	return !$var.hidden;
		                	}
		                	else {
		                		return ($var.validationResultCount.FATAL > 0 || $var.validationResultCount.ERROR > 0  || $var.validationResultCount.WARNING > 0);

		                	}
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'hasVacancy',
	                    'label' : 'ist Frei / Teilbesetzt',
	                    'evalFunc' : function($var, $value) {
		                    var filterDate = OverviewUserDataFactory.getDate();
		                    var minutesSum = AllocationFactory.getOccupanciesSum($var.occupancies, filterDate);
							// $var is a trainingPost because level is set to 'trainingPost'
		                	return ($var.maxAllocaton > minutesSum);
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'isBasic',
	                    'label' : 'für Basisausbildung',
	                    'evalFunc' : function($var, $value) {
		                    var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'oakKey');
		                	return (compString === "BA" || compString === "BAS");
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'isDiscipline',
	                    'label' : 'für Fachausbildung',
	                    'evalFunc' : function($var, $value) {
		                    var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'oakKey');
		                	return (compString === "F" || compString === "AF" || compString === "SFS" || compString === "SFG");
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                }  , {
	                    'id' : 'isGeneralPractice',
	                    'label' : 'für Allgemeinmedizin',
	                    'evalFunc' : function($var, $value) {
		                    var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'oakKey');
		                	return (compString === "AM" || compString === "A" || compString === "AL" || compString === "AMW");
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
                        'id' : 'isComplementary',
                        'label' : 'für Gegenfächer',
                        'evalFunc' : function($var, $value) {
                            var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'oakKey');
                            return (compString === "GF" );
                        },
                        'level' : 'trainingPost',
                        'expandOnFilter': true,
                        'showInFilterBar': true
                    } , {
	                    'id' : 'aeao2006',
	                    'label' : 'für ÄAO 2006',
	                    'evalFunc' : function($var, $value) {
		                    var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'trainingRegulation');
		                	return (compString === '2006');
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
	                    'id' : 'aeao2015',
	                    'label' : 'für ÄAO 2015',
	                    'evalFunc' : function($var, $value) {
		                	var compString = serverRemoteConfig.getTrainingPostTypeValueById($var.trainingPostType, 'id', 'trainingRegulation');
		                	return (compString === '2015');
		                },
	                	'level' : 'trainingPost',
						'expandOnFilter': true,
						'showInFilterBar': true
	                } , {
						'id' : 'hideExpired',
						'label' : 'nur aktive Stellen',
						'evalFunc' : function($var, $value) {
							return !OverviewUserDataFactory.isTrainingPostInactive($var);
						},
						'level' : 'trainingPost',
						'expandOnFilter': false,
						'infoText': 'nur aktive Stellen',
						'showInFilterBar': false,
						'activeByDefault': true
					}]
	            }
	    	};

	    	return settings;
	    }
    ])

    .factory('OverviewFilterFactory', [
    	'$rootScope', '$log',
    	'OverviewFilterConfigFactory','FilterFunctionsService','CommonToggleFactory',
    	function($rootScope, $log, OverviewFilterConfigFactory, FilterFunctionsService, CommonToggleFactory){

	        var toolbarFilter = {};

	        toolbarFilter.settings = angular.copy(OverviewFilterConfigFactory);

	        toolbarFilter.toggle = new CommonToggleFactory();

	        toolbarFilter.settings.textSearch.curValue = '';

	        toolbarFilter.filterExpressions = {};

	        toolbarFilter.numItems = 0;

			toolbarFilter.numItemsInFilterBar = 0;

			// Set default for Text Search
	        var defaultSelected = FilterFunctionsService.getObjectById(toolbarFilter.settings.textSearch.options, toolbarFilter.settings.textSearch.defaultValueId, 'id');
	        toolbarFilter.settings.textSearch.curSelected = defaultSelected;

	        // Calculate Splitpoint to Split Options in 2 rows
	        toolbarFilter.settings.trainingPostSearch.splitPoint = toolbarFilter.settings.trainingPostSearch.options.length / 2;
	        if(toolbarFilter.settings.trainingPostSearch.splitPoint % 2 !== 0) {
	            toolbarFilter.settings.trainingPostSearch.splitPoint++;
	        }

	        toolbarFilter.getItemLabel = function(categoryKey,itemId) {
	            // filter for item
	            var item = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, itemId, 'id');
	            // return label
	            return item.label;
	        };

	        toolbarFilter.getInfoText = function(categoryKey,itemKey,item) {

				// Get generic Message Template for filter category
	            var message = toolbarFilter.settings[categoryKey].infoText;

				// look if filter provides his own InfoText, first get filter definition
				var filterDef = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, itemKey, 'id');
				if ( filterDef && filterDef.infoText ) {
					message = filterDef.infoText;
				} else {
					// if filter does not provide its own infoText, replace generic template variables
					// Replace Strings
					message = message.replace(/\$cLabel/g, toolbarFilter.settings[categoryKey].label);

					if (!toolbarFilter.settings[categoryKey].uniqueValues) {
						message = message.replace(/\$oLabel/g, toolbarFilter.getItemLabel(categoryKey,item.id));
						message = message.replace(/\$value/g, item.value);
					}
					else {
						message = message.replace(/\$oLabel/g, toolbarFilter.getItemLabel(categoryKey,itemKey));
						message = message.replace(/\$value/g, item);
					}
				}

	            // Return Message
	            return message;
	        };

	        toolbarFilter.getFilterValues = function() {
		        return toolbarFilter.items;
	        };

	        toolbarFilter.buildFilterExpressions = function() {
	            toolbarFilter.filterExpressions = {};

	            angular.forEach(toolbarFilter.items, function(categoryObject, categoryKey) {
	                angular.forEach(categoryObject, function(filterObject, filterKey) {
						if (filterObject) {
							var item = {};

							if (!toolbarFilter.settings[categoryKey].uniqueValues) {
								item = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, filterObject.id, 'id');
		                    }
		                    else {
			                    item = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, filterKey, 'id');
		                    }

		                    if (item) {

		                        if (!toolbarFilter.filterExpressions[item.level]) {
		                       		toolbarFilter.filterExpressions[item.level] = {};
		                        }

		                        if (!toolbarFilter.filterExpressions[item.level][item.id]) {
		                        	toolbarFilter.filterExpressions[item.level][item.id] = [];
		                        }

								if (!toolbarFilter.settings[categoryKey].uniqueValues) {
		                        	toolbarFilter.filterExpressions[item.level][item.id].push({'evalFunc' : item.evalFunc, 'value' : filterObject.value, 'expandOnFilter': item.expandOnFilter });
		                        }
		                        else {
			                        toolbarFilter.filterExpressions[item.level][item.id].push({'evalFunc' : item.evalFunc, 'value' : filterObject, 'expandOnFilter': item.expandOnFilter });
		                        }
		                    }
	                    }
	                });
	            });

	            $rootScope.$broadcast('OverviewFilterSettingsChanged', toolbarFilter.filterExpressions);
            };

	        toolbarFilter.addItem = function(categoryKey, item, value) {
				//console.log(" === addItem ", categoryKey,item,value );
		        if (value && value !== '') {
					var filterDef = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, item, 'id');

		            if (!toolbarFilter.settings[categoryKey].uniqueValues) {

		                toolbarFilter.items[categoryKey].push({
			                'id' : item,
			                'value' :value
			            });
		            } else if (value && value !== '') {
		                toolbarFilter.items[categoryKey][item] = value;
		            }

					if ( filterDef.showInFilterBar ) {
						toolbarFilter.numItemsInFilterBar ++;
					}
		            toolbarFilter.numItems++;

		            toolbarFilter.buildFilterExpressions();

		            return true;
	            }
	            else {
		            return false;
	            }
	        };

	        toolbarFilter.removeItem = function(categoryKey, itemKey) {
				var filterDef = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, itemKey, 'id');
				//console.log("  ==== toolbarFilter.removeItem", categoryKey, itemKey, filterDef.showInFilterBar, toolbarFilter.items );
	            if (!toolbarFilter.settings[categoryKey].uniqueValues) {
	                toolbarFilter.items[categoryKey].splice(itemKey, 1);
	            }
	            else {
	                delete toolbarFilter.items[categoryKey][itemKey];
	            }

				if ( filterDef.showInFilterBar ) {
					toolbarFilter.numItemsInFilterBar --;
				}

	            toolbarFilter.numItems--;

	            toolbarFilter.buildFilterExpressions();
	        };

	        toolbarFilter.toggleItem = function(categoryKey, itemKey) {
				var filterDef = FilterFunctionsService.getObjectById(toolbarFilter.settings[categoryKey].options, itemKey, 'id');
				//console.log("  ==== toolbarFilter.toggleItem  ", categoryKey,itemKey, filterDef.showInFilterBar, toolbarFilter.items);
	            //toolbarFilter.items[categoryKey][item] = !toolbarFilter.items[categoryKey][item];
	            if (!toolbarFilter.items[categoryKey][itemKey]) {
		            toolbarFilter.removeItem(categoryKey, itemKey);
		        }
		        else {
					if ( filterDef.showInFilterBar ) {
						toolbarFilter.numItemsInFilterBar ++;
					}
			        toolbarFilter.numItems++;
		        }
	            toolbarFilter.buildFilterExpressions();
	        };

	        toolbarFilter.reset = function() {
				//console.log("  ==== toolbarFilter.reset");
	            toolbarFilter.items = {};
	            toolbarFilter.numItems = 0;
				toolbarFilter.numItemsInFilterBar = 0;

	            angular.forEach(toolbarFilter.settings, function(categoryObject, categoryKey) {
	                if (categoryObject.uniqueValues) {
	                    toolbarFilter.items[categoryKey] = {};
	                }
	                else {
	                    toolbarFilter.items[categoryKey] = [];
	                }
	            });

	            toolbarFilter.buildFilterExpressions();
	        };

	        toolbarFilter.init = function(curItems, curNumItems) {
				//console.log("  ==== toolbarFilter.init:", curItems, curNumItems);
	            toolbarFilter.items = curItems;
	            toolbarFilter.numItems = curNumItems;
				toolbarFilter.numItemsInFilterBar = curNumItems;

				// mh activate all filters that are active by default - only trainingPostSearch filters can do that
				angular.forEach(toolbarFilter.settings.trainingPostSearch.options, function(filter) {
					if ( filter.activeByDefault ) {
						//console.log("   filter active by default: " + filter.id );
						toolbarFilter.items['trainingPostSearch'][filter.id] = true;
					}
				});

	            toolbarFilter.buildFilterExpressions();
	        };

	        toolbarFilter.resetInput = function (categoryKey) {
		        toolbarFilter.settings[categoryKey].curValue = '';

		        return true;
	        };

	        toolbarFilter.reset();

	        return toolbarFilter;
	    }
	])


    .filter('OverviewFilter', [
	    function() {
	        return function (items, selectedItems, itemLevel) {

	            if (angular.isObject(items) && angular.isObject(selectedItems) && angular.isObject(selectedItems[itemLevel]) && Object.keys(selectedItems[itemLevel]).length > 0) {
		            var count = items.length;

		            angular.forEach(items, function(item, itemKey) {
						var itemEval = true;
				        angular.forEach(selectedItems[itemLevel], function(filterItems) {

							angular.forEach(filterItems, function(filterItem) {
								// TODO: provide trainingpost here?
						        if (itemEval && !filterItem.evalFunc(item, filterItem.value)) {
									itemEval = false;
								}
				        	});
				        });

				        if (itemEval) {
					        items[itemKey].hidden = false;
				        }
				        else {
					        items[itemKey].hidden = true;
					        count --;
				        }

			        });
			        if (count === 0) {
				        items.allHidden = true;
			        }
			        else {
				        items.allHidden = false;
			        }

			        return items;
			    }
				else if (angular.isObject(items)){
					angular.forEach(items, function(item, itemKey) {
				        items[itemKey].hidden = false;
		        	});

				    items.allHidden = false;

					return items;
				}
				else {
					return items;
				}
	        };
	    }
    ])

	.filter('toniDateFilter', function() {
		return function(input) {
			return input === "01.01.1970" ? 'unbegrenzt' : input;
		};
	})

    ;

})();
