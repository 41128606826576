(function(){
	'use strict';

	angular.module('OrgUnitSearch', [
		// AIT modules
		'LocalConfig', 'RemoteConfig', 'LinkGenerator'
	])

	.directive('orgUnitSearchTag', [
		function() {
		return {
			templateUrl: '/modules/asv/Components/OrgUnitSearchTag.html',
			controller : 'OrgUnitSearchController',
			scope : {
				orgUnits : '=orgUnits',
				linkHref : '@linkHref',
				getLinkParams : '&getLinkParams',
				selectedId: '=?selectedId',
                omitCheckboxesFor: '@omitCheckboxesFor',
                showFilter: '=showFilter'
			}
		};
	}])
	
    .controller('OrgUnitSearchController', [
    	'$scope',
    	'LinkGeneratorFactory', 'UrlConfigFactory','ServerRemoteConfigFactory', 'UserServiceFactory',
		function($scope,  LinkGeneratorFactory, UrlConfigFactory, ServerRemoteConfigFactory, UserServiceFactory) {

			$scope.linkGenerator = LinkGeneratorFactory;
    		$scope.organisationList = [];
			$scope.totalCount = 0;
			$scope.activityOverlay = true;
			$scope.orgUnitTypes = ServerRemoteConfigFactory.getOrgUnitTypes();
            $scope.selectedOrgUnitTypes = {};
			$scope.selectableOrgUnitTypes = [];
			$scope.organisationSearch = {
                text: ''
            };
			
			// load user info including preferences
			var user = UserServiceFactory.getAuthInfo();

            /*
             * filter the list of orgUnitTypes into 'selectableOrgUnitTypes', filtering out
             * all OrgUnitTypes whose 'key' ist in the list of omitCheckboxesForOrgUnitTypes (tag attribute)
             */
            if ( !$scope.omitCheckboxesFor ) $scope.omitCheckboxesFor = '';
            var orgUnitTypeKeysToOmit = $scope.omitCheckboxesFor.split(',');
            for ( var i=0;i<$scope.orgUnitTypes.length;i++ ) {
                if ( orgUnitTypeKeysToOmit.lastIndexOf( $scope.orgUnitTypes[i].key ) == -1 ) {
                    $scope.selectableOrgUnitTypes.push( $scope.orgUnitTypes[i] );
                }
			}
			
			/**
			 * save organization preferences
			 */
			var savePreferencesOrg = function() {
				user.preferences.org = $scope.selectedOrgUnitTypes;
				UserServiceFactory.savePreferences(user.preferences);
			};

			/**
			 * init organization preferences and set selectedOrgUnitTypes state
			 */
			var initPreferencesOrg = function() {	
				// apply config to checkbox
				var userPreferencesOrg = (user && user.preferences && user.preferences.org) ? user.preferences.org : {};
				angular.forEach( $scope.selectableOrgUnitTypes, function(orgUnitType) {
					$scope.selectedOrgUnitTypes[orgUnitType.key] = userPreferencesOrg[orgUnitType.key] || false; // false is default
				});
	
				// only save preferences if the selectedOrgUnitTypes differ from the userPreferencesOrg
				if (JSON.stringify(Object.keys($scope.selectedOrgUnitTypes).sort()) !== JSON.stringify(Object.keys(user.preferences).sort())) {
					savePreferencesOrg();
				}
			}
			
            if (!$scope.selectedId) {
                $scope.selectedId = null;
            }

			// Get Resource for List
	        var organisationListResource = UrlConfigFactory.getOrganisationListResource();

			// checkbox toggled
			$scope.getOrganisations = function(key) {
				$scope.activityOverlay = true;
				var selectedOrgUnitKeys = new Array();
                angular.forEach( $scope.selectedOrgUnitTypes, function(isSelected, orgUnitTypeKey) {
				    if (isSelected) selectedOrgUnitKeys.push( orgUnitTypeKey );
                } );

				savePreferencesOrg();

				organisationListResource.get({label : $scope.organisationSearch.text, types: selectedOrgUnitKeys, id: $scope.selectedId, size: 100, sort: "label"}, function(page) {
					$scope.activityOverlay = false;
					$scope.organisationList = page.content;
					$scope.totalCount = page.totalElements;
				});
			};

			$scope.reset = function() {
				$scope.organisationSearch.text = '';
				$scope.selectedId = null;

				$scope.getOrganisations();
			};

			initPreferencesOrg();

			$scope.getOrganisations();
	}]);

})();
