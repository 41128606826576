
import _ from 'lodash';

// ASV Start Screen
(function(){
    'use strict';

    angular.module('Start',[
    	'ui.bootstrap',
    	'Bootstrap', 'LocalConfig','RemoteConfig','LinkGenerator'
    ])

    // Init Controller
    .controller('StartController', [
    	'$scope', '$location', '$route',
    	'ROUTE_CONFIG', 'LinkGeneratorFactory', 'UserRemoteConfigFactory', 'UrlConfigFactory', 'UserServiceFactory',
		function($scope, $location, $route, ROUTE_CONFIG, LinkGeneratorFactory, UserRemoteConfigFactory, UrlConfigFactory, UserServiceFactory) {

			$scope.linkGenerator = LinkGeneratorFactory;

			var user = UserServiceFactory.getAuthInfo();
			var preferences = (user && user.preferences) || {};
			$scope.startPreferences = preferences.start || {org: true, report: true};

			$scope.organisationDraftList = [];

			$scope.showCategoryFilter = UserServiceFactory.hasRoleOEAEK() ||
                UserServiceFactory.hasRoleLAEK() ||
                UserServiceFactory.hasRoleSysAdmin();

			// Get Resources for Lists
	        var organisationDraftListResource = UrlConfigFactory.getOrganisationDraftListResource();

	        //Load List from Server
			function loadLists() {
                organisationDraftListResource.get( function( result ) {
                    $scope.organisationDraftList = result;
                });

			}

			$scope.resolveLinkParams = function(organisation) {
				return {'organisationId': organisation.id};
			};


			$scope.getUrl = function(url) {
				if (url) {
					return url;
				}
				return null;
			};

			$scope.toggleAccordion = function(id) {
				$scope.startPreferences[id] = !$scope.startPreferences[id];
				var newPref = _.merge({}, preferences, {start: $scope.startPreferences});
				UserServiceFactory.savePreferences(newPref);
				
			};

			loadLists();
	}])

	// Init Controller
    .controller('ActivityFeedController', [
    	'$scope',
    	'APP_CONFIG', 'UrlConfigFactory',
		function($scope, APP_CONFIG, UrlConfigFactory) {
			// Set Defaults
			$scope.dateFormat = APP_CONFIG.dateFormat;
			$scope.feedList = [];
			$scope.feedListLoading = true;
			$scope.linkList = [];
			$scope.linkListLoading = true;

            // Load Activity List from Server
			function loadList() {
				UrlConfigFactory.getActivityFeedListResource().query(function(result) {
					//$scope.feedList = feedList;
					$scope.feedList = result;
					$scope.feedListLoading = false;
				});
				UrlConfigFactory.getLinkFeedListResource().query(function(result) {
					//$scope.feedList = feedList;
					$scope.linkList = result;
					$scope.linkListLoading = false;
				});
			}

			$scope.getFeedDisplayValues = function(status) {
				var dispValues = {
					'statusClass' : ''
				};

				if (status === "WARNING") {
					dispValues.statusClass = "feed-status-warning";
				}
				else if (status === "ERROR") {
					dispValues.statusClass = "feed-status-danger";
				}
				else if (status === "LINK") {
					dispValues.statusClass = "feed-status-link";
				}
				else {
					dispValues.statusClass = "feed-status-info";
				}

				return dispValues;
			};

			loadList();
	}])

	;

})();
