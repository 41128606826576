(function(){
	'use strict';

	angular.module('Sections', [
		'ui.bootstrap',
		// AIT modules
		'Bootstrap','RemoteConfig', 'TrainingPosts', 'FilterFunctions', 'OverviewData', 'OverviewFilter'
	])

	.controller('SectionController', [
		'$rootScope', '$scope', '$sce', '$filter', '$log',
		'SERVER_CONFIG', 'OverviewDataFactory', 'OverviewFilterFactory', 'OverviewUserDataFactory',
		function($rootScope, $scope, $sce, $filter, $log, SERVER_CONFIG, OverviewDataFactory, OverviewFilterFactory, OverviewUserDataFactory) {

			// Load dataFactory
	        var dataFactory = OverviewDataFactory;

	        // Load OverviewFilter
	        $scope.overviewFilter = OverviewFilterFactory;

	        // Load OverviewUserFactory Values
	        $scope.sectionState = OverviewUserDataFactory.getSectionState();

			// Reset Validation Counter
			function resetValidationResultCount(section) {
				section.validationResultCount = {
	                'OK' : 0,
	                'INFO' : 0,
	                'WARNING' : 0,
	                'ERROR' : 0,
	                'FATAL' : 0,
	                'PENDING' : 0
	            };
			}

	        // Init Section
	        if (!$scope.sectionState[$scope.section.id]) {
		        $scope.sectionState[$scope.section.id] = {
			        isOpen : false
			    };
		    }

            resetValidationResultCount($scope.section);

		    // Handle ValidationUpdates
            $scope.$on('ValidationUpdates', function (event) {

	            resetValidationResultCount($scope.section);

				angular.forEach($scope.section.trainingPosts, function(trainingPost, trainingPostsKey) {

                    angular.forEach(trainingPost.occupancies, function(occupancy, occupancyKey) {
                        var validationEntryForThisOccupancy = dataFactory.getValidationByOccupancyId( occupancy.id );
                        occupancy.validation = validationEntryForThisOccupancy;

                        if (occupancy.validation && occupancy.validation.status === 'COMPLETED') {
							$scope.section.validationResultCount[SERVER_CONFIG.violationCodes[occupancy.validation.maxSeverity]]++;
						}
						else if (occupancy.validation && occupancy.validation.status === 'PENDING') {
							$scope.section.validationResultCount.PENDING++;
						}
                    });
                });
			});

			$scope.getSectionStatusFragment = function( section ) {

				var htmlFragment =
				'<span class="status-icon trainingPost-status-icon fatal' +  (section.validationResultCount.FATAL ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-minus-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon error' +  (section.validationResultCount.ERROR ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-times-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon warning' +  (section.validationResultCount.WARNING ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-exclamation-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon info' +  (section.validationResultCount.INFO ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-info-circle"></i>' +
				'</span>' +
				'<span class="status-icon trainingPost-status-icon ok' +  (section.validationResultCount.OK ? ' active':'') + '">' +
					'<i class="fa fa-fw fa-lg fa-check-circle"></i>' +
				'</span>';

				var trustedHtmlFragment = $sce.trustAsHtml(htmlFragment);
				return trustedHtmlFragment;
			};

			$scope.saveOpenState = function (sectionId, isOpen) {
				$scope.sectionState[sectionId].isOpen = isOpen;
				OverviewUserDataFactory.setSectionState($scope.sectionState);
			};

			$scope.getFlexibleAssignmentInfoText = function( flexibleAssignmentInfo ) {
				if (flexibleAssignmentInfo == null || flexibleAssignmentInfo.entries == null) return "null";
				var txt = "<table class='table table-condensed'>"
				        + "<thead><tr><th>Fach (max. Belegung SFG+SFS)</th><th></th></tr></thead><tbody>"
				var entries = flexibleAssignmentInfo.entries;
				if (Object.keys(entries).length > 0) {
					for ( var key in entries ) {
						if ( entries.hasOwnProperty(key) ) {
							var val = entries[ key ];
							txt = txt + "<tr><td class='text-left text-nowrap '>" + val.subjectLabel + " </td><td> " + val.maxValue + " </td></tr>";
						}
					}
				}
				return txt + "</tbody></table>";
			};

			$scope.hasFlexibleAssignmentInfo = function( flexibleAssignmentInfo ) {
				return flexibleAssignmentInfo && flexibleAssignmentInfo.entries && Object.keys(flexibleAssignmentInfo.entries).length > 0;
			};
		}
	])


	// Leider ist section bereits ein html5 keyword, daher müssen wir das hier umbenennen

	.directive('sectionTag', [
		function() {
		return {
			restrict : 'E',
			templateUrl: '/modules/asv/Components/SectionTag.html',
			controller : 'SectionController',
			scope : {
				organisation : '=organisation',
				section : '=section',
				trainingPosts : '=trainingPosts',
				editMode : '=editMode'
			}
		};
	}])

	;

})();
