// ASV Overview
(function() {
	'use strict';

	angular.module('OverviewModals', [
		'ngResource', 'ui.bootstrap',
		'Bootstrap', 'LocalConfig', 'RemoteConfig', 'FilterFunctions', 'AsvUI', 'Analytics'
	])

	.service('OverviewModalsPrintService', [
		function() {
			var printService = {};
			printService.print = function ()
				{
					var modal = document.getElementsByClassName("modal");
					var backdrop = document.getElementsByClassName("modal-backdrop");
					var content = document.getElementsByClassName("app-content");
					angular.element(content[0]).addClass('hidden-print');
					angular.element(backdrop[0]).addClass('hidden-print');
					angular.element(modal[0]).addClass('modal-print');
					window.print();
					angular.element(content[0]).removeClass('hidden-print');
					angular.element(backdrop[0]).removeClass('hidden-print');
					angular.element(modal[0]).removeClass('modal-print');
				};
			return printService;
		}
	])

	// Modal for TrainingPost or Occupancy Info
	.controller('OverviewModalsInfoModalController', [
		'$scope', '$log', '$modalInstance',
		'APP_CONFIG', 'data', 'ServerRemoteConfigFactory', 'OverviewModalsPrintService',
		function($scope, $log, $modalInstance, APP_CONFIG, data, ServerRemoteConfigFactory, OverviewModalsPrintService) {
			$scope.data = data;
			$scope.dateFormat = APP_CONFIG.dateFormat;

			$scope.getTrainingPostTypeLabel = function( id ) {
				return ServerRemoteConfigFactory.getTrainingPostTypeById(id).shortLabel;
			};

			$scope.getTrainingPostSubjectLabel = function( id ) {
				return ServerRemoteConfigFactory.getSubjectById(id).label;
			};

			$scope.getGradeOfRecognition = function (id) {
				return ServerRemoteConfigFactory.getGradeOfRecognitionById(id);
			};
			
			$scope.getTrainingPostComment = function( comment ) {
				return comment ? comment:'-';
			};

			$log.debug(data);

			$scope.ok = function() {
				$modalInstance.close();
			};
			$scope.cancel = function() {
				$modalInstance.dismiss('cancel');
			};

			$scope.print = function() {
				OverviewModalsPrintService.print();
			};

			// Close Modal if User hits Browser Back Button
			$scope.$on('$routeChangeStart', function(next, current) {
				$scope.cancel();
			});
		}
	])

	// Modal for New Occupancy
	.controller('OverviewModalsNewOccupancyController', [
		'$scope', '$filter', '$log', '$resource', '$modalInstance','$timeout',
		'APP_CONFIG', 'ServerRemoteConfigFactory', 'UrlConfigFactory', 'CommonToggleFactory', 'FilterFunctionsService', 'data',
		function($scope, $filter, $log, $resource, $modalInstance, $timeout, APP_CONFIG, ServerRemoteConfigFactory, UrlConfigFactory, CommonToggleFactory, FilterFunctionsService, data) {

			// Set Vars for Scope
			$scope.data = data;
			$scope.dateFormat = APP_CONFIG.dateFormat;
			$scope.dateToday = new Date();

			$scope.dateOfBirthMin = new Date();
			$scope.dateOfBirthMax = new Date();
			$scope.dateOfBirthMin.setFullYear($scope.dateToday.getFullYear() - ServerRemoteConfigFactory.getConstraints().trainee.maxAge);
			$scope.dateOfBirthMax.setFullYear($scope.dateToday.getFullYear() - ServerRemoteConfigFactory.getConstraints().trainee.minAge);
			$scope.minLengthFirstName = ServerRemoteConfigFactory.getConstraints().trainee.minLengthFirstName;
			$scope.minLengthLastName = ServerRemoteConfigFactory.getConstraints().trainee.minLengthLastName;
			$scope.maxLengthComment = ServerRemoteConfigFactory.getConstraints().occupancy.maxLengthComment;
		    $scope.maxLengthOakKey = ServerRemoteConfigFactory.getConstraints().trainee.maxLengthOakKey;
			$scope.oakKeyPattern = new RegExp(ServerRemoteConfigFactory.getConstraints().trainee.oakKeyPattern);

			$scope.toDateType = "openEnded"; /* openEnded, dateSet */
			$scope.toDateRequired = false;
			$scope.traineeSearch = false;
			$scope.traineeSearchResult = null; /* null,oakKeyFound,oakKeyNotFound,nameFound,nameNotFound  */

			$scope.tooltips = {
				'traineeOakKeyNotFound':"<h5>ÖÄK-Arztnummer existiert nicht</h5><p>Diese ÖÄK-Arztnummer existiert nicht in der ÖÄK-Standesführung.</p>",
				'traineeNameNotFound':"<h5>Person nicht gefunden</h5><p>Unter diesen Daten (Vor- und Nachname, Geburtsdatum) kann derzeit kein Eintrag in der ÖÄK-Standesführung gefunden werden. Möglicherweise hat diese Person noch keine Arztnummer oder ist noch nicht in ihrer Ärztekammer gemeldet. Bitte überprüfen Sie dennoch erneut die eingegebenen Daten auf Richtigkeit. Sie können trotzdem diese Person auf einer Ausbildungsstelle melden.</p>",
				'traineeUnsafeNameFound':"<h5>Person nicht gefunden</h5><p>Unter diesen Daten (Vor- und Nachname, Geburtsdatum) wurde im Datenbestand der Applikation eine Person gefunden. Diese wurde jedoch noch nicht von der ÖÄK Ärzteliste als Arzt/Ärztin bestätigt.</p>"
			};

			$scope.required = {
	            trainee : {
					oakKey : true,
					firstName : true,
					lastName : true,
					dateOfBirth : true
				}
            };

			$scope.isNonEmptyObject = function( o ) {
				return Object.keys(o).length > 0;
			};

			// Init Date Picker Togglers
			$scope.toggler = {
				from : new CommonToggleFactory(),
				to : new CommonToggleFactory(),
				dateOfBirth : new CommonToggleFactory()
			};

			$scope.dateOfBirthOptions = {
				datepickerMode : "'year'"
			};

			$scope.dateOptions = {
				datepickerMode : "'month'"
			};

			$scope.selectOptions = {
				updateOn : "default blur"
			};

			$scope.inputOptions = {
				debounce : {
					default : 300,
					blur : 0
				}
			};

			$scope.dateInputOptions = {
			};

			// Form Data
			$scope.occupancyData = {
				"from" : new Date(),
				"to" : null,
				"allocation" : null,
				"maxAllocation" : $scope.data.trainingPost.maxAllocation,
				"comment" : "",
				"trainee" : {
				}
			};

			// Get Resource for Trainee Search
	        var traineeSearchResource = null;
	        traineeSearchResource = UrlConfigFactory.getTraineeSearchResource();

	        // Update List from Server
	        $scope.getTraineeResult = function() {
				$scope.traineeFound = false;
				$scope.checkTraineeFields();
	        	if ($scope.occupancyData !== null &&
	        		($scope.occupancyData.trainee.oakKey && $scope.occupancyData.trainee.oakKey.match($scope.oakKeyPattern))
	        		|| ($scope.occupancyData.trainee.firstName && $scope.occupancyData.trainee.firstName.length >= $scope.minLengthFirstName
	        			 && $scope.occupancyData.trainee.lastName && $scope.occupancyData.trainee.lastName.length >= $scope.minLengthLastName
	        			 && $scope.occupancyData.trainee.dateOfBirth)) {
		        	$scope.traineeSearch = true;
		            return traineeSearchResource.save(
		            	$scope.occupancyData.trainee,
		                function(data) {
		                	$log.debug("Got Results");
		            		$scope.traineeSearch = false;
		                    if (data.ok) {
		                    	$log.debug(data.payload);
		                    	if (data.payload !== null) {
		                    		if ($scope.occupancyData.trainee.dateOfBirth !== null) {
		                    			data.payload.dateOfBirth = $scope.occupancyData.trainee.dateOfBirth;
		                    		}
		                    		$scope.occupancyData.trainee = data.payload;
									$scope.traineeFound = true;
									if ($scope.occupancyData.trainee.oakKey && $scope.occupancyData.trainee.oakKey.match($scope.oakKeyPattern)) {
										$scope.traineeSearchResult = "nameFound";
									} else {
										$scope.traineeSearchResult = "unsafeNameFound";
										console.log("getTraineeResult1 value: " + $scope.occupancyData.trainee.oakKey + " does not match pattern: " + $scope.oakKeyPattern);
									}
								} else {
									$scope.traineeFound = false;
									$scope.traineeSearchResult = "nameNotFound";
								}
		                    }
		                },
		                function() {
							// TODO: Error Handling
		                    $log.error("Failed to load Trainee Search Results");
							$scope.traineeFound = false;
							$scope.traineeSearch = false;
							$scope.traineeSearchResult = null;
						}
		            );
	        	} else {
					$scope.traineeSearchResult = null;
				}
	        };

			$scope.getTraineeResultByOakKey = function() {
				$scope.checkTraineeFields();
				$scope.traineeFound = false;
				$scope.traineeSearchResult = null;
				if ($scope.occupancyData.trainee.oakKey && $scope.occupancyData.trainee.oakKey.match($scope.oakKeyPattern)) {
					$scope.traineeSearch = true;
					return traineeSearchResource.save(
						$scope.occupancyData.trainee,
						function(data) {
							$scope.traineeSearch = false;
							if (data.ok) {
								$log.debug(data.payload);
								if (data.payload) {
									$log.debug("data Payload !");
									if ($scope.occupancyData.trainee.dateOfBirth !== null) {
										data.payload.dateOfBirth = $scope.occupancyData.trainee.dateOfBirth;
									}
									$scope.occupancyData.trainee = data.payload;
									$scope.traineeFound = true;
									$scope.traineeSearchResult = "oakKeyFound";
								} else {
									$log.debug("data Payload nix !");
									$scope.traineeSearchResult = "oakKeyNotFound";
									$scope.traineeFound = false;
								}
							}
						},
						function() {
							// TODO: Error Handling
							$log.error("Failed to load Trainee Search Results");
							$scope.traineeFound = false;
							$scope.traineeSearch = false;
							$scope.traineeSearchResult = null;
						}
					);
				} else {
					console.log("getTraineeResultByOakKey value: " + $scope.occupancyData.trainee.oakKey + " does not match pattern: " + $scope.oakKeyPattern);
				}
			};


			$scope.resetTraineeFields = function() {
				delete $scope.traineeFound;
				delete $scope.occupancyData.trainee.firstName;
				delete $scope.occupancyData.trainee.lastName;
				delete $scope.occupancyData.trainee.dateOfBirth;
				delete $scope.occupancyData.trainee.oakKey;
				$scope.traineeSearchResult = null;

				$scope.required.trainee = {
				    oakKey : true,
				    firstName : true,
					lastName : true,
					dateOfBirth : true
			    };
			};

			$scope.checkTraineeFields = function() {
				if ($scope.required !== null && $scope.occupancyData !== null) {
				    var checkVal = $scope.occupancyData.trainee;

				    if (checkVal.oakKey && $scope.required.trainee.oakKey) {
						$log.debug("------- re-setting requirements: oakKey=true");
					    $scope.required.trainee = {
						    oakKey : true,
						    firstName : false,
							lastName : false,
							dateOfBirth : false
					    };
					    delete $scope.occupancyData.trainee.firstName;
						delete $scope.occupancyData.trainee.lastName;
						delete $scope.occupancyData.trainee.dateOfBirth;

				    }
				    else if (($scope.required.trainee.firstName || $scope.required.trainee.lastName || $scope.required.trainee.dateOfBirth) && (checkVal.firstName || checkVal.lastName || checkVal.dateOfBirth)) {
						$log.debug("------- re-setting requirements: oakKey=false");
					    $scope.required.trainee = {
						    oakKey : false,
						    firstName : true,
							lastName : true,
							dateOfBirth : true
					    };

					    delete $scope.occupancyData.trainee.oakKey;
				    }
				    else {
						$log.debug("------- re-setting requirements: ALL");
					    $scope.required.trainee = {
						    oakKey : true,
						    firstName : true,
							lastName : true,
							dateOfBirth : true
					    };

					    delete $scope.occupancyData.trainee.firstName;
						delete $scope.occupancyData.trainee.lastName;
						delete $scope.occupancyData.trainee.dateOfBirth;
						delete $scope.occupancyData.trainee.oakKey;
				    }
			    }
		    };


			$scope.getTrainingPostTypeLabel = function( id ) {
				return ServerRemoteConfigFactory.getTrainingPostTypeById(id).shortLabel;
			};

			$scope.getTrainingPostSubjectLabel = function( id ) {
				return ServerRemoteConfigFactory.getSubjectById(id).label;
			};

			$scope.activateToDateRadio = function() {
				$scope.toDateRequired = true;
				$scope.toDateType = "dateSet";
				$timeout( function() {
					document.getElementById('to').focus();
				});
			};

			$scope.resetToDate = function() {
				$scope.toDateRequired = false;
				$scope.occupancyData.to = null;
			};

			$scope.selectTrainee = function(traineeId) {
				$scope.curTrainee = FilterFunctionsService.getObjectById($scope.traineeSearchResult, traineeId, 'id');
				$scope.occupancyData.trainee = angular.copy($scope.curTrainee);
			};

			// Submit Function
			$scope.submit = function() {
				$log.debug($scope.occupancyData);
				if ($scope.toDateType === "openEnded") {
					$scope.occupancyData.to = null;
				}
				$modalInstance.close($scope.occupancyData);
			};

			// Cancel Function
			$scope.cancel = function() {
				$modalInstance.dismiss('cancel');
			};

			// Close Modal if User hits Browser Back Button
			$scope.$on('$routeChangeStart', function(next, current) {
				$scope.cancel();
			});

			// Trainee Search Data
			$scope.traineeSearchResult = null;
			$scope.traineeSearchResult = $scope.getTraineeResult();
		}
	])

	// Modal for TrainingPost Info
	.controller('OverviewModalsCommitDraftsController', [
		'$scope', '$log', '$modalInstance', '$timeout', '$sce',
		'UrlConfigFactory', 'data','OverviewDataFactory', 'AnalyticsFactory',
		function($scope, $log, $modalInstance, $timeout, $sce, UrlConfigFactory, data, OverviewDataFactory, AnalyticsFactory) {
			$scope.isValid = true;
			$scope.isFinished = false;
			$scope.isLoading = false;
			$scope.responseOk = true;
            $scope.dataFactory = OverviewDataFactory;

			$scope.occupancyCount = data.occupancyCount;
			$scope.validationResultCount = data.validationResultCount;

			// Create Status Message
			if ($scope.validationResultCount.FATAL > 0) {
				$scope.validationMessageIcon = "fa-minus-circle";
				$scope.validationMessageClass = "alert-danger";
				$scope.validationMessage = $sce.trustAsHtml("Ihre Meldung enthält fatale Fehler. Sie können Ihre Meldung daher nicht durchführen.");
				$scope.isValid = false;
			}
			else if ($scope.validationResultCount.ERROR > 0 || $scope.validationResultCount.WARNING > 0) {
				$scope.validationMessageIcon = "fa-times-circle";
				$scope.validationMessageClass = "alert-warning";
				$scope.validationMessage = $sce.trustAsHtml("Ihre Meldung enthält nicht korrigierte Fehler oder Warnungen. Sie können Ihre Meldung aber dennoch durchführen.");
			}
			else if ($scope.validationResultCount.PENDING > 0) {
				$scope.validationMessageIcon = "fa-info-circle";
				$scope.validationMessageClass = "alert-info";
				$scope.validationMessage = $sce.trustAsHtml("Die Validierung Ihrer Meldung ist noch nicht abgeschlossen. Wollen Sie dennoch die Meldung durchführen?");
			}
			else {
				$scope.validationMessageIcon = "fa-check-circle";
				$scope.validationMessageClass = "alert-success";
				$scope.validationMessage = $sce.trustAsHtml("Ihre Meldung kann durchgeführt werden");
			}

			$scope.commit = function() {
				var commitResource = UrlConfigFactory.getOccupancyCommitResource();
                var ids = [];
                var allDraftOccupancies = $scope.dataFactory.getAllDraftOccupancies();
                angular.forEach(allDraftOccupancies, function(value,key) {
                    ids.push( key );
                });

	        	commitResource.save( ids, function(data) {
	        		if (data.ok) {
		        		$scope.isLoading = false;
						$scope.responseOk = true;

						$scope.validationMessageIcon = "fa-check-circle";
						$scope.validationMessageClass = "alert-success";
						$scope.validationMessage = $sce.trustAsHtml("Ihre Meldung wurde erfolgreich durchgeführt.");
						AnalyticsFactory.trackOccupancyCommit(data.payload, ids.length, true);
	        		} else {
	        			$scope.validationMessageIcon = "fa-times-circle";
	        			$scope.validationMessageClass = "alert-danger";

	        			var errorMessage = "Ihre Meldung konnte nicht erfolgreich durchgeführt werden.";

					if (data.errors) {
						errorMessage = errorMessage + "<br/>" + data.errors.join("<br/>");
					}

					$scope.validationMessage = $sce.trustAsHtml(errorMessage);

					$scope.isLoading = false;
					$scope.responseOk = false;
					AnalyticsFactory.trackOccupancyCommit(null, ids.length, false);
	        		}
	        	});
			};

			// Send Data
			$scope.submit = function() {
				if ($scope.isFinished && !$scope.isLoading && $scope.responseOk) {
					$scope.ok();
				}
				else {
	                $scope.isFinished = true;
					$scope.isLoading = true;

					$scope.validationMessageIcon = "fa-info-circle";
					$scope.validationMessageClass = "alert-info";
					$scope.validationMessage = $sce.trustAsHtml("Ihre Meldung wird durchgeführt.");

					$scope.commit();
				}
			};

			$scope.ok = function() {
				$modalInstance.close();
			};

			$scope.cancel = function() {
				$modalInstance.dismiss('cancel');
			};

			// Close Modal if User hits Browser Back Button
			$scope.$on('$routeChangeStart', function(next, current) {
				$scope.cancel();
			});
		}
	])

	// Modal for New Occupancy
	.controller('OverviewModalsSplitOccupancyController', [
		'$scope', '$filter', '$log', '$resource', '$modalInstance','$timeout',
		'APP_CONFIG', 'ServerRemoteConfigFactory', 'UrlConfigFactory', 'CommonToggleFactory', 'FilterFunctionsService', 'data', 
		function($scope, $filter, $log, $resource, $modalInstance, $timeout, APP_CONFIG, ServerRemoteConfigFactory, UrlConfigFactory, CommonToggleFactory, FilterFunctionsService, data) {
			$scope.data = data;
			$scope.originalOccupactionByTrainingPostId = {};
			$scope.currentOccupactionByTrainingPostId = {};
			$scope.currentFreeCapacityByTrainingPostId = {};
			$scope.initialFreeCapacityByTrainingPostId = {};
			$scope.dateFormat = APP_CONFIG.dateFormat;

			angular.forEach($scope.data.alternativeTrainingPosts, function(tp) {
				if (data.trainingPost.id === tp.id) {
					$scope.currentOccupactionByTrainingPostId[tp.id] = data.occupancy.allocation;
					$scope.originalOccupactionByTrainingPostId[tp.id] = $scope.currentOccupactionByTrainingPostId[tp.id];
					$scope.initialFreeCapacityByTrainingPostId[tp.id] = data.trainingPost.maxAllocation - data.maxAllocationByTrainingPostId[tp.id] + data.occupancy.allocation;
					$scope.currentFreeCapacityByTrainingPostId[tp.id] =  $scope.initialFreeCapacityByTrainingPostId[tp.id];
				} else {
					$scope.originalOccupactionByTrainingPostId[tp.id] = 0.0;
					$scope.currentOccupactionByTrainingPostId[tp.id] = 0.0;
					$scope.currentFreeCapacityByTrainingPostId[tp.id] = data.trainingPost.maxAllocation - data.maxAllocationByTrainingPostId[tp.id];
					$scope.initialFreeCapacityByTrainingPostId[tp.id] = $scope.currentFreeCapacityByTrainingPostId[tp.id];
				}
			});

			$scope.getCurrentFreeCapacity = function(trainingPostId) {
				return $scope.initialFreeCapacityByTrainingPostId[trainingPostId] - $scope.currentOccupactionByTrainingPostId[trainingPostId];
			};

			$scope.getSumClass = function() {
				return $scope.canSubmit() ? "sumEqual" : "sumNotEqual";
			};

			$scope.canSubmit = function() {
				return $scope.getAllocationDiff() === 0 ? true : false;
			};

			$scope.cancel = function() {
				$modalInstance.dismiss('cancel');
			};

			$scope.submit = function() {
				var splitResource = UrlConfigFactory.getOccupancySplitResource();
				var args = {
					occupancyId: data.occupancy.id,
					allocationByTrainingPost: $scope.currentOccupactionByTrainingPostId
				};
				splitResource.save(args);

				$modalInstance.close();
			};

			$scope.getNewOccupancyStatus = function( trainingPostId ) {
				if ( data.trainingPost.id !== trainingPostId ) {
					if ( $scope.currentOccupactionByTrainingPostId[ trainingPostId ] !== 0 ) {
						return "NEW_OCCUPANCY";
					} else {
						return "NOTHING";
					}
				} else {
					if ( $scope.currentOccupactionByTrainingPostId[ trainingPostId ] !== $scope.data.occupancy.allocation ) {
						return "ORIGINAL_CHANGED";
					} else {
						return "ORIGINAL_UNCHANGED";
					}
				}
			};

			$scope.getAllocationSum = function() {
				var context = { sum: 0 };
				angular.forEach( $scope.currentOccupactionByTrainingPostId, function(value,key) {
					context.sum += value;
				},context );
				return context.sum;
			};

			$scope.getAllocationDiff = function() {
				return $scope.getAllocationSum() - $scope.data.occupancy.allocation;
			};

			// Close Modal if User hits Browser Back Button
			$scope.$on('$routeChangeStart', function(next, current) {
				$scope.cancel();
			});
		}
	]);

})();
