(function(){
	'use strict';

	angular.module('ErrorConfig',[

	])

	.constant('GLOBAL_ERRORS', {
		violationMessages : {
			// ServerValue : ClientValue
			'V0001' : 'Es dürfen nicht mehr als zwei Personen auf einer Stelle gemeldet sein.',
			'V0002' : 'Eine Stelle darf nicht zu mehr als 100% belegt sein.'
		}
	})

	.constant('OVERVIEW_ERRORS', {

	})
	;

})();
