(function(){
    'use strict';

    var filterModule = angular.module('FilterFunctions',[]);

    filterModule.service('FilterFunctionsService', [
        function() {
	        var filterFunctions = {};

	        // Returns a certain Value from a given Array of Object by given ID
            filterFunctions.getObjectValueById = function(objectArray, idKey, idName, valueName) {

	            var result = filterFunctions.getObjectById(objectArray, idKey, idName);

                if (result && angular.isDefined(result[valueName])) {
                    return result[valueName];
                }
                else {
                    return false;
                }
            };

            // Returns an Object from a given Array of Objects by given ID
            filterFunctions.getObjectById = function(objectArray, idKey, idName) {
				for (var i = 0, len = objectArray.length; i < len; i++) {
					if (objectArray[i][idName] === idKey) {
						return objectArray[i];
				    }
			    }
			    return false; //nothing found
            };

            // Returns the Index of an Object from a given Array of Objects by given ID
            filterFunctions.getObjectIndexById = function(objectArray, idKey, idName) {
				for (var i = 0, len = objectArray.length; i < len; i++) {
					if (objectArray[i][idName] === idKey) {
						return i;
				    }
			    }
			    return -1; //nothing found
            };

	        return filterFunctions;
		}
	]);
})();