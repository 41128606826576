const angular = require('angular');

(function(){
	'use strict';

/**
 * Um das Security-Modul nutzen zu können, wird ein Modul wie folgt benötigt
 * (dieses wird an die jeweilige Applikation angepasst)
 */

/** SecurityContributor */

//'use strict';
//
//angular.module('SecurityContributor', [ 'ngRoute', 'LocalConfig' ])
//
//.factory('SecurityContributorFactory', ['$http', '$location',
//	function ($http, $location) {
//		return {
//
//			// Wird bei jeder Änderung der Route aufgerufen, und prüft, ob der User die zu ladende Seite sehen darf
//			rerouteIfNeeded : function(currRoute, user) {
//				// TODO Example Code, implement me
//				// Wenn der der Zugriff eingeschränkt ist, prüfe user & ggf. Permissions
//				if ( typeof(currRoute.access) !== 'undefined' && !currRoute.access.isFree) {
//					if (!user) {
//						$location.path("/login");
//					} else if ( typeof(currRoute.access.permissions) !== 'undefined'
//							&& !hasPermission(user, currRoute.access.permissions)) {
//						$location.path("/noPermission");
//					}
//				// Wenn der user bereits eingeloggt ist, aber die Login-Seite aufruft, leite auf die Übersichtsseite weiter
//				} else if ($location.path() === "/login" && user) {
//					$location.path("/overview");
//				}
//			},
//
//			// Callback, beim (externen) Setzen des Users (zB durch ein Login-Modul) (kann leer sein)
//			onSetUser : function(user) {
//			},
//
//			// Callback, wenn der aktuelle User abgefragt wird (kann leer sein)
//			onBeforeGetUser : function() {
//			},
//
//			// Liefert die Server-URL, welche den aktuell eingeloggten User zurückgibt
//			getUserLookupUrl : function() {
//				// TODO Example Code, implement me
//				return "rest/user/current";
//			},
//
//			// Holt das User Objekt aus der Antwort des Servers (von getUserLookupUrl())
//			getUserFromLookupResponse : function(data) {
//				// TODO Example Code, implement me
//				return data.payload;
//			},
//		};
//}])
//.factory('AuthInterceptorContributorFactory', ['$location',
//	function ($location) {
//		return {
//			// Immer wenn ein HttpError zurückkommt, wird diese Funktion aufgerufen
//			handleHttpError : function(httpStatusCode) {
//				// TODO Example Code, implement me
//				if (httpStatusCode === 500) {
//					$location.path("/unexpected");
//				} else if (httpStatusCode === 401) {
//					$location.path("/login");
//				} else if (httpStatusCode === 404) {
//					$location.path("/notFound");
//				} else if (httpStatusCode === 403) {
//					$location.path("/noPermission");
//				}
//			},
//		};
//}])
//
//;

	angular.module('Security', [
		'ngRoute',
		'SecurityContributor',
		'ngCookies'
	])

	.factory('UserServiceFactory', [
		'$rootScope','$location', '$q', '$log', '$http',
		'SecurityContributorFactory','$cookies',
		function($rootScope, $location, $q, $log, $http, SecurityContributorFactory,$cookies) {
			var authInfo = null;

			var service = {

			};

			service.getAuthInfo = function() {
				return authInfo;
			};

			service.setAuthInfo = function( newAuthInfo ) {
				authInfo = newAuthInfo;
				$rootScope.$broadcast('AuthInfo', authInfo);
			};

			service.clear = function( ) {
				authInfo = null;
				$rootScope.$broadcast('AuthInfo', authInfo);
			};

			service.savePreferences = function (preferences) {
				return $http.put('/rest/user/preferences', preferences);
			};

            service.hasRole = function(roleName) {
                var userRoles = (authInfo ? authInfo.userRoles : null);
                if (!roleName || !userRoles) {
                    return false;
                }
                for (var i = 0; i < userRoles.length; i++) {
                    var role = userRoles[i].role;
                    if (role.name === roleName) {
                        return true;
                    }
                }
                return false;
            };

            service.hasRoleOEAEK = function() {
                return this.hasRole( 'OAK' );
            };

            service.hasRoleSysAdmin = function() {
                return this.hasRole( 'SYS_ADMIN' );
            };

            service.hasRoleLAEK = function() {

                var userRoles = (authInfo ? authInfo.userRoles : null);
                var pattern = /^AEK_/i;
                if (!userRoles) {
                    return false;
                }
                for (var i = 0; i < userRoles.length; i++) {
                    var role = userRoles[i].role;
                    if (pattern.test(role.name)) {
                        return true;
                    }
                }
                return false;
            };





            if ($cookies.ASV_AUTH_INFO) {
				// mh: TODO don't know why I have to do this 2 times
				var initialAuthInfo = angular.fromJson( $cookies.ASV_AUTH_INFO );
				initialAuthInfo = angular.fromJson ( initialAuthInfo );
				service.setAuthInfo(initialAuthInfo);
			}


			return service;
		}
	])

	.run([
		'$rootScope', '$location',
		'UserServiceFactory', 'SecurityContributorFactory',
		function($rootScope, $location, UserServiceFactory, SecurityContributorFactory) {
			$rootScope.$on('$routeChangeStart', function(event, currRoute) {
				var authInfo = UserServiceFactory.getAuthInfo();
				SecurityContributorFactory.rerouteIfNeeded(currRoute, authInfo);
			});
		}
	])

	.factory('AuthInterceptor', [
		'$rootScope', '$q',
		'AuthInterceptorContributorFactory',
		function ($rootScope, $q, handlerService) {
			return {
				responseError: function (response) {
					handlerService.handleHttpError(response);
					return $q.reject(response);
				}
			};
		}
	])

	.config(['$httpProvider', function ($httpProvider) {
		$httpProvider.interceptors.push([
			'$injector',
			function ($injector) {
				return $injector.get('AuthInterceptor');
			}
		]);
	}])
	;

})();
