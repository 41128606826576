(function(){
	'use strict';

	angular.module('Login', [
		'LocalConfig', 'RemoteConfig', 'UserAdmin', 'AsvUI', 'Analytics'
	])

	.controller('LoginController', [
		'$scope', '$rootScope', '$resource', '$http', '$location',
		'UserServiceFactory', 'ROUTE_CONFIG', 'UrlConfigFactory','LinkGeneratorFactory', 'AnalyticsFactory',
		function ($scope, $rootScope, $resource, $http, $location, UserServiceFactory, ROUTE_CONFIG, UrlConfigFactory,LinkGeneratorFactory, AnalyticsFactory) {

			$scope.formData = {};
			$scope.errorMessage = "";
			$scope.isLoading = false;

			$scope.processLoginForm = function() {
				$scope.isLoading = true;
				var resource = UrlConfigFactory.getLoginResource();
				resource.save($scope.formData,
					function(data) {
						$scope.isLoading = false;
						if (!data.ok) {
							$scope.errorMessage = data.errors.join("<br/>");
							UserServiceFactory.clear();
							AnalyticsFactory.trackLogin(false);
						} else {						
							AnalyticsFactory.trackLogin(true, data.payload === null || data.payload === null ? null: data.payload.emailAddress);
							
							// if successful, do login stuff
							UserServiceFactory.setAuthInfo(data.payload);
							// goto main screen
							$location.path( LinkGeneratorFactory.getLocation("start",{}));
						}
					},
					function(data, status, headers, config) {
						UserServiceFactory.clear();
						$scope.isLoading = false;
					}
				);
			};

			$scope.resetErrorMessage = function() {
				if ($scope.errorMessage.length > 0) {
					$scope.errorMessage = "";
				}
			};
	}])


	.controller('LogoutController', [
		'$scope', '$http', '$location', '$window',
		'UserServiceFactory', 'ROUTE_CONFIG', 'UrlConfigFactory', 'ServerRemoteConfigFactory', 'AnalyticsFactory',
		function ($scope, $http, $location, $window, UserServiceFactory, ROUTE_CONFIG, UrlConfigFactory, ServerRemoteConfigFactory, AnalyticsFactory) {
		var logoutResource = UrlConfigFactory.getLogoutResource();
		logoutResource.save(function(data) {

			UserServiceFactory.clear();

			AnalyticsFactory.trackLogout();

			// kp: simply reload the page, way too much state, even in some services...
			var pageURL = $window.location.href.split('#')[0];
			$window.location = pageURL;
		});
	}])

	.controller('ForgottenPasswordController', [
		'$scope', '$location', 'UrlConfigFactory',
		function($scope, $location, UrlConfigFactory) {
			$scope.formData = {
				email: $location.search()['email']
			};
			$scope.errorMessage = null;
			$scope.sent = false;
			$scope.send = function() {
				$scope.resetErrorMessage();
				var resource = UrlConfigFactory.getForgottenPasswordResource();
				var email = $scope.formData.email;
				resource.save(email, function(response) {
					if (response.ok) {
						$scope.sent = true;
					} else {
						$scope.errorMessage = response.errors.join("<br/>");
					}
				});
			};
			$scope.resetErrorMessage = function() {
				$scope.errorMessage = null;
			};
		}
	])

	.controller('ResetPasswordController', [
		'$scope', '$location', 'UrlConfigFactory', 'UserServiceFactory',
		function($scope, $location, UrlConfigFactory, UserServiceFactory) {
			var params = $location.search();
			var email = params.email;
			var token = params.token;
			if (email && token) {
				$scope.errorMessage = null;
			} else {
				$scope.errorMessage = "Ungültiger Passwort-Link!";
			}
			$scope.formData = {
				email: email,
				token: token
			};
			$scope.send = function() {
				$scope.resetErrorMessage();
				var resource = UrlConfigFactory.getResetPasswordResource();
				resource.save($scope.formData, function(response) {
					if (response.ok) {
						var userProfile = response.payload;
						UserServiceFactory.setAuthInfo(userProfile);
						$location.path( "/start" );
					} else {
						$scope.errorMessage = response.errors.join("<br/>");
					}
				});
			};
			$scope.resetErrorMessage = function() {
				$scope.errorMessage = null;
			};
		}
	])

	;

})();