import './OverviewFilter'
import './OverviewModals'
import './OverviewUserData'
import './OverviewData'

// ASV Overview
(function(){
	'use strict';

	angular.module('Overview',[
		// Angular modules
		'ui.bootstrap',
		// AIT tech modules
		'Bootstrap', 'LocalConfig', 'RemoteConfig', 'LinkGenerator',
		// AIT biz modules
		'Sections', 'AsvUI', 'Allocation', 'OverviewData', 'OverviewFilter', 'OverviewModals', 'OverviewUserData'
	])

	// Init Controller
	.controller('OverviewController', [
		'$document', '$scope', '$rootScope', '$filter', '$log', '$timeout', '$routeParams', '$modal', '$location',
		'APP_CONFIG', 'SERVER_CONFIG', 'OverviewDataFactory', 'OverviewFilterFactory', 'CommonToggleFactory', 'UserServiceFactory', 'UrlConfigFactory', 'LinkGeneratorFactory', 'OverviewUserDataFactory',
		function($document, $scope, $rootScope, $filter, $log, $timeout, $routeParams, $modal, $location,
				APP_CONFIG, SERVER_CONFIG, OverviewDataFactory, OverviewFilterFactory, CommonToggleFactory, UserServiceFactory, UrlConfigFactory, LinkGeneratorFactory, OverviewUserDataFactory) {

		// Init Vars
		var userPermissionCheckResource = UrlConfigFactory.getUserPermissionCheckResource();
		var dataFactory = OverviewDataFactory;

		// Init Scope Vars
		$scope.linkGenerator = LinkGeneratorFactory;
		$scope.dateFormat = APP_CONFIG.dateFormat;
		$scope.editMode = false;
		$scope.editModeChanging = false;
		$scope.list = [];
		$scope.activityOverlay = true;
		$scope.plussingActivityOverlay = false;
		$scope.curOrganisationId = 0;
		$scope.curOrganisationName = '';
		$scope.showTrainingPostApplicationLink = false;

		// Toolbar
		$scope.toolbar = {};
		$scope.toolbar.filter = OverviewFilterFactory;
		$scope.toolbar.validationResultCount = {};
		$scope.toolbar.occupancyCount = {};

		$scope.toolbar.menu = {
			menuToggle : new CommonToggleFactory()
		};

		// Init Datepickers
		$scope.toolbar.listDate = {};
		$scope.toolbar.listDate.dateToggle = new CommonToggleFactory();

		// Check User Permissions
		$scope.userEditOccupancies = false;
		$scope.userEditUsers = false;


		// Check User Roles
		// Für später, wenn nicht nur Sys-Admins etc. bearbeiten dürfen
		// Statt $scope.userEditUsers wird dann $scope.userAddTraininpost verwendet

		$scope.isNonEmptyObject = function( o ) {
			return Object.keys(o).length > 0;
		};

		$scope.getEditModeStatus = function() {
			if ($scope.editModeChanging) {
				if (!$scope.editMode) return "TURNING_ON_EDIT_MODE";
				else return "TURNING_OFF_EDIT_MODE";
			} else {
				if (!$scope.editMode) return "EDIT_MODE_OFF";
				else return "EDIT_MODE_ON";
			}
		};

		$scope.toolbar.resetValidationResultCount = function() {
			$scope.toolbar.validationResultCount = {
				'OK' : 0,
				'INFO' : 0,
				'WARNING' : 0,
				'ERROR' : 0,
				'FATAL' : 0,
				'PENDING' : 0
			};
		};

		$scope.toolbar.resetOccupancyCount = function() {
			$scope.toolbar.occupancyCount = {
				'TOTAL' : 0,
				//'NEW' : 0,
				'UPDATE' : 0
			};
		};

		// Init Counters
		$scope.toolbar.resetValidationResultCount();
		$scope.toolbar.resetOccupancyCount();

		$scope.$on('OverviewEditModeChanged', function (event, editMode) {
			OverviewUserDataFactory.setEditMode(editMode);
			$scope.editMode = editMode;
			$scope.editModeChanging = false;
		});

		$scope.$on('OverviewOrgUnitsUpdate', function (event, data) {
			if (data && data.length > 0) {
				// Create special Section for Trainingposts without Section
				angular.forEach(data, function(organisation, organisationKey) {
					if (data[organisationKey].trainingPosts.length > 0) {
						data[organisationKey].trainingPostSections = [{
							'id' : data[organisationKey].id,
							'label' : 'Am Standort',
							'trainingPosts' : data[organisationKey].trainingPosts
						}];
					}
				});

				// Run filter on all Sections etc.
				data = runFilter(data, $scope.toolbar.filter.filterExpressions);

				// Check Permissions for current Organisation
				// WAMVAS-72: defer execution to avoid timeouts on IE due to
				// poor rendering performance of the following digest cycle
				$timeout((function() {
					var paramsPermission = {orgUnitId:OverviewUserDataFactory.getOrgUnitId(), permission:"EDIT_OCCUPANCIES"};
					userPermissionCheckResource.get(paramsPermission, function( result ) {
						if (result.ok && result.payload) {
							$scope.userEditOccupancies = true;
						}
					});
					paramsPermission = {orgUnitId:OverviewUserDataFactory.getOrgUnitId(), permission:"EDIT_USERS"};
					userPermissionCheckResource.get(paramsPermission, function( result ) {
						if (result.ok && result.payload) {
							$scope.userEditUsers = true;
						}
					});	 
				}), 0);
				
				$scope.curOrganisationId = $routeParams.organisationId;
				$scope.curOrganisationName = data[0].label;
				$scope.showTrainingPostApplicationLink = true;
			}
			$scope.list = data;
			$scope.activityOverlay = false;
		});

		$scope.$on('OccupancyUpdates', function (event, myOccupancyUpdates) {
			$scope.activityOverlay = false;
		});

		// Filter Function
		var runFilter = function(data, selectedItems) {
			// Filter Sections
			angular.forEach(data, function(organisation, organisationKey) {
				data[organisationKey].sections = $filter('OverviewFilter')(data[organisationKey].sections ,selectedItems, 'section');

				if (organisation.trainingPostSections && organisation.trainingPostSections.length > 0) {
					data[organisationKey].trainingPostSections = $filter('OverviewFilter')(organisation.trainingPostSections ,selectedItems, 'section');
				}
			});

			// Filter Organisations
			data = $filter('OverviewFilter')(data,selectedItems,'organisation');

			return data;
		};

		$scope.$on('OverviewFilterSettingsChanged', function (event, selectedItems) {
			OverviewUserDataFactory.setFilterItems($scope.toolbar.filter.items);
			OverviewUserDataFactory.setFilterNumItems($scope.toolbar.filter.numItems);

			$scope.list = runFilter($scope.list, selectedItems);
		});

		// Handle ValidationUpdates
		$scope.$on('ValidationUpdates', function (event) {
			$scope.toolbar.resetValidationResultCount();
			var validationResult = dataFactory.getValidationResult();

			if (validationResult && validationResult.validationResultEntries) {
				angular.forEach(validationResult.validationResultEntries, function(entry) {
					if (entry.status === 'COMPLETED') {
						$scope.toolbar.validationResultCount[SERVER_CONFIG.violationCodes[entry.maxSeverity]]++;
					}
					else if (entry.status === 'PENDING') {
						$scope.toolbar.validationResultCount.PENDING++;
					}
				});
			}
			$scope.activityOverlay = false;
		});

		$scope.toolbarDateChanged = function() {
			$log.debug($scope.toolbar.listDate.date);
			if ($scope.toolbar.listDate.date) {
				$scope.activityOverlay = true;
				OverviewUserDataFactory.setDate($scope.toolbar.listDate.date);
				dataFactory.setOverviewDate( $scope.toolbar.listDate.date );
				$rootScope.$broadcast('OverviewDateChanged', $scope.toolbar.listDate.date);
			}
		};

		$scope.$on('OverviewUpdateToolbarCount', function(event, data) {
			//$scope.toolbar.occupancyCount.NEW = $scope.toolbar.occupancyCount.NEW + data.NEW;
			$scope.toolbar.occupancyCount.UPDATE = $scope.toolbar.occupancyCount.UPDATE + data.UPDATE;
		});

		// If Route Params is an Integer
		if ($routeParams.organisationId !== 0 && isFinite($routeParams.organisationId)) {
			// Init Stored Overview Values
			// If Current OrgUnit = Stored OrgUnit
			if (OverviewUserDataFactory.getOrgUnitId() === $routeParams.organisationId) {
				$log.debug("Load User Data");
			}
			else {
				$log.debug("Init User Data");
				OverviewUserDataFactory.reset();
				OverviewUserDataFactory.setOrgUnitId($routeParams.organisationId);
				$scope.toolbar.filter.reset();
				OverviewUserDataFactory.setFilterItems($scope.toolbar.filter.items);
			}

			// Init Values
			$scope.editMode = OverviewUserDataFactory.getEditMode();
			//$rootScope.$broadcast('OverviewEditModeChanged', OverviewUserDataFactory.getEditMode());
			$scope.toolbar.listDate.date = OverviewUserDataFactory.getDate();
			$scope.toolbar.filter.init(OverviewUserDataFactory.getFilterItems(), OverviewUserDataFactory.getFilterNumItems());

			// Load dataFactory
			dataFactory.initialize(OverviewUserDataFactory.getOrgUnitId(), OverviewUserDataFactory.getDate(), OverviewUserDataFactory.getEditMode(), function() {
				// WAMVAS-72: defer execution to avoid timeouts on IE due to
				// poor rendering performance of the following digest cycle
				$timeout((function() {
					dataFactory.startPolling();
				}), 0);
			});
		}

		$scope.toggleEditMode = function() {
			//UserRemoteConfigFactory.setEditMode(!$scope.editMode);
			$scope.editModeChanging = true;
			//$scope.editMode = !$scope.editMode;
			dataFactory.toggleEditMode( function( newEditMode ) {
				$rootScope.$broadcast('OverviewEditModeChanged', newEditMode);
				dataFactory.forcePolling();
			});
		};

		$scope.importOccupancies = function() {
			$location.path("/excel/import/step1/" + $routeParams.organisationId);
		};

		$scope.exportOccupancies = function() {
			$location.path("/excel/export/step1/" + $routeParams.organisationId);
		};

		$scope.openCommitDrafts = function () {
			// Build Data for Modal
			var data = {
				validationResultCount : $scope.toolbar.validationResultCount,
				occupancyCount : $scope.toolbar.occupancyCount
			};

			// Open Modal
			var commitDraftsInstance = $modal.open({
			  templateUrl: '/modules/asv/Overview/OverviewModalsCommitDrafts.html',
			  controller: 'OverviewModalsCommitDraftsController',
			  size: 'md',
			  backdrop: 'static',
			  keyboard: false,
			  resolve: {
				data: function () {
				  return data;
				}
			  }
			});

			// Handle Return
			commitDraftsInstance.result.then(function (commitData) {
				$log.debug('Commit success: ' + new Date());

			}, function () {
				$log.debug('Commit Modal dismissed: ' + new Date());
			});


		};

		$scope.$on("$destroy", function() {
			dataFactory.stopPolling();
		});

	}])

	;

})();
