// ASV Local Config

(function(){
	'use strict';

	//Init ASV Config
	angular.module('LocalConfig', [
		// Angular modules
		'ngResource'
	])

	// Set APP Constants
	.constant('APP_CONFIG', {
		// App Parameters
		appName : 'ÖÄK Ausbildungsstellenverwaltung',
		// Misc Settings
		dateFormat : 'dd.MM.yyyy',
		dateFormatLong : 'dd.MM.yyyy HH:mm:ss',
		serverDateFormat : 'yyyy-MM-dd',
		allocationTimeSteps: 15, // TODO: use RemoteConfig 
		serverless : false,
		debugOutput : true,
        currentYear: new Date().getFullYear()
	})

	.constant('SERVER_CONFIG', {
		validationStatus : {
			'PENDING' : 'PENDING',
			'COMPLETED' : 'COMPLETED',
			'CANCELLED' : 'CANCELLED'
		},
		violationCodes : {
			// ServerValue : ClientValue
			'UNDEFINED' : 'OK',
			'INFO' : 'INFO',
			'WARNING' : 'WARNING',
			'ERROR' : 'ERROR',
			'FATAL' : 'FATAL'
		},
		occupancyStateCodes : {
			// ServerValue : ClientValue
			'ACTIVE' : 'ACTIVE',
			'DRAFT' : 'DRAFT',
			'ARCHIVE': 'ARCHIVE',
			'DELETE' : 'DELETE'
		},
		subjectTypeCodes : {
			// ServerValue : ClientValue
			'SPECIALTY' : {label: 'SF', fullName:'Sonderfach'},
			'SUBSPECIALTY' : {label: 'AF', fullName:'Additivfach'},
			'SPECIALTY_AND_SUBSPECIALTY': {label: 'SF + AF', fullName:'Sonderfach mit Additivfach'},
			'NOT_RELEVANT' : {label: 'Keines', fullName:'Nicht relevant'}
		},
		postTypeCodes : {
			// ServerValue : ClientValue
			'GENERAL_MED' : 'Allgemeinmedizin',
			'SPECIFIC_MED' : 'Facharztausbildung',
			'GENERAL_SPECIFIC' : 'AM/FA',
			'COMMON_TRUNK': 'Basisausbildung',
			'BASIC' : 'Grundausbildung',
			'SPECIAL' : 'Spezialisierung',
			'FOCUS' : 'Schwerpunktausbildung'
		},
		orgunitType : {
			// ServerValue : ClientValue
			'HOSPITAL' : 'Krankenhaus',
			'PRACTICE' : 'Ordination',
			'GROUP_PRACTICE' : 'Gruppenpraxis'
		},
		occupancyCommentSuggestions : [
			{
				label : 'Gegenfach',
				text : 'Ab / Anmeldung GEGENFACH () von () bis () auf ().'
			},
			{
				label : '§14 ÄAO 2015',
				text : 'Grund gemäß §14 ÄAO 2015.'
			}
		]
	})

	.constant('ROUTE_CONFIG', {
		start : {
			path : "/start",
			templateUrl: '/modules/asv/Start/Start.html',
			controller: 'StartController',
			access : {
				isFree : false
			}
		},
		overview : {
			path : "/overview/:organisationId",
			templateUrl: '/modules/asv/Overview/Overview.html',
			controller: 'OverviewController',
			access : {
				isFree : false
			}
		},
		history : {
			path : "/history/:type?/:id?",
			templateUrl: '/modules/asv/History/History.html',
			controller: 'HistoryController',
			access : {
				isFree : false
			}
		},
		excelimport : {
			path : "/excel/import/step1/:organisationId",
			templateUrl: '/modules/asv/Import/ImportStep1.html',
			controller: 'ImportStep1Controller',
			access : {
				isFree : false
			}
		},
		excelimport2 : {
			path : "/excel/import/step2",
			templateUrl: '/modules/asv/Import/ImportStep2.html',
			controller: 'ImportStep2Controller',
			access : {
				isFree : false
			}
		},
		excelimport3 : {
			path : "/excel/import/step3",
			templateUrl: '/modules/asv/Import/ImportStep3.html',
			controller: 'ImportStep3Controller',
			access : {
				isFree : false
			}
		},
		login : {
	 		path : "/login",
			templateUrl: '/modules/asv/Auth/Login.html',
			controller: 'LoginController',
			isDefault : true
		},
		logout : {
			path : "/logout",
			templateUrl: '/modules/asv/Auth/Logout.html',
			controller: 'LogoutController'
		},
		forgottenPassword : {
			path : "/forgotten-password",
			templateUrl: '/modules/asv/Auth/ForgottenPassword.html',
			controller: 'ForgottenPasswordController'
		},
		resetPassword : {
			path : "/reset-password",
			templateUrl: '/modules/asv/Auth/ResetPassword.html',
			controller: 'ResetPasswordController'
		},
		// Backend
		admin : {
			path : "/backend",
			templateUrl: '/modules/asv/Backend/Overview.html',
			controller: 'BackendOverviewController',
			access : {
				isFree : false,
				permissions : ['ADMIN']
			}
		},
		userAdmin : {
			path : "/backend/user-admin",
			templateUrl: '/modules/asv/Backend/UserAdmin/UserAdmin.html',
			controller: 'UserAdminController',
			access : {
				isFree : false
			}
		},
		// trainingPostApplication : {
		// 	path : "/backend/training-post-application/:organisationId/:organisationName",
		// 	templateUrl: '/modules/asv/Backend/TrainingPostApplication/TrainingPostApplication.html',
		// 	controller: 'TrainingPostApplicationController',
		// 	access : {
		// 		isFree : false
		// 	}
		// },
		
		// allgemeine (Fehler-)Seiten
		noPermission : {
			path : "/no-permission",
			templateUrl: '/modules/asv/Commons/Templates/no-permission.html'
		},
		notFound : {
			path : "/not-found",
   			templateUrl: '/modules/asv/Commons/Templates/not-found.html'
		},
		unexpected : {
			path : "/unexpected-error",
			templateUrl: '/modules/asv/Commons/Templates/unexpected-error.html'
		}
	})

	.factory('UrlConfigFactory', [
		// Angular modules
		'$resource',
		// ASV modules
		'APP_CONFIG',
		function($resource, APP_CONFIG) {
		var getUrl = function(name) {
			return (APP_CONFIG.serverless ? '/data' : '/rest')
				+ name
				+ (APP_CONFIG.serverless ? '.json' : '');
		};

		var defaultActions = {
			'get' : {method:'GET',timeout: 10000, cache: false},
			'save' : {method:'POST', timeout: 10000, cache: false},
			'query' : {method:'GET', timeout: 10000, cache: false, isArray: true},
			'remove' : {method:'DELETE', timeout: 10000, cache: false},
			'delete' : {method:'DELETE', timeout: 10000, cache: false}
		};


		var getUrlResource = function(resourceName, resourceParams) {
	        var result;
            if (!resourceParams) resourceParams = {};
	        if (APP_CONFIG.serverless) {
	        	result = $resource(getUrl(resourceName), {}, {
	        		'get' : {method:'GET'},
	        		'save' : {method:'GET'},
	        		'query' : {method:'GET', isArray:true},
	        		'remove' : {method:'GET'},
	        		'delete' : {method:'GET'}
	        	});
	        } else {
	        	result = $resource(getUrl(resourceName),  resourceParams, defaultActions);
	        }

			return result;
		};

		// Falls neue Daten-URLs benötigt werden, können diese hier
		// hinzugefügt werden.
		return {
			getOrganisationListResource : function() {
				return getUrlResource('/start/organisations/list');
			},
			getOrganisationDraftListResource : function() {
				return getUrlResource('/start/organisations/draftlist');
			},
			getActivityFeedListResource : function() {
				return getUrlResource('/feed/activity/list');
			},
			getLinkFeedListResource : function() {
				return getUrlResource('/feed/link/list');
			},
            getEditModeResource : function() {
                return getUrlResource('/overview/editMode');
            },
			getOverviewDateResource : function() {
				return getUrlResource('/overview/date/:date',{ date : '@date' });
			},
			getOverviewListResource : function() {
				return getUrlResource('/overview/list/:organisationId/:date/:editMode',
						{ organisationId: '@id', date: '@date', editMode: '@editMode' });
			},
			getOverviewDefaultListResource : function() {
				return getUrlResource('/overview/list/:organisationId',{ organisationId:'@id' });
			},
            getPollingResource : function() {
                return getUrlResource('/overview/poll/:validationId',{ validationId:'@id' });
            },
			getOccupancyUpdateResource : function() {
				return getUrlResource('/occupancy/update');
			},
			getOccupancyCreateResource : function() {
				return getUrlResource('/occupancy/create');
			},
			getOccupancySplitResource : function() {
				return getUrlResource('/occupancy/split');
			},
			getOccupancyRevokeResource : function() {
				return getUrlResource('/occupancy/revoke/:occupancyId',{ occupancyId: '@occupancyId'});
			},
			getOccupancyCommitResource : function() {
				return getUrlResource('/occupancy/commit');
			},
			occupancyCreate : function() {
				return getUrl('/occupancy/create');
			},
			getOccupanciesDeleteResource : function() {
				return getUrlResource('/occupancy/delete');
			},
			getLoginResource : function() {
				return getUrlResource('/auth/login');
			},
			getForgottenPasswordResource : function() {
				return getUrlResource('/auth/forgotten-password');
			},
			getResetPasswordResource : function() {
				return getUrlResource('/auth/reset-password');
			},
			getCurrentUserUrl : function() {
				return getUrl('/auth/current');
			},
			getUserPermissionCheckResource : function() {
				return getUrlResource('/auth/has-permission');
			},
			getUserRoleCheckResource : function() {
				return getUrlResource('/auth/has-role');
			},
			getExcelImportFileUploadUrl : function() {
				return getUrl('/excel/precheck');
			},
			getExcelImportValidationCheckResource : function() {
				return getUrlResource('/excel/validate/:id',{ id: '@id' });
			},
			getExcelImportCommittingResource : function() {
				return getUrlResource('/excel/commit');
			},
			getRemoteConfigResource : function() {
				return getUrlResource('/appdata/load');
			},
			getLogoutResource : function() {
				return getUrlResource('/auth/logout');
			},
			getHistoryResource : function() {
				return getUrlResource('/history/:type/:id',{ type:'@type',id: '@id' });
			},
			getTraineeHistoryResource : function() {
				return getUrlResource('/history/trainee');
			},
			getTraineeSearchResource : function() {
				return getUrlResource('/trainee/search');
			},
			getTrainingPostPlusResource : function() {
				return getUrlResource('/trainingpost/plus/:id',{ id: '@id' });
			},
			getTrainingPostApplicationUploadUrl : function() {
				return getUrl('/trainingpost/application');
			}
		};
	}])

	;

})();
