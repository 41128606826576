// ASV Analytics

(function(){
    'use strict';

    angular.module('Analytics',[
    ])

	// Init Factory
	.factory('AnalyticsFactory', ['$log',
		function ($log) {
			// Init Vars and return block
			return {
				trackLogin: function (wasSuccessful, userEmail) {
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
//						$log.info("trackLogin called with successful-flag: " + wasSuccessful);
						//call piwik method push to track the event of a user-login
//						$log.info("---usermail: " + userEmail);
						_paq.push(['trackEvent', 'BenutzerSession', 'Login', wasSuccessful ? 'erfolgreich' : 'fehlgeschlagen']);
						if(userEmail !== null) {
							_paq.push(['setUserId', userEmail]);
							_paq.push(['trackPageView']);
						}
						$log.info("trackLogin called after piwik push");
					}
				},
				trackLogout: function () {
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
						//call piwik method to track the event of a user-logout
						_paq.push(['trackEvent', 'BenutzerSession', 'Logout']);
//						$log.info("trackLogout called after piwik push");
					}
				},
				trackOccupancyCreation: function (orgUnit, occupancyData) {
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
//						var orgLabel = typeof(orgUnit) !== 'undefined' && angular.isDefined(orgUnit) ? orgUnit.oakKey : "";
						//call piwik method to track the event for a created occupancy draft
//						_paq.push(['trackEvent', 'Meldung', 'DraftAngelegt', 'OakNr', orgUnit.id]); //TBD
						_paq.push(['trackEvent', 'Meldung', 'DraftAngelegt', 'ID', occupancyData !== null && occupancyData.occupancyId !== null ? occupancyData.occupancyId : '']);
//						_paq.push(['trackEvent', 'Meldung', 'DraftAngelegt', orgLabel]);
//						$log.info("trackOccupancyCreation called after piwik push");
					}
				},
				trackOccupancyCommit: function (occupancySetId, occupancyCount, wasSuccessful) {
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
//						var orgLabel = typeof(orgUnit) !== 'undefined' && angular.isDefined(orgUnit) ? orgUnit.oakKey : "";
						//call piwik method to track the event for a created/changend occupancy
//						$log.info("---occupancySetId: " + occupancySetId);
//						_paq.push(['trackEvent', 'Meldungen', 'Durchführen', wasSuccessful ? 'erfolgreich' : 'fehlgeschlagen', occupancyCount]);
						if(occupancySetId === null) {
							_paq.push(['trackEvent', 'Meldungen', 'Durchführen', wasSuccessful ? 'erfolgreich' : 'fehlgeschlagen']);
						} else {
							_paq.push(['trackEvent', 'Meldungen', 'Durchführen', wasSuccessful ? 'erfolgreich' : 'fehlgeschlagen', occupancySetId]);
						}
//						$log.info("trackOccupancyComit called after piwik push");
					}
				},
				trackOccupancyDeletion: function (occupancyId, trainingPost, orgUnit, draftMode) {
//					$log.info("trackOccupancyDeletion.occupancyId: " + occupancyId);
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
//						$log.info("trackOccupancyDeletion.draftMode: " + draftMode);
						//call piwik method to track the event when the delete/revoke buttons for an occupancy are clicked
						//(that does not mean the validation goes ok for committing a revoked occupancy, because this happens in the committing-modal)
						
						_paq.push(['trackEvent', 'Meldung', draftMode ? 'Verwerfen' : 'Widerrufen', 'ID', occupancyId]);
//						$log.info("trackOccupancyDeletion called after piwik push");
					}
				},
				trackUserNavigation: function (path, userEmail) {
					if (typeof(_paq) !== 'undefined' && angular.isDefined(_paq)) {
//						_paq.push(['setUserId', userEmail]);
//						_paq.push(['trackPageView']);
						$log.info("trackUserNavigation.path: " + path + ", userId: " + userEmail);
						//call piwik method to track the event when an navigational route was permitted
						
						_paq.push(['trackEvent', 'BenutzerSession', 'Navigation', path]);
						$log.info("trackUserNavigation called after piwik push");						
					}
				},
				initializePiwik: function (piwikId, piwikEnabled, userEmail) {
//					$log.info("initialize piwik called - flag piwikEnabled = " + piwikEnabled);
					//an alternative will be the method: setDoNotTrack( bool ) - Set to true to not track users who opt out of tracking using Mozilla's (proposed) Do Not Track setting.
//					$log.info("---usermail: " + userEmail);
//					$log.info("---piwikId: " + piwikId);
					if(piwikEnabled) {
						var s = document.createElement('script'); // use global document since Angular's $document is weak
						s.setAttribute("type", "text/javascript");
						s.innerHTML = //"alert(\"12345test: " + piwikId + "\");\n" +
								"var _paq = _paq || [];\n" +
//								"_paq.push(['setUserId'," + userEmail + "]);\n" +
								"_paq.push(['trackPageView']);\n" +
								"_paq.push(['enableLinkTracking']);\n" +
								"(function() {\n" +
								"var u=\"http://stats.aerztekammer.at/\";\n" + //add protocol here, because otherwise there are problems with https pages -> piwik.js load aborted
								"_paq.push(['setTrackerUrl', u+'piwik.php']);\n" +
								"_paq.push(['setSiteId', " + piwikId + "]);\n" +
								"var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
								"g.type='text/javascript'; g.async=true; g.defer=true; g.src='js/piwik.js'; s.parentNode.insertBefore(g,s);\n" +
//								"g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);\n" +
								"})();\n";

						document.body.appendChild(s);
						/* Info how piwik analytics-data for a specific site can be deleted can be found inside README.md
						 */
					}
				}				
			};
		}
	])
    ;

})();
