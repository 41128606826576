const uiGrid = require('angular-ui-grid/ui-grid');
const uiSelect = require('ui-select');
const uiSwitch = require('angular-ui-switch');
const ngPasswordStrength = require('ng-password-strength');

import _ from 'lodash'; // for ngPasswordStrength

(function () {
	'use strict';

	var mod = angular.module('UserAdmin', [
		'ui.bootstrap', 'ui.grid', 'ui.grid.selection', 'ui.select', 'uiSwitch', 'ngPasswordStrength'
	]);

	mod.constant('FEDERAL_STATES', [
		{name: 'BGLD', label: 'Burgenland'},
		{name: 'KTN', label: 'Kärnten'},
		{name: 'NOE', label: 'Niederösterreich'},
		{name: 'OOE', label: 'Oberösterreich'},
		{name: 'SBG', label: 'Salzburg'},
		{name: 'STMK', label: 'Steiermark'},
		{name: 'TIR', label: 'Tirol'},
		{name: 'VBG', label: 'Vorarlberg'},
		{name: 'WIEN', label: 'Wien'}
	]);

	mod.constant('ORG_UNIT_TYPES', [
		{name: 'HOSPITAL', label: 'Krankenhaus', root: true, icon: 'fa-h-square'},
		{name: 'DEPARTMENT', label: 'Abteilung', root: false, icon: 'fa-h-square'},
		{name: 'PRACTICE', label: 'Ordination', root: true, icon: 'fa-stethoscope'},
		{name: 'GROUP_PRACTICE', label: 'Gruppenpraxis', root: true, icon: 'fa-users'},
		{name: 'OTHERS', label: 'Sonstige', root: true, icon: 'fa-question-circle'}
	]);

	mod.factory('OrgUnitService', ['$http', function ($http) {
			var baseURL = '/rest/org-unit/';
			return {
				getOrgUnits: function (orgUnitFilter, pageIndex, pageSize) {
					var params = angular.copy(orgUnitFilter);
					if (pageIndex) {
						params.page = pageIndex;
					}
					if (pageSize) {
						params.size = pageSize;
					}
					var config = {
						url: baseURL,
						params: params
					};
					var res = $http(config).then(function (data) { return (data) ? data.data : data;});
					return res;
				},
				getOrgUnitLabels: function (orgUnitFilter, pageIndex, pageSize) {
					var params = angular.copy(orgUnitFilter);
					if (pageIndex) {
						params.page = pageIndex;
					}
					if (pageSize) {
						params.size = pageSize;
					}
					var config = {
						url: baseURL + 'labels',
						params: params
					};
					return $http(config).then(function (data) { return (data) ? data.data : data;});
				}
			};
		}]);

	mod.factory('UserService', ['$http', function ($http) {
			var baseURL = '/rest/user/';
			return {
				getUsers: function (userFilter, pageIndex, pageSize, sort) {
					var params = angular.copy(userFilter);
					if (pageIndex) {
						params.page = pageIndex;
					}
					if (pageSize) {
						params.size = pageSize;
					}
					params.sort = sort ? sort : 'lastName,asc';
					return $http({url: baseURL, params: params}).then(function (data) { return (data) ? data.data : data;});
				},
				getLastNames: function (userFilter, pageIndex, pageSize) {
					var params = angular.copy(userFilter);
					if (pageIndex) {
						params.page = pageIndex;
					}
					if (pageSize) {
						params.size = pageSize;
					}
					var config = {
						url: baseURL + "/last-names",
						params: params
					};
					return $http(config).then(function (data) { return (data) ? data.data : data;});
				},
				getProfile: function (id) {
					return $http.get(baseURL + id + '/profile').then(function (data) { return (data) ? data.data : data;});
				},
				getAllRoles: function () {
					return $http.get(baseURL + 'all-roles').then(function (data) { return (data) ? data.data : data;});
				},
				getRoleCapabilities: function (existingUserRoles) {
					return $http.post(baseURL + 'role-capabilities', existingUserRoles).then(function (data) { return (data) ? data.data : data;});
				},
				isUniqueEmail: function (id, email) {
					return $http.get(baseURL + id + '/unique-email?email=' + email).then(function (data) { return (data) ? data.data : data;});
				},
				save: function (profile) {
					var id = profile.id;
					if ( profile && typeof profile['preferences'] === 'object') {
					    var preferencesJson = angular.toJson( profile['preferences'] );
					    profile['preferences'] = preferencesJson;
                    }
					return (id === 0 ? $http.post(baseURL, profile) : $http.put(baseURL + id, profile)).then(function (data) { return (data) ? data.data : data;});
				},
				deleteUser: function (id) {
					return $http.delete(baseURL + id).then(function (data) { return (data) ? data.data : data;});
				}
			};
		}]);

	mod.factory('OrgUnitTreeDisplay', ['$sce', function ($sce) {
			return function (orgUnits, index) {
				var orgUnit = orgUnits[index];
				var label = orgUnit.label;
				var len = label.length;
				var prevLabel = (index === 0 ? [] : orgUnits[index - 1].label);
				var prevLen = prevLabel.length;
				var indent = 0;
				for (var i = 0; i < Math.min(len, prevLen); i++) {
					if (label[i] === prevLabel[i]) {
						++indent;
					} else {
						break;
					}
				}
				var html = '<span style="padding-left: ' + indent + 'em;">';
				html += '<i class="fa fa-caret-right"></i> ';
				for (var i = indent; i < len; i++) {
					html += label[i];
					if (i < len - 1) {
						html += ' <i class="fa fa-angle-right"></i> ';
					}
				}
				if (len === 1) {
					html += ' (' + orgUnit.zip + ' ' + orgUnit.city + ')';
				}
				html += '</span>';
				return $sce.trustAsHtml(html);
			};
		}]);

	mod.controller('UserAdminController', [
		'$scope', '$modal', 'OrgUnitService', 'UserService', 'FEDERAL_STATES', 'ORG_UNIT_TYPES',
		function ($scope, $modal, OrgUnitService, UserService, FEDERAL_STATES, ORG_UNIT_TYPES) {

			// filter management
			$scope.orgUnitFilter = {
			};

			$scope.userFilter = {
				active: null
			};

			// federal state
			$scope.federalStates = FEDERAL_STATES;
			$scope.selectedFederalState = null;
			$scope.selectFederalState = function (index) {
				var state = (index === -1 ? null : $scope.federalStates[index]);
				$scope.selectedFederalState = state;
				$scope.orgUnitFilter.federalState = (state ? state.name : null);
				$scope.$broadcast('reloadOrgUnits');
			};

			// org-unit type
			$scope.orgUnitTypes = ORG_UNIT_TYPES;
			$scope.selectedOrgUnitType = null;
			$scope.selectOrgUnitType = function (index) {
				var type = (index === -1 ? null : $scope.orgUnitTypes[index]);
				$scope.selectedOrgUnitType = type;
				$scope.orgUnitFilter.rootUnitType = (type ? type.name : null);
				$scope.$broadcast('reloadOrgUnits');
			};

			// org-unit labels
			$scope.orgUnitLabels = [];
			$scope.loadOrgUnitLabels = function (label) {
				if (!label || label.length < 3) {
					$scope.orgUnitLabels = [];
					if (!label) {
						$scope.orgUnitFilter.rootUnitLabel = null;
						$scope.onOrgUnitLabelSelected();
					}
					return;
				}
				var labelFilter = angular.copy($scope.orgUnitFilter);
				labelFilter.rootUnitLabel = label;
				labelFilter.labelMatchPolicy = 'CONTAINS';
				labelFilter.rootUnit = true;
				OrgUnitService.getOrgUnitLabels(labelFilter, 0, 300)
						.then(function (labels) {
							$scope.orgUnitLabels = labels;
						});
			};
			$scope.onOrgUnitLabelSelected = function () {
				$scope.$broadcast('reloadOrgUnits');
			};

			// user last name
			$scope.userLastNames = [];
			$scope.loadUserLastNames = function (lastName) {
				if (!lastName || lastName.length === 0) {
					$scope.userLastNames = [];
					$scope.userFilter.lastName = null;
					$scope.$broadcast('reloadUsers');
					return;
				}
				var lastNameFilter = angular.copy($scope.userFilter);
				lastNameFilter.lastName = lastName;
				lastNameFilter.nameMatchPolicy = 'STARTS_WITH';
				UserService.getLastNames(lastNameFilter).then(function (lastNames) {
					$scope.userLastNames = lastNames;
				});
			};
			$scope.onUserLastNameSelected = function () {
				$scope.$broadcast('reloadUsers');
			};

			// role
			$scope.allRoles = [];
			$scope.selectedRole = null;
			$scope.loadAllRoles = function () {
				UserService.getAllRoles().then(function (roles) {
					$scope.allRoles = roles;
				});
			};
			$scope.selectRole = function (index) {
				var role = (index === -1 ? null : $scope.allRoles[index]);
				$scope.selectedRole = role;
				$scope.userFilter.role = (role ? role.name : null);
				$scope.$broadcast('reloadUsers');
			};
			$scope.loadAllRoles();

			// user state
			$scope.selectActive = function(active) {
				$scope.userFilter.active = active;
				$scope.$broadcast('reloadUsers');
			};

			// user management
			$scope.editUser = function(userId) {
				UserService.getProfile(userId).then(function(profile) {
					$scope.editProfile(profile);
				});
			};
			$scope.createUser = function() {
				var profile = {
					id: 0,
					active: true,
					userRoles: [],
					updatePassword: true
				};
				$scope.editProfile(profile);
			};
			$scope.editProfile = function(profile) {
				var dlg = $modal.open({
					templateUrl: '/modules/asv/Backend/UserAdmin/UserEditor.html',
					controller: 'UserEditorController',
					size: 'lg',
					backdrop: 'static',
					resolve: {
						profile: function () {
							return profile;
						},
						orgUnitFilter: function () {
							return $scope.orgUnitFilter;
						},
						selectedOrgUnit: function () {
							return $scope.selectedOrgUnit;
						}
					}
				});
				dlg.result.then(function(profile) {
					$scope.$broadcast("reloadUsers");
				});
			};
			$scope.copyUser = function(userId) {
				UserService.getProfile(userId).then(function(profile) {
					profile.id = 0;
					profile.active = true;
					profile.firstName = null;
					profile.lastName = null;
					profile.emailAddress = null;
					profile.updatePassword = true;
					$scope.editProfile(profile);
				});
			};

			// event handling
			$scope.$on('orgUnitSelected', function (event, orgUnit) {
				$scope.selectedOrgUnit = orgUnit;
				$scope.userFilter.orgUnitId = (orgUnit ? orgUnit.id : null);
				$scope.$broadcast('reloadUsers');
			});
			$scope.$on('editUser', function (event, user) {
				$scope.editUser(user.id);
			});
			$scope.$on('copyUser', function (event, user) {
				$scope.copyUser(user.id);
			});
		}
	]);

	mod.controller('OrgUnitGridController', [
		'$scope', '$timeout', 'OrgUnitService',
		function ($scope, $timeout, OrgUnitService) {
			$scope.pageNum = 1;
			$scope.pageSize = 100;
			$scope.totalCount = 0;
			var typeCol = {
				field: 'type',
				displayName: 'Typ',
				cellTemplate: 'orgUnitTypeCell.html',
				width: 50,
				enableColumnMenu: false
			};
			var labelCol = {
				field: 'label',
				displayName: 'Organisation',
				cellTemplate: 'orgUnitLabelCell.html',
				enableColumnMenu: false
			};
			$scope.gridOptions = {
				columnDefs: [typeCol, labelCol],
				rowHeight: 50,
				enableSorting: false,
				enableRowSelection: true,
				enableRowHeaderSelection: false,
				multiSelect: false,
				noUnSelect: true,
				modifierKeysToMultiSelect: false,
				enableColumnMenu: false,
				getRowIdentity: function (row) {
					return row.id;
				},
				rowIdentity: function (row) {
					return row.id;
				},
				onRegisterApi: function (gridApi) {
					$scope.gridApi = gridApi;
					gridApi.selection.on.rowSelectionChanged($scope, function (row) {
						var orgUnit = (row.isSelected ? row.entity : null);
						$scope.$emit('orgUnitSelected', orgUnit);
					});
				}
			};
			$scope.loadOrgUnits = function () {
				if ($scope.gridApi) {
					var selectedRows = $scope.gridApi.selection.getSelectedRows();
					if (selectedRows.length > 0) {
						$scope.gridApi.selection.clearSelectedRows();
						$scope.$emit('orgUnitSelected', null);
					}
				}
				var pageIndex = $scope.pageNum - 1;
				var pageSize = $scope.pageSize;
				OrgUnitService.getOrgUnits($scope.orgUnitFilter, pageIndex, pageSize)
						.then(function (page) {
							$scope.totalCount = page.totalElements;
							var orgUnits = page.content;
							$scope.gridOptions.data = orgUnits;
							if ($scope.totalCount === 1) {
								$timeout(function () {
									$scope.gridApi.selection.selectRow(orgUnits[0]);
								});
							}
						});
			};
			$scope.$on('reloadOrgUnits', function (event) {
				$scope.loadOrgUnits();
			});
			$scope.loadOrgUnits();
		}
	]);


	mod.controller('UserGridController', [
		'$scope', '$timeout', 'UserService',
		function ($scope, $timeout, UserService) {
			$scope.pageNum = 1;
			$scope.pageSize = 100;
			$scope.totalCount = 0;
			var firstNameCol = {
				field: 'firstName',
				displayName: 'Vorname',
				enableColumnMenu: false
			};
			var lastNameCol = {
				field: 'lastName',
				displayName: 'Nachname',
				enableColumnMenu: false
			};
			var emailCol = {
				field: 'emailAddress',
				displayName: 'E-Mail',
				cellTemplate: 'userEmailCell.html',
				enableColumnMenu: false
			};
			var activeCol = {
				name: 'active',
				displayName: '',
				enableSorting: false,
				width: 30,
				cellTemplate: 'userActiveCell.html',
				enableColumnMenu: false
			};
			var actionCol = {
				name: 'actions',
				displayName: '',
				enableSorting: false,
				width: 70,
				cellTemplate: 'userActionCell.html',
				enableColumnMenu: false
			};
			$scope.gridOptions = {
				columnDefs: [activeCol, firstNameCol, lastNameCol, emailCol, actionCol],
				enableRowSelection: true,
				enableRowHeaderSelection: false,
				multiSelect: false,
				noUnSelect: true,
				modifierKeysToMultiSelect: false,
				getRowIdentity: function (row) {
					return row.id;
				},
				rowIdentity: function (row) {
					return row.id;
				}
			};
			$scope.loadUsers = function () {
				var pageIndex = $scope.pageNum - 1;
				var pageSize = $scope.pageSize;
				UserService.getUsers($scope.userFilter, pageIndex, pageSize)
						.then(function (page) {
							$scope.totalCount = page.totalElements;
							var users = page.content;
							// workaround for https://github.com/angular-ui/ng-grid/issues/1302
							// if (!$scope.gridOptions.data) {
							// 	$scope.gridOptions.data = {}
							// }
							// $scope.gridOptions.data.length = 0;
							// $timeout(function() {
								$scope.gridOptions.data = users;
							// });
							// $scope.$digest();

						});
			};
			$scope.edit = function(user) {
				$scope.$emit('editUser', user);
			};
			$scope.copy = function(user) {
				$scope.$emit('copyUser', user);
			};
			$scope.$on('reloadUsers', function(event) {
				$scope.loadUsers();
			});
			$scope.loadUsers();
		}
	]);

	mod.controller('UserEditorController', [
		'$scope', '$modalInstance', 'UserServiceFactory', 'OrgUnitService',
		'OrgUnitTreeDisplay', 'UserService', 'profile', 'orgUnitFilter', 'selectedOrgUnit',
		function ($scope, $modalInstance, UserServiceFactory, OrgUnitService,
				OrgUnitTreeDisplay, UserService, profile, orgUnitFilter, selectedOrgUnit) {
			$scope.profile = profile;
			$scope.lastSavedProfile = null;

			var updateDisplayName = function() {
				$scope.displayName = (profile.id === 0 ? 'Neuer Benutzer' : 'Benutzer ' + profile.firstName + ' ' + profile.lastName);
			};
			updateDisplayName();

			// alerts and messages
			$scope.alerts = [];
			$scope.addAlert = function(type, message, clear) {
				if (clear) {
					$scope.alerts = [];
				}
				var alert = {
					type: type,
					msg: message
				};
				$scope.alerts.push(alert);
			};
			$scope.addAlerts = function(type, messages) {
				angular.forEach(messages, function (message) {
					$scope.addAlert(type, message);
				});
			};
			$scope.closeAlert = function (index) {
				$scope.alerts.splice(index, 1);
			};

			// self-editing
			var authInfo = UserServiceFactory.getAuthInfo();
			$scope.selfie = (profile.id === authInfo.id);

			// password management
			$scope.updatePassword = function () {
				$scope.profile.updatePassword = true;
			};
			$scope.cancelUpdatePassword = function () {
				$scope.profile.updatePassword = false;
			};
			$scope.passwordStrengthStatus = {
				open: false
			};
			$scope.showPasswordStrength = function(open) {
				$scope.passwordStrengthStatus.open = open;
			};

			// role management
			$scope.grantableRoles = [];
			$scope.revokableUserRoles = [];
			$scope.loadRoleCapabilities = function () {
				if ($scope.selfie) {
					return;
				}
				UserService.getRoleCapabilities(profile.userRoles).then(function(capabilities) {
					$scope.grantableRoles = capabilities.grantableRoles;
					$scope.revokableUserRoles = capabilities.revokableUserRoles;
				});
			};
			$scope.loadRoleCapabilities();
			$scope.isRevokableRole = function(userRole) {
				var scope = userRole.scope;
				var orgUnitId = (userRole.orgUnit ? userRole.orgUnit.id : -1);
				for (var i = 0; i < $scope.revokableUserRoles.length; i++) {
					var rev = $scope.revokableUserRoles[i];
					var revOrgUnitId = (rev.orgUnit ? rev.orgUnit.id : -1);
					if (scope === rev.scope && orgUnitId === revOrgUnitId) {
						return true;
					}
				}
				return false;
			};
			$scope.revokeRole = function (index) {
				$scope.profile.userRoles.splice(index, 1);
				$scope.loadRoleCapabilities();
			};

			$scope.newUserRole = null;
			$scope.startGrantRole = function(index) {
				var role = $scope.grantableRoles[index];
				$scope.newUserRole = {
					id: 0,
					role: role,
					orgUnit: $scope.selectedOrgUnit
				};
				if (role.scope !== 'ORG_UNIT' || $scope.selectedOrgUnit) {
					$scope.doGrantRole();
				}
			};
			$scope.orgUnitFilter = (orgUnitFilter ? orgUnitFilter : {});
			$scope.selectedOrgUnit = selectedOrgUnit;
			$scope.orgUnits = [];
			$scope.loadOrgUnits = function(filter) {
				OrgUnitService.getOrgUnits(filter, 0, 300)
						.then(function (page) {
							$scope.orgUnits = page.content;
						});
			};
			$scope.searchOrgUnits = function(label) {
				if (!label || label.length === 0) {
					return;
				}
				var filter = angular.copy($scope.orgUnitFilter);
				filter.rootUnitLabel = label;
				filter.labelMatchPolicy = 'CONTAINS';
				$scope.loadOrgUnits(filter);
			};
			$scope.getOrgUnitDisplay = OrgUnitTreeDisplay;
			$scope.loadOrgUnits($scope.orgUnitFilter);
			$scope.newOrgUnitChanged = function () {
				var orgUnit = $scope.newUserRole.orgUnit;
				if (orgUnit) {
					$scope.doGrantRole();
				}
			};
			$scope.cancelGrantRole = function() {
				$scope.newUserRole = null;
			};
			$scope.doGrantRole = function() {
				$scope.profile.userRoles.push($scope.newUserRole);
				$scope.newUserRole = null;
				$scope.loadRoleCapabilities();
			};

			// CRUD actions
			$scope.save = function () {
				UserService.save($scope.profile).then(function(response) {
					if (response.ok) {
						$modalInstance.close(response.payload);
					} else {
						$scope.addAlerts('danger', response.errors);
					}
				});
			};
			$scope.deleteUser = function () {
				var f = function(response) {
					if (response.ok) {
						$modalInstance.close(response.payload);
					} else {
						$scope.addAlerts('danger', response.errors);
					}
				};
				UserService.deleteUser($scope.profile.id).then(f);
			};
			$scope.saveAndAdd = function() {
				UserService.save($scope.profile).then(function(response) {
					if (response.ok) {
						$scope.lastSavedProfile = response.payload;
						$scope.profile.id = 0;
						$scope.profile.active = true;
						$scope.profile.firstName = null;
						$scope.profile.lastName = null;
						$scope.profile.emailAddress = null;
						$scope.profile.password = null;
						$scope.profile.password_confirm = null;
						$scope.profile.updatePassword = true;
						updateDisplayName();
						var msg = 'Daten für Benutzer "' +  response.payload.emailAddress + '" wurden gespeichert, ' +
								'bitte geben Sie die Daten des neuen Benutzers an!';
						$scope.addAlert('success', msg, true);
					} else {
						$scope.addAlerts('danger', response.errors);
					}
				});
			};
			$scope.cancel = function() {
				if ($scope.lastSavedProfile) {
					$modalInstance.close($scope.lastSavedProfile);
				} else {
					$modalInstance.dismiss('cancel');
				}
			};

			// Close Modal if User hits Browser Back Button
			$scope.$on('$routeChangeStart', function(next, current) {
				$modalInstance.dismiss('cancel');
			});
		}
	]);

	mod.directive("passwordVerify", function () {
		return {
			require: "ngModel",
			scope: {
				passwordVerify: '='
			},
			link: function (scope, element, attrs, ctrl) {
				scope.$watch(function () {
					var combined;
					if (scope.passwordVerify || ctrl.$viewValue) {
						combined = scope.passwordVerify + '_' + ctrl.$viewValue;
					}
					return combined;
				}, function (value) {
					if (value) {
						ctrl.$parsers.unshift(function (viewValue) {
							var origin = scope.passwordVerify;
							if (origin !== viewValue) {
								ctrl.$setValidity("passwordVerify", false);
								return undefined;
							} else {
								ctrl.$setValidity("passwordVerify", true);
								return viewValue;
							}
						});
					}
				});
			}
		};
	});

	mod.directive('uniqueEmail',['$q','UserService','$timeout',
        function ($q, UserService, $timeout) {
		return {
			require: 'ngModel',
			link: function (scope, elm, attrs, ctrl) {
				ctrl.$asyncValidators.uniqueEmail = function (modelValue, viewValue) {
					var email = modelValue;
					if (ctrl.$isEmpty(email)) {
						return $q.when();
					}
					var def = $q.defer();
					$timeout(function () {
						var userId = scope.profile.id;
						UserService.isUniqueEmail(userId, email).then(function (result) {
							if (result) {
								def.resolve();
							} else {
								def.reject();
							}
						});
					}, 200);
					return def.promise;
				};
			}
		};
	}]);

})();
