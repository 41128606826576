
// ASV Overview
(function(){
	'use strict';

	angular.module('Import', [
		'ui.bootstrap', 'ngFileUpload',
		'LocalConfig', 'RemoteConfig', 'Messages'
	])

	// Init Factory
	.factory('ImportFactory', [function() {
		var dto;
		var occupancyId2RowMap = [];
		var _resetDto = function() {
			dto = {
				uploadCompleted: false,
				validationId: -1,
				organisationId: -1,
				occupancyUploadItemId: -1,
				occupancies: []
			};
			_resetValidationResults();
		};
		var _resetValidationResults = function() {
			dto.validationResults = {
				UPDATE: 0,
				PENDING: 0,
				FATAL: 0,
				ERROR: 0,
				WARNING: 0,
				INFO: 0,
				OK: 0
			};
		};

		_resetDto();

		return {
			reset: function() {
				_resetDto();
			},
			resetValidation: function() {
				_resetValidationResults();
			},
			setUploadCompleted: function(uploadCompleted) {
				dto.uploadCompleted = uploadCompleted;
			},
			isUploadCompleted: function() {
				return dto.uploadCompleted;
			},
			setValidationId: function(validationId) {
				dto.validationId = validationId;
			},
			getValidationId: function() {
				return dto.validationId;
			},
			setOrganisationId: function(organisationId) {
				dto.organisationId = organisationId;
			},
			getOrganisationId: function() {
				return dto.organisationId;
			},
			setValidation: function(validationResults) {
				dto.validationResults = validationResults;
			},
			getValidation: function() {
				return dto.validationResults;
			},
			setOccupancyUploadItemId: function(occupancyUploadItemId) {
				dto.occupancyUploadItemId = occupancyUploadItemId;
			},
			getOccupancyUploadItemId: function() {
				return dto.occupancyUploadItemId;
			},
			setOccupancies: function(occupancies) {
				dto.occupancies = occupancies;
			},
			getOccupancies: function() {
				return dto.occupancies;
			},
			setCommittedOccupancies: function(committedOccupancies) {
				dto.committedOccupancies = committedOccupancies;
			},
			getCommittedOccupancies: function() {
				return dto.committedOccupancies;
			}
		};
	}])

	// Init Controller
	.controller('ImportStep1Controller', [
	'$scope', '$routeParams', '$location', 'Upload',
	'UrlConfigFactory', 'MessageFactory', 'ImportFactory',
	function($scope, $routeParams, $location, Upload,
			UrlConfigFactory, MessageFactory, ImportFactory) {
		ImportFactory.reset();
		ImportFactory.setOrganisationId($routeParams.organisationId);
		$scope.uploadCompleted = ImportFactory.isUploadCompleted();
		$scope.messagesToImport = 0;
		$scope.totalCount = 0;
		$scope.importedDraftsCount = 0;
		$scope.updatedDraftsCount = 0;
		$scope.ignoredRowsCount = 0;
		$scope.valid = false;
		$scope.messages = [];
		$scope.occupancyId2RowMap = ImportFactory.occupancyId2RowMap;
		$scope.filterQuery = {
			error: {
				severity: 'ERROR'
			},
			warning: {
				severity: 'WARNING'
			},
			info: {
				severity: 'INFO'
			}
		};

		$scope.$watch('files', function () {
			$scope.upload($scope.files);
		});

		$scope.upload = function (files) {
			if (files && files.length) {
				for (var i = 0; i < files.length; i++) {
					var file = files[i];
					Upload.upload({
						url: UrlConfigFactory.getExcelImportFileUploadUrl(),
						params: {orgUnitId: ImportFactory.getOrganisationId()},
						file: file
					}).progress(function (evt) {
						$scope.uploadCompleted = false;
						$scope.valid = false;
						var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
					}).then(function (data, status, headers, config) {
						// WASV-4: "real" JSON-result seems to be wrapped as "data"-property, unclear where this happens
						if (data.data) {
							data = data.data;
						}
						$scope.messages = data.messages;
						$scope.uploadCompleted = true;
						$scope.totalCount = data.totalCount;
						$scope.importedDraftsCount = data.importedDraftsCount;
						$scope.updatedDraftsCount = data.updatedDraftsCount;
						$scope.headRowCount = data.headRowCount;
						$scope.ignoredRowsCount = data.ignoredRowsCount;
						ImportFactory.occupancyId2RowMap = data.occupancyId2RowMap;
						//if you want to use occupancyId2RowMap inside step 1, comment this in
						ImportFactory.setUploadCompleted($scope.uploadCompleted);
						if (data.validationOk) {
							$scope.valid = true;
							ImportFactory.setValidationId(data.validationId);
							ImportFactory.setOccupancies(data.occupancies);
							ImportFactory.setOccupancyUploadItemId(data.occupancyUploadItemId);
						}
					});
				}
			}
		};

		$scope.validate = function() {
			if (($scope.files !== null)) {
				return true;
			}
			MessageFactory.error("Bitte laden Sie mindestens eine Excel Datei hoch...");
			return false;
		};

		$scope.previous = function() {
			$location.path("/overview/" + ImportFactory.getOrganisationId());
		};

		$scope.next = function() {
			if ($scope.validate()) {
				$location.path("/excel/import/step2");
			}
		};
	}])

	// Init Controller
	.controller('ImportStep2Controller', [
		'$scope', '$timeout', '$location', '$log',
		'SERVER_CONFIG', 'UrlConfigFactory', 'MessageFactory', 'ImportFactory',
		function($scope, $timeout, $location, $log,
				SERVER_CONFIG, UrlConfigFactory, MessageFactory, ImportFactory) {
			$scope.validationId = ImportFactory.getValidationId();
			$scope.validationResultCount = ImportFactory.getValidation();
			$scope.validation = {
				allCompleted: false
			};
			$scope.occupancyId2RowMap = ImportFactory.occupancyId2RowMap;

			$scope.checkValidationProgress = function() {
				if ($scope.validationId < 0) {
					MessageFactory.error("Leider ist ein Fehler beim Hochladen Ihrer Daten aufgetreten, bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.");
				} else {
					var resource = UrlConfigFactory.getExcelImportValidationCheckResource();
					resource.get({'id' : $scope.validationId}, function(data) {
						if (!data.ok) {
							$scope.step1();
							MessageFactory.error("Es ist ein Problem bei der Validierung aufgetreten, bitte starten Sie den Prozess erneut.");
						} else {
							$scope.validation = data.payload;
							$log.debug($scope.validation);
							$scope.updateValidation();
							if (!$scope.validation.allCompleted) {
								// poll
								$timeout(function() {
									$scope.checkValidationProgress();
								}, 2000);
							}
						}
					});
				}
			};

			$scope.updateValidation = function() {
				ImportFactory.resetValidation();
				$scope.validationResultCount = ImportFactory.getValidation();
				for (var i = 0; i < $scope.validation.validationResultEntries.length; i++) {
					var entry = $scope.validation.validationResultEntries[i];
					$scope.validationResultCount.UPDATE += 1;

					if (entry.status !== "COMPLETED") {
						$scope.validationResultCount.PENDING += 1;
					}
					if (entry.violations === null || entry.violations.length === 0) {
						$scope.validationResultCount.OK += 1;
					} else if (entry.maxSeverity === "FATAL") {
						$scope.validationResultCount.FATAL += 1;
					} else if (entry.maxSeverity === "ERROR") {
						$scope.validationResultCount.ERROR += 1;
					} else if (entry.maxSeverity === "WARNING") {
						$scope.validationResultCount.WARNING += 1;
					} else if (entry.maxSeverity === "INFO") {
						$scope.validationResultCount.INFO += 1;
					}
					ImportFactory.setValidation($scope.validationResultCount);
				}
			};

			$scope.validate = function() {
				if ($scope.validation.allCompleted) {
					return $scope.validationResultCount.FATAL === 0;
				} else {
					$scope.checkValidationProgress();
					return false;
				}
			};

			$scope.previous = function() {
				$location.path("/excel/import/step1/" + ImportFactory.getOrganisationId());
				//reset map
				ImportFactory.occupancyId2RowMap = [];
			};

			$scope.next = function() {
				if ($scope.validate()) {
					var resource = UrlConfigFactory.getExcelImportCommittingResource();

					resource.save({
							'orgUnitId' : ImportFactory.getOrganisationId(),
							'occupancyUploadItemId' : ImportFactory.getOccupancyUploadItemId(),
							'occupancies' : ImportFactory.getOccupancies()
						}, function(data) {
						if (!data.ok) {
							$scope.step1();
							MessageFactory.error("Es ist ein Problem beim Einmelden aufgetreten, bitte starten Sie den Prozess erneut.");
							MessageFactory.warning(data.errors);
						} else {
							if (data.errors !== null && data.errors.length > 0) {
								MessageFactory.info(data.errors);
							}
							ImportFactory.setCommittedOccupancies(data.payload);
							$location.path("/excel/import/step3");
							//reset map
							ImportFactory.occupancyId2RowMap = [];
						}
					});
				}
			};

			$scope.getViolationDisplayVars = function(violation) {
	            var dispVars = {
		            severityIcon : '',
		            severityClass : ''
	            };

                if (violation) {

                    if (SERVER_CONFIG.violationCodes[violation.severity] === "FATAL") {
		                dispVars.severityIcon = 'fa-minus-circle';
		                dispVars.severityClass = 'list-group-item-danger';
		            }
		            else if (SERVER_CONFIG.violationCodes[violation.severity] === "ERROR") {
		                dispVars.severityIcon = 'fa-times-circle';
		                dispVars.severityClass = 'list-group-item-danger';
		            }
		            else if (SERVER_CONFIG.violationCodes[violation.severity] === "WARNING") {
		                dispVars.severityIcon = 'fa-exclamation-circle';
		                dispVars.severityClass = 'list-group-item-warning';
		            }
		            else if (SERVER_CONFIG.violationCodes[violation.severity] === "INFO") {
		                dispVars.severityIcon = 'fa-info-circle';
		                dispVars.severityClass = 'list-group-item-info';
		            }

                }
                return dispVars;
            };

			$scope.validate();
	}])

	// Init Controller
	.controller('ImportStep3Controller', [
	'$scope', '$location',
	'UrlConfigFactory', 'MessageFactory', 'ImportFactory',
	function($scope, $location,
			UrlConfigFactory, MessageFactory, ImportFactory) {

		$scope.validationId = ImportFactory.getValidationId();
		$scope.committed = ImportFactory.getCommittedOccupancies();

		$scope.next = function() {
			$location.path("/overview/" + ImportFactory.getOrganisationId());
		};

	}])

	;

})();
