(function(){
	'use strict';

	angular.module('EventConfig',[

	])

	.constant('GLOBAL_EVENTS', [
		'OverviewListLoaded',
		/*  Overview Listen geladen + initialisiert
			Values:
				data {
					originalList, 	--> Liste ohne Drafts / aktueller Meldungsstand
					editList		--> Liste mit Drafts / aktueller Bearbeitungsstand
				}
		*/

		'OverviewEditModeChanged',
		/*  Overview EditMode de/aktiviert
			Values:
				editMode -> true, false
		*/

		'OverviewDateChanged',
		/*  Overview Date geändert
			Values:
				date -> neues Datum
		*/

		'OverviewFilterSettingsChanged',
		/*  Overview Filter Settings Changed
			Values:
				FilterSettings -> Liste mit Settings
		*/

		'OverviewUpdateToolbarCount',
		/*  Update Overview Toolbar Draft Counts
			Values:
				countData -> Liste mit Count-Differenz für "NEW" + "UPDATE"
		*/
		'RemoteConfigLoaded'
		/*  Indikator dass die Konfiguration vom Server geladen wurde und nun abrufbar ist
			Values:
				none
		*/

	])
	;

})();

