(function () {
    'use strict';

    angular.module('OverviewData', [
        'ngResource',
        'LocalConfig', 'RemoteConfig', 'FilterFunctions', 'Analytics'
    ])

        .factory('OverviewDataFactory', [
            '$rootScope', '$log', '$resource',
            'SERVER_CONFIG', 'APP_CONFIG', 'UrlConfigFactory', 'ServerRemoteConfigFactory', '$timeout', '$filter', '$q', 'AnalyticsFactory',
            function ($rootScope, $log, $resource, SERVER_CONFIG, APP_CONFIG, UrlConfigFactory, ServerRemoteConfigFactory, $timeout, $filter, $q, AnalyticsFactory) {

                // Init Vars
                var dataFactory = {};

                // Init List Vars
                var editMode = false;
                var validationId = 0;
                var pollingTurnedOn = false;
                var pollingForced = false;
                var pollingTimerPromise = null;
                var pollingRequesterPromise = null;
                var orgUnits = null;
                var validationResult = null;
                var validationsByOccupancyId = {};

                // Applikation Data
                var serverRemoteConfig = ServerRemoteConfigFactory;

                // Get Resource for List
                var serverListResource = UrlConfigFactory.getOverviewListResource();

                var editModeResource = UrlConfigFactory.getEditModeResource();

                var pollingResource = UrlConfigFactory.getPollingResource();

                var overviewDateResource = UrlConfigFactory.getOverviewDateResource();

                //Load List from Server
                dataFactory.initialize = function (organisationId, initialDate, initialEditMode, callback) {
                    editMode = initialEditMode;

                    var params = {
                        organisationId: organisationId,
                        date: $filter('date')(initialDate, APP_CONFIG.serverDateFormat),
                        editMode: initialEditMode
                    };

                    //$log.debug(params);

                    serverListResource.get(params, function (result) {
                        validationId = result.validationId;

                        // Update orgUnits
                        if (angular.isObject(result.orgUnits)) {
                            orgUnits = dataFactory.buildOrganisations(true, result.orgUnits, result.occupancyUpdates);
                            $rootScope.$broadcast('OverviewOrgUnitsUpdate', orgUnits);
                        }

                        if (callback) {
                            callback();
                        }
                    });
                };

                dataFactory.startPolling = function () {
                    if (pollingRequesterPromise) {
                        $log.warn("startPolling called while pollingRequesterPromise is not null");
                        return;
                    }
                    if (pollingTimerPromise) {
                        $log.warn("startPolling called while pollingTimerPromise is not null");
                        $timeout.cancel(pollingTimerPromise);
                        pollingTimerPromise = null;
                    }
                    $log.debug("startPolling:    pollingTimerPromise=" + pollingTimerPromise);
                    if (!pollingTurnedOn) {
                        pollingTurnedOn = true;
                        pollingForced = false;
                        dataFactory.schedulePolling();
                    }
                };

                dataFactory.stopPolling = function () {
                    if (pollingTurnedOn) {
                        if (pollingTimerPromise) {
                            $timeout.cancel(pollingTimerPromise);
                        }
                        pollingTimerPromise = null;
                        pollingTurnedOn = false;
                        pollingForced = false;
                    }
                };

                dataFactory.forcePolling = function () {
                    if (pollingRequesterPromise) {
                        pollingForced = true;
                        return;
                    }
                    $log.debug("forcePolling:    pollingTimerPromise=" + pollingTimerPromise);
                    if (pollingTimerPromise) {
                        $timeout.cancel(pollingTimerPromise);
                        pollingTimerPromise = null;
                        $timeout(function () {
                            dataFactory.schedulePolling();
                        });
                    }
                };

                dataFactory.schedulePolling = function () {
                    dataFactory.poll(function (response) {
                        if (!pollingTurnedOn) {
                            $log.debug("stopping polling");
                            return;
                        }
                        if (pollingForced) {
                            pollingForced = false;
                            dataFactory.schedulePolling();
                        } else {
                            pollingTimerPromise = $timeout(function () {
                                pollingTimerPromise = null;
//                            $log.debug("polling now ...");
                                dataFactory.schedulePolling();
                            }, 3000);
                        }
                    });
                };

                dataFactory.toggleEditMode = function (callback) {
                    editModeResource.save({validationId: validationId, editMode: !editMode}, function (response) {
                        if (response.ok && callback) {
                            editMode = response.payload;
                            callback(editMode);
                        }
                    });
                };

                dataFactory.poll = function (callback) {
                    if (pollingRequesterPromise) {
                        $log.warn("poll called while pollingRequesterPromise is not NULL");
                        return;
                    }
                    pollingForced = false;
                    if (pollingTimerPromise) {
                        $timeout.cancel(pollingTimerPromise);
                        pollingTimerPromise = null;
                    }
                    pollingRequesterPromise = pollingResource.get({validationId: validationId}, function (response) {
                        // Update validationResult
                        pollingRequesterPromise = null;
                        if (angular.isObject(response.occupancyUpdates)) {
                            $log.debug("poll() - broadcast OccupancyUpdates");
                            $rootScope.$broadcast('OccupancyUpdates', response.occupancyUpdates);
                        }
                        if (angular.isObject(response.validationResult)) {
                            validationResult = response.validationResult;
                            var arrayLength = validationResult.validationResultEntries.length;
                            for (var i = 0; i < arrayLength; i++) {
                                var validationResultEntry = validationResult.validationResultEntries[i];
                                validationsByOccupancyId[validationResultEntry.occupancy] = validationResultEntry;
                            }
                            $rootScope.$broadcast('ValidationUpdates', validationsByOccupancyId);
                        }
                        if (callback) {
                            callback(response);
                        }
                    });
                };

                dataFactory.getValidationByOccupancyId = function (occupancyId) {
                    return validationsByOccupancyId[occupancyId];
                };

                dataFactory.getValidationResult = function () {
                    return validationResult;
                };

                dataFactory.resetValidationResult = function () {
                    validationResult = null;
                    validationsByOccupancyId = {};

                    $rootScope.$broadcast('ValidationUpdates', validationsByOccupancyId);
                };

                //Update List from Server
                dataFactory.updateList = function () {
                    if (angular.isObject(orgUnits)) {
                        orgUnits = dataFactory.buildOrganisations(false, orgUnits, null);

                        $rootScope.$broadcast('OverviewOrgUnitsUpdate', orgUnits);
                    }
                };

                dataFactory.getAllDraftOccupancies = function () {
                    var result = {};
                    var draftState = SERVER_CONFIG.occupancyStateCodes["DRAFT"];
                    if (angular.isObject(orgUnits)) {
                        angular.forEach(orgUnits, function (orgUnit, orgUnitKey) {
                            angular.forEach(orgUnit.sections, function (section, sectionKey) {
                                angular.forEach(section.trainingPosts, function (trainingPost, trainingPostKey) {
                                    angular.forEach(trainingPost.occupancies, function (occupancy, occupancyKey) {
                                        if (occupancy.status === draftState) {
                                            result[occupancy.id] = occupancy;
                                        }
                                    });
                                });
                            });

                            // Special Case for Trainingposts without Section
                            if (orgUnit.trainingPosts.length > 0) {
                                angular.forEach(orgUnit.trainingPosts, function (trainingPost, trainingPostKey) {
                                    angular.forEach(trainingPost.occupancies, function (occupancy, occupancyKey) {
                                        if (occupancy.status === draftState) {
                                            result[occupancy.id] = occupancy;
                                        }
                                    });
                                });
                            }
                        });
                    }
                    return result;
                };


                dataFactory.buildOrganisations = function (firstBuild, organisations, occupancyUpdates) {
                    // Parsing through ServerList
                    angular.forEach(organisations, function (organisation, organisationKey) {
                        angular.forEach(organisation.sections, function (section, sectionKey) {
                            section.firstBuild = firstBuild;
                            organisations[organisationKey].sections[sectionKey] = dataFactory.buildTrainingposts(firstBuild, section, occupancyUpdates);
                        });

                        // Special Case for Trainingposts without Section
                        if (organisation.trainingPosts.length > 0) {
                            organisations[organisationKey] = dataFactory.buildTrainingposts(firstBuild, organisation, occupancyUpdates);
                        }
                    });

                    return organisations;
                };

                // Get Edit List
                dataFactory.buildTrainingposts = function (firstBuild, section, occupancyUpdates) {
                    var iteratorContext = {
                        lastTrainingPost: null,
                        vacantPositionsOnThisRootOakKey: 0
                    };
                    angular.forEach(section.trainingPosts, function (trainingPost, trainingPostKey) {
                        var trainingPostType = serverRemoteConfig.getTrainingPostTypeById(trainingPost.trainingPostType);
                        var isPlusable = trainingPostType.plusability === 'PLUSABLE';
                        var isSameRootOakKey = this.lastTrainingPost && this.lastTrainingPost.rootOakKey === trainingPost.rootOakKey;
                        var isVacant = !trainingPost.occupancies || trainingPost.occupancies.length === 0;

                        if (isPlusable && isSameRootOakKey) {
                            trainingPost.plussing = false;
                            trainingPost.plusable = true;
                            trainingPost.lastPlusableInGroup = false;
                            trainingPost.actualPlusability = "";
                            if (isVacant) {
                                this.vacantPositionsOnThisRootOakKey++;
                            }
                        } else if (isPlusable && !isSameRootOakKey) {
                            trainingPost.plussing = false;
                            trainingPost.plusable = true;
                            trainingPost.firstPlusableInGroup = true;
                            trainingPost.lastPlusableInGroup = false;
                            trainingPost.vacantPositions = 0;
                            trainingPost.actualPlusability = "";
                            if (this.lastTrainingPost && this.lastTrainingPost.plusable) {
                                this.lastTrainingPost.lastPlusableInGroup = true;
                                if (this.vacantPositionsOnThisRootOakKey <= 5) {
                                    this.lastTrainingPost.actualPlusability = "canPlus";
                                } else {
                                    this.lastTrainingPost.actualPlusability = "disabledPlus";
                                }
                            }
                            this.vacantPositionsOnThisRootOakKey = isVacant ? 1 : 0;
                        } else if (!isPlusable) {
                            if (this.lastTrainingPost && this.lastTrainingPost.plusable) {
                                this.lastTrainingPost.lastPlusableInGroup = true;
                                if (this.vacantPositionsOnThisRootOakKey <= 5) {
                                    this.lastTrainingPost.actualPlusability = "canPlus";
                                } else {
                                    this.lastTrainingPost.actualPlusability = "disabledPlus";
                                }
                            }
                            this.vacantPositionsOnThisRootOakKey = isVacant ? 1 : 0;
                        }
                        this.lastTrainingPost = trainingPost;
                        section.trainingPosts[trainingPostKey] = dataFactory.buildTrainingPost(firstBuild, trainingPost, occupancyUpdates);
                    }, iteratorContext);

                    if (iteratorContext.lastTrainingPost && iteratorContext.lastTrainingPost.plusable) {
                        iteratorContext.lastTrainingPost.lastPlusableInGroup = true;
                        if (iteratorContext.vacantPositionsOnThisRootOakKey <= 5) {
                            iteratorContext.lastTrainingPost.actualPlusability = "canPlus";
                        } else {
                            iteratorContext.lastTrainingPost.actualPlusability = "disabledPlus";
                        }
                    }

                    return section;
                };

                // Calculate TrainingPost Values
                dataFactory.buildTrainingPost = function (firstBuild, trainingPost, occupancyUpdates) {
                    trainingPost.firstBuild = firstBuild;
                    trainingPost.occupancyUpdates = null;

                    if (angular.isObject(occupancyUpdates) && angular.isDefined(occupancyUpdates[trainingPost.id])) {
                        trainingPost.occupancyUpdates = occupancyUpdates[trainingPost.id];
                    }

                    // If this is initial Loading set additional Values
                    if (firstBuild) {
                        var subj = serverRemoteConfig.getSubjectById(trainingPost.subject);
                        var trainingPostType = serverRemoteConfig.getTrainingPostTypeById(trainingPost.trainingPostType);

                        var shortLabel = '';
                        if (subj && subj.shortLabel && subj.shortLabel != '') {
                            shortLabel = ' - ' + subj.shortLabel;
                        }
                        trainingPost.displayName = trainingPostType.shortLabel + shortLabel + ' - ' + trainingPost.oakKey
                            + (trainingPost.plusable && trainingPost.oakKey === trainingPost.rootOakKey ? "-000" : "");
                    }

                    return trainingPost;
                };

                dataFactory.plusTrainingPost = function (trainingPost) {
                    var trainingPostPlusPromise = UrlConfigFactory.getTrainingPostPlusResource().save({id: trainingPost.id});
                    return trainingPostPlusPromise;
                };

                // Add a new Occupancy to List
                dataFactory.updateOccupancy = function (occupancyData) {

                    var occupancyUpdatePromise = UrlConfigFactory.getOccupancyUpdateResource().save(occupancyData);

                    return occupancyUpdatePromise;
                };

                // Add a new Occupancy to List
                dataFactory.newOccupancy = function (organisationId, sectionId, trainingPostId, occupancyData) {

                    occupancyData.trainingPost = trainingPostId;
                    if (sectionId !== 'NONE') {
                        occupancyData.orgUnit = sectionId;
                    } else {
                        occupancyData.orgUnit = organisationId;
                    }

                    var saveData = UrlConfigFactory.getOccupancyCreateResource().save(occupancyData);
                    //do not track here anymore, but in TrainingsPosts.js, because there the result from server is handled and therefore there already exists an id
//		AnalyticsFactory.trackOccupancyCreation(occupancyData.orgUnit, saveData);

                    return saveData;
                };

                // Delete Occupancies from the List
                dataFactory.deleteOccupancy = function (trainingPost, organisation, occupancyId) {
                    // Get Resource for deleting/withdrawing
                    var occupancyResource = UrlConfigFactory.getOccupanciesDeleteResource();

                    //POST in serverless mode will result in Error: "405 Method Not Allowed"
                    var params = {'occupancyId': occupancyId, 'validationId': validationId};
                    var deletePromise = occupancyResource.delete(params);
                    AnalyticsFactory.trackOccupancyDeletion(occupancyId, trainingPost, organisation, true);
                    return deletePromise;
                };

                // Delete Occupancies from the List
                dataFactory.revokeOccupancy = function (trainingPost, organisation, occupancyId) {
                    // Get Resource for deleting/withdrawing
                    var occupancyResource = UrlConfigFactory.getOccupancyRevokeResource();

                    //POST in serverless mode will result in Error: "405 Method Not Allowed"
                    var revokePromise = occupancyResource.save({'occupancyId': occupancyId});
                    AnalyticsFactory.trackOccupancyDeletion(occupancyId, trainingPost, organisation, false);
                    return revokePromise;
                };

                dataFactory.getOccupancyState = function (occupancy) {
                    // TODO MM / CS korrigieren auf den echten wert / überarbeiten
                    return SERVER_CONFIG.violationCodes["INFO"];
                };

                dataFactory.getOccupancyEditStatus = function (occupancy, editMode) {
                    return (SERVER_CONFIG.occupancyStateCodes[occupancy.status] !== "DELETE"
                        && (occupancy.revocation !== true || SERVER_CONFIG.occupancyStateCodes[occupancy.status] !== "DRAFT")
                        && editMode);
                };

                dataFactory.getAllocationDiff = function (occupancy, currentDate) {
                    if (occupancy.revocation === true) {
                        return 0;
                    }
                    if (occupancy.from > currentDate) {
                        return 0;
                    }
                    if (occupancy.from < currentDate && occupancy.to && occupancy.to < currentDate) {
                        return 0;
                    }
                    return occupancy.allocation;

                };

                dataFactory.setOverviewDate = function (date) {
                    var dateFormatted = $filter('date')(date, APP_CONFIG.serverDateFormat);
                    overviewDateResource.save({'date': dateFormatted, 'validationId': validationId});
                };

                // Return Service
                return dataFactory;
            }
        ])

    ;

})();
