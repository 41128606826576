const ngClickOutside = require('@iamadamjowett/angular-click-outside');

/**
 * Created by mhager on 24.04.15.
 */
(function(){
    'use strict';

    angular.module('asv.Progressbar', [
        'angular-click-outside',
        'Allocation',
        'LocalConfig',
        'OverviewUserData',
        'OverviewData',
        'RemoteConfig'
    ])

    .directive('asvProgressBar', ['$filter', 'APP_CONFIG', 'AllocationFactory', 'OverviewUserDataFactory',
	function($filter, APP_CONFIG, AllocationFactory, OverviewUserDataFactory) {
        return {
			restrict: 'E',
			template: '<div class="progress"><div class="progress-bar {{errorClass}}" role="progressbar" '
				+ 'aria-valuenow="{{percentageText}}" aria-valuemin="0" aria-valuemax="100" ' +
				'ng-style="{ width: percentage + \'%\'}">{{percentageText}}%</div></div>',
			link: function(scope, element, attrs, ctrl) {
				scope.percentageRounded = 0;
				scope.percentage = 0;
			},
			controller: ['$scope', function($scope) {
				$scope.percentageRounded = 0;
				$scope.percentage = 0;

				var getAllocation = function(date) {
					var minutesSum = AllocationFactory.getOccupanciesSum($scope.trainingPost.occupancies, date);
					$scope.percentageText = AllocationFactory.minutesToPercent(minutesSum, $scope.trainingPost.maxAllocation);
					$scope.percentage = parseFloat($scope.percentageText.replace(",", "."));

					if (minutesSum > $scope.trainingPost.maxAllocation) {
						$scope.errorClass = 'progress-bar-danger';
					} else {
						$scope.errorClass = '';
					}
				};

				$scope.$on('OverviewDateChanged', function (event, newDate) {
					getAllocation(newDate);
				});

				$scope.$watchCollection('trainingPost.occupancies', function(oldValue, newValue) {
					getAllocation(OverviewUserDataFactory.getDate());
				});
			}]
		};
    }])

    .directive('asvAllocationProgressBar', ['$compile', '$filter',
    	'OverviewDataFactory',
    	function($compile, $filter, dataFactory) {
	        function link(scope, element, attrs) {
				function editingTemplate( occupancy ) {
					return "<form class='form-inline' role='form' id='allocation-" + occupancy.id + "-to' name='allocation" + occupancy.id + "'>" +
					    "<div class='form-group' ng-class=\"{'has-error' : (allocation" + occupancy.id + ".allocation.$dirty && isNonEmptyObject(allocation" + occupancy.id + ".allocation.$error))}\">" +
					    "<div class='input-group' click-outside='cancelEditing()' id='allocation-cloutside-" + occupancy.id + "'>" +
					    "<input type='text' class='form-control input-sm' ng-model='value' name='allocation' allocation-input max-allocation='" + occupancy.maxAllocation + "' on-keydown='keyUp(event, allocation" + occupancy.id + ".allocation.$error)' on-ok='submit(callbackValue, allocation" + occupancy.id + ".allocation.$error)' on-cancel='cancelEditing()' placeholder='hh:mm'>" +
					    "</div>" +
					    "</div>" +
					    "</form>";
				}

				function nonEditingTemplate( occupancy, editMode) {
		       		var timeAndPercent = $filter('allocationTimeAndPercent')(occupancy.allocation, occupancy.maxAllocation);
		       		return dataFactory.getOccupancyEditStatus(occupancy, editMode) ? '<a ng-click="startEditing($event)" href class="inline-edit-link">' + timeAndPercent + '</a>' : timeAndPercent;
				}

				function render( ) {
					if ( scope.editing ) {
					    element.html( editingTemplate(scope.occupancy) );
					    $compile( element.contents() )(scope);
					} else {
					    element.html( nonEditingTemplate(scope.occupancy, scope.editMode) );
					    $compile( element.contents() )(scope);
					}
				}

				scope.occupancy = scope.$eval(attrs.occupancy);
				scope.trainingPost = scope.$eval(attrs.trainingPost);

				scope.editMode = scope.$eval(attrs.editMode);
				scope.editing = false;
				scope.value = scope.occupancy.allocation;
				scope.render = render;

				render();
	        }

	        return {
				restrict: 'E',
				link: link,
				scope: {
					occupancy: '=',
					trainingPost: '=',
					editMode: '='
				},
				controller: ['$scope', function( $scope ) {

					$scope.startEditing = function($event) {
					    $event.preventDefault();
					    $event.stopPropagation();
					    $scope.editing = !$scope.editing;
					    $scope.value = $scope.occupancy.allocation;
					    $scope.render();
					};

					$scope.keyUp = function (event, formErrors) {
					    // When ESC is pressed
					    if (event.keyCode === 27) {
					    	// cancel
					    	$scope.cancelEditing();
					    }
					    // When ENTER is pressed
					    else if (event.keyCode === 13) {
					    	$scope.submit(null, formErrors);
					    }
					};

					$scope.submit = function (callbackValue, formErrors) {
						if (!$scope.isNonEmptyObject(formErrors)) {
							if (callbackValue) {
								$scope.value = callbackValue;
							}
						    if ($scope.value !== $scope.occupancy.allocation) {
								var updatedOccupany = {
									id:         	$scope.occupancy.id,
									allocation: 	$scope.value,
									maxAllocation:	$scope.trainingPost.maxAllocation, // update with trainingpost to make sue maxAllocation is always up to date
									comment:    	$scope.occupancy.comment,
									from:       	$scope.occupancy.from,
									to:         	$scope.occupancy.to,
									revocation: 	$scope.occupancy.revocation,
									trainee:    	$scope.occupancy.trainee,
									status:     	$scope.occupancy.status
								};

								var occupancyUpdatePromise = dataFactory.updateOccupancy(updatedOccupany);

								// If Server Response then update List
								occupancyUpdatePromise.$promise.then(function (result) {
									if (result.ok) {
										$scope.occupancy.allocation = $scope.value;
									    dataFactory.forcePolling();
									}
									$scope.editing = false;
									$scope.render();
								});
						    }
						    else {
							// cancel
						    }
						}
					};

					$scope.$on('OverviewEditModeChanged', function (event,data) {
					    $scope.editMode = data;
					    $scope.render();
					});

					$scope.$on('OccupancyUpdates', function (event,data) {
					    if ( data[ $scope.trainingPost.id ]  ) {
						angular.forEach(data[$scope.trainingPost.id], function (occ) {
							if (occ.occupancy.id === $scope.occupancy.id) {
							    $scope.occupancy = occ.occupancy;
							    $scope.render();
							}
						});
					    }
					});

					$scope.cancelEditing = function() {
					    $scope.editing = false;
					    $scope.value = $scope.occupancy.allocation;
					    $scope.render();
					};

					$scope.isNonEmptyObject = function( o ) {
						return Object.keys(o).length > 0;
					};
				}]
	        };

    	}
	])

    .directive( 'asvOccupancyDateTo',
        ['APP_CONFIG','SERVER_CONFIG', '$log', '$filter','$compile','OverviewDataFactory',
        function ( APP_CONFIG, SERVER_CONFIG, $log, $filter, $compile, dataFactory ) {

			function editingTemplate( occupancy ) {
				return "<form class='form-inline' role='form' id='" + occupancy.id + "-to' name='to" + occupancy.id + "'>" +
					"<div class='form-group' ng-class=\"{'has-error' : (to" + occupancy.id + ".dateTo.$dirty && isNonEmptyObject(to" + occupancy.id + ".dateTo.$error))}\">" +
					"<div class='input-group' click-outside='cancelEditing()' id='cloutside-" + occupancy.id + "'>" +
					"<input type='text' class='form-control input-sm' ng-model='value' name='dateTo' is-open='$parent.datePickerOpen' ng-keyup='keyUp($event, to" + occupancy.id + ".dateTo.$error)'  ng-change='selectDate()' min-date='occupancy.from' datepicker-popup asvdate>" +
					"<div class='input-group-btn'>" +
					"<button type='button' class='btn btn-default btn-sm' ng-click='openDatepicker($event)'><i class='fa fa-fw fa-calendar'></i></button>" +
					"</div>" +
					"</div>" +
					"</div>" +
					"</form>";
			}

			function nonEditingTemplate( occupancy,editMode ) {
				return dataFactory.getOccupancyEditStatus(occupancy, editMode) ? "<a class='inline-edit-link' href ng-click='startEditing($event)'>" + (occupancy.to ? $filter('date')(occupancy.to,APP_CONFIG.dateFormat):"offen") + "</a>" : (occupancy.to ? $filter('date')(occupancy.to,APP_CONFIG.dateFormat):"offen");
			}

			function link(scope, element, attrs) {

				function render() {
				    if ( scope.editing ) {
						element.html( editingTemplate(scope.occupancy) );
						$compile( element.contents() )(scope);
				    } else {
						element.html( nonEditingTemplate( scope.occupancy,scope.editMode) );
						$compile( element.contents() )(scope);
				    }
				}

				scope.occupancy = scope.$eval(attrs.occupancy);
				scope.value = scope.occupancy.to;
				scope.trainingPost = scope.$eval(attrs.trainingPost);
				scope.editMode = scope.$eval(attrs.editMode);
				scope.editing = false;
				scope.render = render;

				render();
			}

			return {
				restrict: 'E',
				link: link,
				controller: ['$scope', function( $scope ) {
				    $scope.startEditing = function($event) {
						$event.preventDefault();
						$event.stopPropagation();
						$scope.editing = !$scope.editing;
						$scope.render();
				    };

				    $scope.cancelEditing = function() {
						$scope.value = $scope.occupancy.to;
						$scope.editing = false;
						$scope.render();
				    };

				    $scope.$on('OverviewEditModeChanged', function (event,data) {
						$scope.editMode = data;
						$scope.render();
				    });

				    $scope.selectDate = function() {

				    };

					$scope.isNonEmptyObject = function( o ) {
						return Object.keys(o).length > 0;
					};

				    $scope.$on('OccupancyUpdates', function (event,data) {
						if ( data[ $scope.trainingPost.id ]  ) {
							angular.forEach(data[$scope.trainingPost.id], function (occ) {
							    if (occ.occupancy.id === $scope.occupancy.id) {
								$scope.occupancy = occ.occupancy;
								$scope.render();
							    }
							});
						}
				    });

				    $scope.openDatepicker = function($event) {
					$event.preventDefault();
					$event.stopPropagation();
					$scope.$parent.datePickerOpen = true;
					$scope.render();
				    };

				    $scope.keyUp  = function ($event, formErrors) {
						// When ESC is pressed
						if ($event.keyCode === 27) {
							// cancel
							$scope.cancelEditing();
						}
						// When ENTER is pressed
						else if ($event.keyCode === 13) {
							$scope.submit(formErrors);
						}
				    };

				    $scope.submit = function (formErrors) {
						var evalValue = $scope.validate($scope.value);

						if (evalValue !== $scope.occupancy.to && !$scope.isNonEmptyObject(formErrors)) {
							var updatedOccupany = angular.copy($scope.occupancy);

							updatedOccupany.to = evalValue;

							var occupancyUpdatePromise = dataFactory.updateOccupancy(updatedOccupany);

							// If Server Response then update List
							occupancyUpdatePromise.$promise.then(function (result) {
							    if (result.ok) {
								dataFactory.forcePolling();
							    }
							    $scope.editing = false;
							    $scope.render();
							});
						}
						else {
							// cancel
						}
				    };

				    $scope.validate = function (value) {
						if (value === null) {
							return value;
						}
						return $filter('date')(new Date(value),APP_CONFIG.serverDateFormat);
				    };
				}]
			};
        }])


    .directive( 'asvOccupancyComment',
        ['APP_CONFIG','SERVER_CONFIG','$filter','$compile','OverviewDataFactory', 'ServerRemoteConfigFactory',
		function ( APP_CONFIG, SERVER_CONFIG, $filter, $compile, dataFactory, ServerRemoteConfigFactory ) {

			function editModeTemplate(occupancy) {
			    var str = ''
					+ '<span class="occupancy-comment-icon">'
					+   '<a href class="btn btn-link" ng-click="toggleTooltip($event)">'
					+     '<i class="fa fa-fw fa-lg ' + (occupancy.comment ? 'fa-comment':'fa-comment-o') + '"></i>'
					+   '</a>'
					+ '<div class="tooltip top-right" role="tooltip" ng-if="tooltipVisible">'
					+   '<div class="tooltip-arrow"></div>'
					+   '<div class="tooltip-inner">'
					+     '<div>'
					+       '<div class="inline-edit">'
					+         '<a href ng-if="!editing" ng-click="startEditing($event)" class="inline-edit-link fa' + (occupancy.comment ? 'fa-comment':'fa-comment-o') + '">{{ occupancy.comment || "Kein Kommentar" }}</a>'
					+         '<a href ng-if="!editing && !!occupancy.comment" ng-click="deleteComment($event)" class="inline-delete-link"><i class="fa fa-trash" class="inline-delete-link"></i></a>'
					+         '<form ng-if="editing" class="form-inline" role="form" click-outside="cancelEditing()" id="' + occupancy.id + '-comment">'
					+           '<div class="form-group">'
					+		 '<textarea class="form-control input-sm" ng-model="comment" rows="4" cols="30" maxlength="{{maxLengthComment}}" placeholder="Kommentar eingeben">{{comment}}</textarea>'
					+           '</div>'
					+           '<div class="form-group btn-row">'
					+		 '<div class="btn-group pull-left" dropdown>'
					+		   '<a href class="dropdown-toggle btn btn-sm btn-default" dropdown-toggle>'
					+			 'Vorlagen <span class="caret"></span>'
					+		   '</a>'
					+		   '<ul class="dropdown-menu">'
					+			 '<li ng-repeat="suggestion in occupancyCommentSuggestions">'
					+			   '<a href ng-click="setComment(suggestion.text)">{{suggestion.label}}</a>'
					+			 '</li>'
					+		   '</ul>'
					+		 '</div>'
					+		 '<div class="pull-right">'
					+		   '<button class="btn btn-sm btn-default" title="Abbrechen" ng-click="cancelEditing()"><i class="fa fa-times"></i></button>'
					+		   '<button class="btn btn-sm btn-primary" title="Speichern" ng-click="commit(comment)"><i class="fa fa-check"></i></button>'
					+		 '</div>'
					+           '</div>'
					+         '</form>'
					+       '</div>'
					+     '</div>'
					+   '</div>'
					+ '</div>'
					+ '</span>';
			    return str;
			}

			function nonEditModeTemplate(occupancy) {
			    var str = ''
					+ '<span class="occupancy-comment-icon">'
					+   '<a href class="btn btn-link" ng-click="toggleTooltip($event)">'
					+     '<i class="fa fa-fw fa-lg ' + (occupancy.comment ? 'fa-comment':'fa-comment-o') + '"></i>'
					+   '</a>'
					+ '<div class="tooltip top-right" role="tooltip" ng-if="tooltipVisible">'
					+   '<div class="tooltip-arrow"></div>'
					+   '<div class="tooltip-inner">'
					+     '<div>'
					+       '<span>' + (occupancy.comment || 'Kein Kommentar') + '</span>'
					+     '</div>'
					+   '</div>'
					+ '</div>'
					+ '</span>';
			    return str;
			}

			function link(scope, element, attrs) {

			    function render() {
					if ( dataFactory.getOccupancyEditStatus(scope.occupancy, scope.editMode) ) {
						element.html( editModeTemplate(scope.occupancy) );
						$compile( element.contents() )(scope);
					} else {
						element.html( nonEditModeTemplate( scope.occupancy) );
						$compile( element.contents() )(scope);
					}
			    }

			    scope.trainingPost = scope.$eval(attrs.trainingPost);
			    scope.occupancy = scope.$eval(attrs.occupancy);
			    scope.maxLengthComment = ServerRemoteConfigFactory.getConstraints().occupancy.maxLengthComment;
			    scope.editMode = scope.$eval(attrs.editMode);
			    scope.editing = false;
			    scope.tooltipVisible = false;
			    scope.render = render;
			    scope.occupancyCommentSuggestions = SERVER_CONFIG.occupancyCommentSuggestions;
			    scope.comment = scope.occupancy.comment;
			    render();
			}

			return {
			    restrict: 'E',
			    link: link,
			    scope: {
				trainingPost: '=',
				occupancy: '=',
				editMode: '='
			    },
				controller: ['$scope', function( $scope ) {

					$scope.toggleTooltip = function($event) {
						$event.preventDefault();
						$event.stopPropagation();
						$scope.tooltipVisible = !$scope.tooltipVisible;
					};

					$scope.$on('OverviewEditModeChanged', function (event,data) {
						$scope.editMode = data;
						$scope.render();
					});

					$scope.$on('OccupancyUpdates', function (event,data) {
						if ( data[ $scope.trainingPost.id ]  ) {
						    angular.forEach(data[$scope.trainingPost.id], function (occ) {
							if (occ.occupancy.id === $scope.occupancy.id) {
								$scope.occupancy = occ.occupancy;
								$scope.render();
							}
						    });
						}
					});

					$scope.startEditing = function($event) {
						$event.preventDefault();
						$event.stopPropagation();
						$scope.editing = true;
					};

					$scope.deleteComment = function($event) {
						$event.preventDefault();
						$event.stopPropagation();
						$scope.commit(null);
					};

					$scope.cancelEditing = function() {
						$scope.tooltipVisible = false;
						$scope.editing = false;
						$scope.render();
					};

					$scope.commit = function( newComment ) {
						if (newComment !== $scope.occupancy.comment) {
						    var updatedOccupany = {
							id:         	$scope.occupancy.id,
							allocation: 	$scope.occupancy.allocation,
							maxAllocation:	$scope.trainingPost.maxAllocation, // use tainingpost to make sure maxAllocation is always up to date
							comment:    	$scope.occupancy.comment,
							from:       	$scope.occupancy.from,
							to:         	$scope.occupancy.to,
							revocation: 	$scope.occupancy.revocation,
							trainee:    	$scope.occupancy.trainee,
							status:     	$scope.occupancy.status
						    };

						    updatedOccupany.comment = newComment;

						    var occupancyUpdatePromise = dataFactory.updateOccupancy(updatedOccupany);

						    // If Server Response then update List
						    occupancyUpdatePromise.$promise.then(function (result) {
							if (result.ok) {
								dataFactory.forcePolling();
							}
							$scope.tooltipVisible = false;
							$scope.editing = false;
							$scope.comment = newComment;
							$scope.render();
						    });
						}
						else {
						    // cancel
						}
						$scope.tooltipVisible = false;
						$scope.editing = false;
					};

					$scope.setComment = function( comment ) {
						$scope.comment = comment;
					};
			    }]
			};
		}
	])

    .directive('asvOccupancyStatus', ['SERVER_CONFIG','$compile', '$log', function(SERVER_CONFIG,$compile,$log) {

	    function getOccupancyIcon(occupancy) {

            var iconValues = {
	            'icon' : '',
	            'iconState' : ''
            };

            var severity = null;

            if (occupancy.validation && occupancy.validation.status === 'COMPLETED') {
                severity = occupancy.validation.maxSeverity;

	            if (SERVER_CONFIG.violationCodes[severity] === "FATAL") {
	                iconValues.icon = 'fa-minus-circle';
	                iconValues.iconState = 'error';
	            }
	            else if (SERVER_CONFIG.violationCodes[severity] === "ERROR") {
	                iconValues.icon = 'fa-times-circle';
	                iconValues.iconState = 'error';
	            }
	            else if (SERVER_CONFIG.violationCodes[severity] === "WARNING") {
	                iconValues.icon = 'fa-exclamation-circle';
	                iconValues.iconState = 'warning';
	            }
	            else if (SERVER_CONFIG.violationCodes[severity] === "INFO") {
	                iconValues.icon = 'fa-info-circle';
	                iconValues.iconState = 'info';
	            }
	            else if (SERVER_CONFIG.violationCodes[severity] === "OK") {
	                iconValues.icon = 'fa-check-circle';
	                iconValues.iconState = 'ok';
	            }
			}
			else if (occupancy.validation && occupancy.validation.status === 'PENDING') {
				iconValues.icon = 'fa-circle-o-notch fa-spin';
	            iconValues.iconState = 'validating';
			}
			else {
				iconValues.icon = 'fa-circle-o-notch fa-spin';
                iconValues.iconState = '';
			}


            var status = occupancy.status;

			if (occupancy.revocation === true && SERVER_CONFIG.occupancyStateCodes[status] === "DRAFT"
				|| SERVER_CONFIG.occupancyStateCodes[status] === "DELETE") {
				iconValues.icon = 'fa-ban';
				iconValues.iconState = 'deleted';
            }

			return iconValues;
        }

        function getTemplate( occupancy ) {
	        var iconValues = getOccupancyIcon(occupancy);
			var strVar='';
			strVar += '<span class="status-icon occupancy-status-icon active ' + iconValues.iconState + '" data-tooltip-html-unsafe="' + getViolationsPopoverContent(occupancy) + '" data-tooltip-placement="right">';
			strVar += '<i class="fa fa-fw fa-lg ' + iconValues.icon + '" ></i>';
			strVar += '</span>';
			return strVar;
        }

        function getViolationsPopoverContent(occupancy) {
			var a = "";
			if (angular.isObject(occupancy.validation)) {
				if (occupancy.validation.violations && occupancy.validation.violations.length > 0) {
				    a = "<ul class='list-group list-group-medium'>";
				    angular.forEach(occupancy.validation.violations, function (violation) {
					var severityIcon = '';

					if (SERVER_CONFIG.violationCodes[violation.severity] === "FATAL") {
						severityIcon = 'fa-minus-circle';
					}
					else if (SERVER_CONFIG.violationCodes[violation.severity] === "ERROR") {
						severityIcon = 'fa-times-circle';
					}
					else if (SERVER_CONFIG.violationCodes[violation.severity] === "WARNING") {
						severityIcon = 'fa-exclamation-circle';
					}
					else if (SERVER_CONFIG.violationCodes[violation.severity] === "INFO") {
						severityIcon = 'fa-info-circle';
					}

					a = a + "<li class='list-group-item'><i class='fa fa-fw fa-lg pull-left " + severityIcon + "'></i>" + violation.message + "</li>\n";
				    });
				    a = a + "</ul>";
				}
				else if (occupancy.displayValues.noEdit) {
				    a = "<ul class='list-group'><li class='list-group-item'><i class='fa fa-fw fa-ban fa-lg pull-left'></i>Meldung wird widerrufen.</li></ul>";
				}
				else if (occupancy.validation.status === 'PENDING') {
					a = "<ul class='list-group'><li class='list-group-item'><i class='fa fa-fw fa-circle-o-notch fa-spin fa-lg pull-left'></i>Meldung wird überprüft.</li></ul>";
				}
				else {
				    a = "<ul class='list-group'><li class='list-group-item'><i class='fa fa-fw fa-check-circle fa-lg pull-left'></i>Meldung ist korrekt.</li></ul>";
				}
			}
			return a;
        }

        function link(scope, element, attrs) {
			function render() {
				element.html( getTemplate(scope.occupancy) );
				$compile( element.contents() )(scope);
			}

			scope.occupancy = scope.$eval(attrs.occupancy);
			scope.render = render;
			scope.getViolationsPopoverContent = getViolationsPopoverContent;
			scope.getOccupancyIcon = getOccupancyIcon;
			render();
        }

        return {
			restrict: 'E',
			link: link,
			scope: {
				occupancy: '='
			},
			controller: ['$scope', function( $scope ) {

				$scope.$on('ValidationUpdates', function (event, data) {
				    if (data[$scope.occupancy.id]
				    		&& (!$scope.lastStatus || $scope.lastStatus !== data[$scope.occupancy.id].status) ) {
						$scope.lastStatus = data[$scope.occupancy.id].status;
						$scope.occupancy.validation = data[$scope.occupancy.id];
						$scope.render();
				    }
				});
			}]
        };
    }])

    ;

})();
