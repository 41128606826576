(function(){
    'use strict';

    angular.module('RemoteConfig',[
    	'ngResource',
    	'LocalConfig','FilterFunctions','Security'
    ])

    .factory('ServerRemoteConfigFactory', [
	'$resource', '$log', '$rootScope',
	'FilterFunctionsService', 'UrlConfigFactory',
        function($resource, $log, $rootScope, FilterFunctionsService, UrlConfigFactory){

            // Init Vars
            var serverConfig = {};

            // Settings
            var dataList = null;

            // Get Resource for List
            var serverConfigResource = UrlConfigFactory.getRemoteConfigResource();

            // Check if is already loaded
            serverConfig.isLoaded = function() {
            	return (dataList !== null);
            };

            /* array of callbacks to call when dataList is loaded, they are added by calls to doWithRemoteConfig(callback) calls */
            var callbackArray = [];

            // Update List from Server
            serverConfig.loadData = function() {
            	if (!serverConfig.isLoaded()) {
	                serverConfigResource.get(
	                    function( result ) {
                            dataList = result;
                            angular.forEach(callbackArray, function(callback) {
                                callback();
                            });
                            callbackArray = [];
                            $rootScope.$broadcast('RemoteConfigLoaded');
                        },
                        function() {
                            $log.error("Failed to load App-Data");
                        }
	                );
            	}
            };

            serverConfig.doWhenRemoteConfigLoaded = function( callback ) {
                if (dataList !== null) {
                    callback();
                } else {
                    callbackArray.push( callback );
                }
            };

			// Reset List
            serverConfig.clean = function() {
            	dataList = null;
            };

            // Get Methods
            serverConfig.getSubjects = function() {
                return dataList.subjects;
            };

            serverConfig.getSubjectById = function(idKey) {
                return FilterFunctionsService.getObjectById(dataList.subjects, idKey, 'id');
            };

            serverConfig.getGradeOfRecognitionById = function(idKey) {
            	return FilterFunctionsService.getObjectById(dataList.recognitionTypes, idKey, 'id');
            };
            
            serverConfig.getSubjectValueById = function(idKey, idName, valueName) {
                return FilterFunctionsService.getObjectValueById(dataList.subjects, idKey, idName, valueName);
            };

            serverConfig.getTrainingPostTypes = function() {
                return dataList.trainingPostTypes;
            };

            serverConfig.getTrainingPostTypeById = function(idKey) {
                return FilterFunctionsService.getObjectById(dataList.trainingPostTypes, idKey, 'id');
            };

            serverConfig.getTrainingPostTypeValueById = function(idKey, idName, valueName) {
                return FilterFunctionsService.getObjectValueById(dataList.trainingPostTypes, idKey, idName, valueName);
            };

            serverConfig.getOrgUnitTypes = function() {
                return dataList.orgUnitTypes;
            };

            serverConfig.getVersion = function() {
            	return dataList.version;
            };

            serverConfig.getAllocationSteps = function() {
				return dataList.allocationSteps;
			};

            serverConfig.getSystemName = function() {
                return dataList.systemName;
            };

            serverConfig.getSystemNameClass = function() {
                return dataList.systemNameClass;
            };

            serverConfig.getConstraints = function() {
                return dataList.constraints;
            };

            serverConfig.getPiwikId = function() {
                return dataList.piwikId;
            };

            serverConfig.isPiwikEnabled = function() {
                return dataList.piwikEnabled;
            };

            // Return Service
            return serverConfig;
        }
    ])

	// Holds all Settings for User from Security Module
    .factory('UserRemoteConfigFactory', [
    	'$rootScope',
    	'UserServiceFactory',
		function ($rootScope,UserServiceFactory) {
			var authInfo = UserServiceFactory.getAuthInfo();
			var userConfig = {};

			return {
				setEditMode : function(editMode) {
					userConfig.editMode = editMode;
					$rootScope.$broadcast('OverviewEditModeChanged', userConfig.editMode);
				}
			};

		}
	])

    ;
})();
