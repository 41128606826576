import '../ait/Ait'
import './Commons/Commons'
import './Analytics/Analytics'
import './Auth/Auth'
import './History/History'
import './Backend/Backend'
import './Components/Components'
import './Import/Import'
import './Overview/Overview'
import './Start/Start'

const styles = require('../../less/styles.less')

const angular = require('angular');

/*
const angularAnimate = require('angular-animate');
const angularAria = require('angular-aria');
const angularLoader = require('angular-loader');
const angularMessages = require('angular-messages');
const angularMocks = require('angular-mocks');
const angularSanitize = require('angular-sanitize');
const angularScenario = require('angular-scenario');
const angularTouch = require('angular-touch');
*/

const ngRoute = require('angular-route');
const ngResource = require('angular-resource');
const ngCookies = require('angular-cookies');
const ngIdle = require('ng-idle/angular-idle');
const moment = require('moment');
const amMoment = require('angular-moment');
const momentTimezone = require('moment-timezone');
const uiBBootstrap = require('angular-ui-bootstrap/ui-bootstrap-tpls');

console.log('starting ...');

// ASV APP Main
(function(){
	'use strict';

	// Init ASV App
	angular.module('AsvApp',[
		// Angular modules
		'ngRoute', 'ngIdle','angularMoment', 'ui.bootstrap', 'ngCookies','LinkGenerator',
        'Security', 'LocalConfig', 'RemoteConfig', 'History','Analytics',
        'AsvUI', 'Overview', 'Login', 'UserAdmin', 'TrainingPostApplication', 'Start', 'Import'
	])

	// ASV Routing Configuration
	.config([
		'$routeProvider', '$locationProvider', 'ROUTE_CONFIG',
		function($routeProvider, $locationProvider, ROUTE_CONFIG) {
			$locationProvider.hashPrefix('');

			for (var key in ROUTE_CONFIG) {
				var route = ROUTE_CONFIG[key];
				$routeProvider.when(route.path, route);
				if (typeof(route.isDefault) !== 'undefined' && route.isDefault) {
					$routeProvider.otherwise({
						redirectTo : route.path
					});
				}
			}
		}
	])

	.config(['KeepaliveProvider', 'IdleProvider', function(KeepaliveProvider, IdleProvider) {
		IdleProvider.idle(3600);
		IdleProvider.timeout(60);
		KeepaliveProvider.interval(10);
	}])

	.factory('HttpJsonDateFormatter', [
		'$filter','amMoment',
		function($filter,amMoment) {
			var httpJsonDateFormatterFilter = {};

			amMoment.changeLocale("de");
			amMoment.changeTimezone("Europe/Vienna");

			var mom = moment;

			httpJsonDateFormatterFilter.convertDateStringsToDates = function (input) {
				// mh: todo correct this mess, still, works for now
				// Ignore things that aren't objects.
				if (typeof input !== "object") return input;
				for (var key in input) {
					if (!input.hasOwnProperty(key)) continue;

					var value = input[key];
					// Check for string properties which look like dates.
					if (angular.isDate(value)) {
						var formatted2 = $filter('date')(value, 'yyyy-MM-dd HH:mm');
						var x = mom.tz(formatted2, "Europe/Vienna");

						input[key] = x.format();

					} else if (typeof value === "object") {
						// Recurse into object
						httpJsonDateFormatterFilter.convertDateStringsToDates(value);
					}
				}
			};

			return httpJsonDateFormatterFilter;
		}
	])

	.config(['$httpProvider','HttpJsonDateFormatterProvider', function ($httpProvider,httpJsonDateFormatterProvider) {
		var httpJsonDateFormatter = httpJsonDateFormatterProvider.$get();
		$httpProvider.defaults.transformRequest.unshift(function(data){
			httpJsonDateFormatter.convertDateStringsToDates(data);
			return data;
		});
	}])

	.config(['$logProvider', 'APP_CONFIG' ,function($logProvider,APP_CONFIG){
	    $logProvider.debugEnabled(APP_CONFIG.debugOutput);
	}])

	// without this, angular >1.7 addes trasnform href="javscript:void()" to "
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|javascript):/);
    }])

	// Main Controller to handle global Stuff ouside the ng-view
	.controller('AsvController', [
		'$log', '$scope','$rootScope', '$locale', '$modal',
		'ServerRemoteConfigFactory', 'LinkGeneratorFactory', 'APP_CONFIG', 'ROUTE_CONFIG', 'UserServiceFactory','Idle','Keepalive','$location','$timeout', 'AnalyticsFactory',
		function ($log, $scope,$rootScope, $locale, $modal, ServerRemoteConfigFactory, LinkGeneratorFactory, APP_CONFIG, ROUTE_CONFIG, UserServiceFactory, Idle, Keepalive, $location, $timeout, AnalyticsFactory) {

			// load base app data
			ServerRemoteConfigFactory.loadData();

			//Init Vars
			$scope.linkGenerator = LinkGeneratorFactory;
			// Show Content
			$scope.showApp = false;
			// Set App Version + Name
			$scope.appName = APP_CONFIG.appName;
			$scope.authInfo = UserServiceFactory.getAuthInfo();
			$scope.systemName = null;
			$scope.systemNameClass = null;
			$scope.piwikId = null;
            $scope.currentYear = APP_CONFIG.currentYear;

			$scope.hasRole = function(roleName) {
				var userRoles = ($scope.authInfo ? $scope.authInfo.userRoles : null);
				if (!roleName || !userRoles) {
					return false;
				}
				for (var i = 0; i < userRoles.length; i++) {
					var role = userRoles[i].role;
					if (role.name === roleName) {
						return true;
					}
				}
				return false;
			};

			$scope.hasPermission = function(permission) {
				var userRoles = ($scope.authInfo ? $scope.authInfo.userRoles : null);
				if (!permission || !userRoles) {
					return false;
				}
				for (var i = 0; i < userRoles.length; i++) {
					var role = userRoles[i].role;
					for (var j = 0; j < role.permissions.length; j++) {
						if (permission === role.permissions[j]) {
							return true;
						}
					}
				}
				return false;
			};

			$scope.hasRole = UserServiceFactory.hasRole;
			// Set Locale to German
			$locale.id = "de-at";

			$rootScope.$on('RemoteConfigLoaded', function(event) {
				$scope.appVersion = ServerRemoteConfigFactory.getVersion();
				$scope.showApp = true;
				$scope.systemName = ServerRemoteConfigFactory.getSystemName();
				$scope.systemNameClass = ServerRemoteConfigFactory.getSystemNameClass();
				$scope.piwikId = ServerRemoteConfigFactory.getPiwikId();
				$scope.piwikEnabled = ServerRemoteConfigFactory.isPiwikEnabled();

				AnalyticsFactory.initializePiwik($scope.piwikId, $scope.piwikEnabled, ($scope === null || $scope.authInfo === null ? null: $scope.authInfo.emailAddress));

			});

			$rootScope.$on('AuthInfo', function(event) {
				$scope.authInfo = UserServiceFactory.getAuthInfo();

                if ( UserServiceFactory.hasRoleOEAEK() ||
                    UserServiceFactory.hasRoleLAEK() ||
                    UserServiceFactory.hasRoleSysAdmin()) {
                    Idle.setIdle( 3600 );
                    Idle.setTimeout( 60 );
                    Idle.watch();
                } else {
                    Idle.setIdle( 600 );
                    Idle.setTimeout( 60 );
                    Idle.watch();
                }
			});

			$rootScope.$on('ConnectionError', function(event) {
				// Open Modal

				var data = {};

				$modal.open({
					templateUrl: 'connection-error-dialog.html',
					controller: 'FatalErrorLogoutController',
					size: 'md',
					backdrop: 'static',
					keyboard: false,
					backdropClass: 'alert alert-danger',
					windowClass: 'modal-dialog-danger',
					resolve: {
						data: function () {
							return data;
						}
					}
				});

			});

			$rootScope.$on('FatalError', function(event) {
				// Open Modal

				var data = {};

				$modal.open({
					templateUrl: 'fatal-error-dialog.html',
					controller: 'FatalErrorLogoutController',
					size: 'md',
					backdrop: 'static',
					keyboard: false,
					backdropClass: 'alert alert-danger',
					windowClass: 'modal-dialog-danger',
					resolve: {
						data: function () {
							return data;
						}
					}
				});

			});

			$scope.editUserProfile = function() {
				var profile = UserServiceFactory.getAuthInfo();
				var copy = angular.copy(profile);
				var resolve = {
					profile: function () {
						return copy;
					},
					orgUnitFilter: function () {
						return null;
					},
					selectedOrgUnit: function () {
						return null;
					}
				};
				var dlg = $modal.open({
					templateUrl: '/modules/asv/Backend/UserAdmin/UserEditor.html',
					controller: 'UserEditorController',
					size: 'lg',
					backdrop: true,
					resolve: resolve
				});
				dlg.result.then(function(modified) {
					UserServiceFactory.setAuthInfo(modified);
				});
			};

			$scope.closeIdleModals = function() {
				if ($scope.warning) {
					$scope.warning.close();
					$scope.warning = null;
				}

				if ($scope.timedout) {
					$scope.timedout.close();
					$scope.timedout = null;
				}
			};

			$scope.$on('IdleStart', function() {
				$scope.closeIdleModals();

				$scope.warning = $modal.open({
					templateUrl: 'warning-dialog.html',
					windowClass: 'modal-danger'
				});
			});

			$scope.$on('IdleEnd', function() {
				$scope.closeIdleModals();
			});

			$scope.$on('IdleTimeout', function() {
				$scope.closeIdleModals();
				$scope.timedout = $modal.open({
					templateUrl: 'timedout-dialog.html',
					windowClass: 'modal-danger'
				});

				$timeout( new function() {
					$location.path('/logout');
				},1000 );

			});

			$scope.calcWatches = function() {
				var root = angular.element(document.getElementsByTagName('body'));

				var watchers = [];

				var f = function (element) {
					angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
						if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
							angular.forEach(element.data()[scopeProperty].$$watchers, function (watcher) {
								watchers.push(watcher);
							});
						}
					});

					angular.forEach(element.children(), function (childElement) {
						f(angular.element(childElement));
					});
				};

				f(root);

				// Remove duplicate watchers
				var watchersWithoutDuplicates = [];
				angular.forEach(watchers, function(item) {
					if(watchersWithoutDuplicates.indexOf(item) < 0) {
						watchersWithoutDuplicates.push(item);
					}
				});
			};
		}])

		.directive('asvdate', ['$filter','moment', function ($filter, moment) {

			var REGEX = /^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/;
			var REGEX2 = /^([0-3][0-9][0-3][0-9][1-2][0-9][0-9][0-9])$/;
			var REGEX3 = /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/;

			var checkDate = function(date) {
				var result = date;
				if (date) {
					if (angular.isDate(date)) {
						return result;
					} else if (REGEX.test(date) || REGEX2.test(date)) {
						result = new Date( moment(date, ["D.M.YYYY", "DDMMYYYY"]) );
						return result;
					} else if (REGEX3.test(date)) {
						result = new Date( moment(date,["YYYY-M-D","DDMMYYYY"]) );
						return result;
					} else {
						// if invalid, return undefined (no model update happens)
						return undefined;
					}
				}
				else return undefined;
			};

			return {
				require: 'ngModel',
				restrict: 'A',
				link: function (scope, element, attrs, ctrl) {
					ctrl.$parsers.unshift(function (viewValue) {
						if (viewValue !== null && viewValue !== "" && angular.isDefined(viewValue)) {
							var curDate = checkDate(viewValue);

							if (curDate) {
								ctrl.$setValidity('date', true);
								curDate.setHours(0,0,0,0);

								var maxDate = checkDate(scope.$eval(attrs.maxDate));
								if (maxDate) {
									maxDate.setHours(0,0,0,0);
								}
								var minDate = checkDate(scope.$eval(attrs.minDate));
								if (minDate) {
									minDate.setHours(0,0,0,0);
								}

								if (maxDate && (maxDate.getTime() < curDate.getTime())) {
									ctrl.$setValidity('maxDate', false);
								}
								else {
									ctrl.$setValidity('maxDate', true);
								}
								if (minDate && (minDate.getTime() > curDate.getTime())) {
									ctrl.$setValidity('minDate', false);
								}
								else {
									ctrl.$setValidity('minDate', true);
								}

								var tzdat = moment.tz(curDate, "Europe/Vienna");
								var formatted2 = tzdat.format();
								return formatted2;

							}
							else {
								ctrl.$setValidity('date', false);
								// if invalid, return undefined
								// (no model update happens)
								return undefined;
							}
						}
						else if (viewValue === null || viewValue === "" || !angular.isDefined(viewValue)){
							ctrl.$setValidity('maxDate', true);
							ctrl.$setValidity('minDate', true);
							ctrl.$setValidity('date', true);
							return null;
						}
						else {
							ctrl.$setValidity('maxDate', true);
							ctrl.$setValidity('minDate', true);
							ctrl.$setValidity('date', true);
							return undefined;
						}
					});
				}
			};
		}])

		// Directive to disable ngAnimate for certain Elements
		// Fixes Redraw Issues on already animated Elements
		.directive('noAnimate', ['$animate',
			function($animate) {
				return {
					restrict: 'A',
					link: function(scope, element, attrs) {
						$animate.enabled(false, element);
						scope.$watch(function() {
							$animate.enabled(false, element);
						});
					}
				};
			}
		])


		// Modal for TrainingPost Info
		.controller('FatalErrorLogoutController', [
			'$scope','$location','$modalInstance',
			function($scope,$location,$modalInstance) {
				$scope.ok = function() {
					$modalInstance.close();
					$location.path("/logout");
				};
			}
		])

	;
})();

// assumes profileDirectiveDigest(selector)
// use: findExpensiveDigest(selector1, selector2, ...);

/* global profileDirectiveDigest */
(function (window) {
	var performance = window.performance;

	function findExpensiveDigest() {
		if (typeof profileDirectiveDigest !== 'function') {
			throw new Error('cannot find profileDirectiveDigest function');
		}
		var selectors = Array.prototype.slice.call(arguments, 0);
		var durations = selectors.map(function (selector) {
			/* global performance */
			var started = performance.now();
			profileDirectiveDigest(selector);
			var takes = performance.now() - started;
			return takes;
		});
		var merged = selectors.map(function (selector, k) {
			return {
				selector: selector,
				takes: durations[k]
			};
		});
		merged = merged.sort(function (a, b) {
			return b.takes - a.takes;
		});

		console.table(merged);
	}

	window.findExpensiveDigest = findExpensiveDigest;
}(window));

(function (window) {

	function getDiff(start, end) {
		return (end - start).toFixed(4);
	}

	function profileDirectiveDigest(selector) {
		console.assert(selector && typeof selector === 'string', 'expected selector', selector);
		var el = document.querySelector(selector);
		console.assert(el, 'cannot find element with selector', selector);

		/* global angular */
		var ngEl = angular.element(el);
		var scope = ngEl.scope() || ngEl.isolateScope();

		console.assert(scope, 'cannot find scope from element', selector);
		scope.$digest();
	}

	window.profileDirectiveDigest = profileDirectiveDigest;
}(window));

/** IE 9 rotate animation support */
var looper;
var degrees = 0;

function rotateAnimationIE9(elClass, speed) {
	if (navigator.appName.indexOf("Internet Explorer") > -1 &&
			navigator.appVersion.indexOf("MSIE 9") > -1) {
		var elems = document.getElementsByClassName(elClass);
		for (var i = 0; i < elems.length; i++) {
			var elem = elems[i];
			if (elem.className.indexOf("ng-hide") === -1) {
				elem.style.msTransform = "rotate(" + degrees + "deg)";
				degrees += 7;
				if (degrees > 359) {
					degrees = 1;
				}
			}
		}
		looper = setTimeout('rotateAnimationIE9(\'' + elClass + '\', ' + speed + ')', speed);
	}
}

rotateAnimationIE9('fa-spin', 50);
