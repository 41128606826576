const angular = require('angular');

(function(){
    'use strict';

    angular.module('Messages', [ ])

	.factory('MessageFactory', ['$rootScope', '$sce',
		function($rootScope, $sce) {
		var _broadcast = function(event, severity, ticks, closeButton, messageText) {
			var payload = {
				"severity" : severity,
				"ticks" : ticks,
				"closeButton" : closeButton,
				"textAsHtml" : $sce.trustAsHtml(messageText),
				"messageText" : messageText
			};
			$rootScope.$broadcast(event, payload);
		};
	
		return {
			broadcast : function(event, severity, ticks, closeButton, messageText) {
				if (messageText) {
					if (messageText.constructor === Array) {
						for (var i = 0; i < messageText.length; i++) {
							_broadcast(event, severity, ticks, closeButton, messageText[i]);
						}
					} else {
						_broadcast(event, severity, ticks, closeButton, messageText);
					}
				}
			},
			success : function(messageText) {
				this.broadcast("MESSAGE_EVENT", "alert-success", 20, true, messageText);
			},
			info : function(messageText) {
				this.broadcast("MESSAGE_EVENT", "alert-info", 20, true, messageText);
			},
			warning: function(messageText) {
				this.broadcast("MESSAGE_EVENT", "alert-warning", 60, true, messageText);
			},
			error : function(messageText) {
				this.broadcast("MESSAGE_EVENT", "alert-danger", null, true, messageText);
			}
		};
	}])
	
	.controller('MessageController', ['$scope', '$interval',
		function($scope, $interval) {
		$scope.timerInterval = null;
		$scope.messages = [];
	
		$scope.removeMessage = function(message) {
			var index = $scope.messages.indexOf(message);
			if (index > -1) {
				$scope.messages.splice(index, 1);
			}
		};
	
		$scope.$on("$locationChangeSuccess", function(event, payload) {
			$scope.stopTimer();
			$scope.messages = [];
		});
	
		$scope.$on("MESSAGE_EVENT", function(event, payload) {
			var message = payload;
			var messageExists = false;
	
			// Check if same Message is already displayed
			for (var i = 0; i < $scope.messages.length; i++) {
	
				if (angular.equals(payload.messageText, $scope.messages[i].messageText)
						&& angular.equals(payload.severity, $scope.messages[i].severity)
					) {
					messageExists = true;
					//Increase Display Counter
					$scope.messages[i].count += 1;
					break;
				}
			}
	
			if (!messageExists) {
				message.count = 1;
				$scope.messages.push(message);
			}
	
			if (message.ticks && message.ticks > 0) {
				$scope.startTimer();
			}
		});
	
		$scope.startTimer = function() {
			if ($scope.timerInterval === null) {
				$scope.timerInterval = $interval(function() {
					var stopTimer = true;
					if ($scope.messages.length > 0) {
						for (var i = 0; i < $scope.messages.length; i++) {
							var message = $scope.messages[i];
							if (message.ticks) {
								message.ticks--;
								if (message.ticks <= 0) {
									$scope.messages.splice(i, 1);
								} else {
									stopTimer = false;
								}
							}
	
						}
					}
					if (stopTimer) {
						$scope.stopTimer();
					}
				}, 1000);
			}
		};
	
		$scope.stopTimer = function() {
			if ($scope.timerInterval === null) {
				$interval.cancel($scope.timerInterval);
				$scope.timerInterval = null;
			}
		};
	
		$scope.$on('$destroy', function() {
			// Make sure that the interval is destroyed too
			$scope.stopTimer();
		});
	}])
	
	.directive('messages',
		function() {
		return {
			restrict : 'A',
			templateUrl : '/modules/ait/Messages.html',
			controller : 'MessageController'
		};
	})
	
	.factory('ErrorInterceptor', ['MessageFactory',
		function (MessageFactory) {
		return {
			response : function(response) {
				if (response && response.data.ok
					&& response.data.ok === false) {
					if (response.data.errors)  {
						MessageFactory.error(response.data.errors);
					} else {
						MessageFactory.error("Es gab einen Fehler in der Verarbeitung Ihrer Anfrage. Bitte versuchen Sie es erneut, bzw. wenden Sie sich an einen Administrator");
					}
				}
				return response;
			}
		};
	}])
	
	.directive('ngConfirmClick', [
		function(){
			return {
				link: function (scope, element, attr) {
					var msg = attr.ngConfirmClick || "Are you sure?";
					var clickAction = attr.confirmedClick;
					element.bind('click',function (event) {
						if ( window.confirm(msg) ) {
							scope.$eval(clickAction);
						}
					});
			}
		};
	}])
	
	;

})();
