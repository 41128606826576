(function(){
	'use strict';

	/**
	 * Dieses Modul wird vom technischen Security Modul benötigt. Es muss die zwei Services 'SecurityContributorFactory'
	 * und 'AuthInterceptorContributorFactory' umfassen.
	 * Für weitere Informationen sehen Sie bitte in der untenstehenden Dokumentation bei den Methoden oder
	 * im Security.js nach.
	 */
	angular.module('SecurityContributor', [
		'ngRoute',
		'LocalConfig', 'RemoteConfig', 'Messages', 'Analytics'
	])

	.factory('SecurityContributorFactory', [
		'$http', '$log', '$location',
		'ROUTE_CONFIG', 'UrlConfigFactory', 'AnalyticsFactory',
		function ($http, $log, $location, ROUTE_CONFIG, UrlConfigFactory, AnalyticsFactory) {
			// private members	
			var checkPermissions = function(userRoles, neccessaryPermissions) {
				// wenn keine Permissions benötigt werden -> retourniere true
				if (typeof(neccessaryPermissions) === 'undefined') {
					return true;
				}
				// ansonsten prüfe ob der User mindestens eine Permission hat
				if (userRoles !== null) {
					for (var z = 0; z < neccessaryPermissions.length; z++) {
						for (var iRole = 0; iRole < userRoles.length; iRole++) {
							var userPermissions = userRoles[iRole].permissions;
							for (var iPerm = 0; iPerm < userPermissions.length; iPerm++) {
					            if (userPermissions[iPerm] === neccessaryPermissions[z]) {
					                return true;
					            }
							}
				        }
				    }
				}
			    return false;
			};

			// private members
			var checkRoles = function(userRoles, neccessaryRoles) {
				// wenn keine Roles benötigt werden -> retourniere true
				if (typeof(neccessaryRoles) === 'undefined') {
					return true;
				}
				// ansonsten prüfe ob der User mindestens eine Role hat
				if (userRoles !== null) {
					for (var z = 0; z < neccessaryRoles.length; z++) {
						for (var iRole = 0; iRole < userRoles.length; iRole++) {
				            if (userRoles[iPerm] === neccessaryRoles[z]) {
				                return true;
				            }
				        }
				    }
				}
				return false;
			};
			
			// public api
			return {

				// Wird bei jeder Änderung der Route aufgerufen, und prüft, ob der User die zu ladende Seite sehen darf
				rerouteIfNeeded : function(currRoute, authInfo) {
					var routeChangePermitted = true;
					// Wenn der der Zugriff eingeschränkt ist, prüfe user & ggf. Permissions
					if ( currRoute.access && !currRoute.access.isFree) {
						if (!authInfo ) {
							$location.path(ROUTE_CONFIG.login.path);
							routeChangePermitted = false;
						} else if (!checkPermissions(authInfo.userRoles, currRoute.access.permissions)
								|| !checkRoles(authInfo.userRoles, currRoute.access.roles)) {
							$location.path(ROUTE_CONFIG.noPermission.path);
							routeChangePermitted = false;
						}
					// Wenn der user bereits eingeloggt ist, aber die Login-Seite aufruft, leite auf die Übersichtsseite weiter
					} else if ($location.path() === ROUTE_CONFIG.login.path && authInfo ) {
						$location.path(ROUTE_CONFIG.start.path);
						routeChangePermitted = false;
					}

					if(routeChangePermitted) {
						//deferred execution -> damit exekutiert er erst, wenn JS-Engine keinen Event-Handler mehr hat
						AnalyticsFactory.trackUserNavigation($location.path(), authInfo !== null ? authInfo.emailAddress : null);
					}
				},

				// Callback, beim (externen) Setzen des Users (zB durch ein Login-Modul) (kann leer sein)
				onSetUser : function(user) {
				},

				// Callback, wenn der aktuelle User abgefragt wird (kann leer sein)
				onBeforeGetUser : function() {
				},

				// Liefert die Server-URL, welche den aktuell eingeloggten User zurückgibt
				getUserLookupUrl : function() {
					return UrlConfigFactory.getCurrentUserUrl();
				},

				// Holt das User Objekt aus der Antwort des Servers (von getUserLookupUrl())
				getUserFromLookupResponse : function(data) {
					return data.payload;
				}
			};
	}])

	.factory('AuthInterceptorContributorFactory', [
		'$location',
		'ROUTE_CONFIG', 'MessageFactory','$rootScope',
		function ($location, ROUTE_CONFIG, MessageFactory,$rootScope) {

			return {
				// Immer wenn ein HttpError zurückkommt, wird diese Funktion aufgerufen
				handleHttpError : function(response) {
					var httpStatusCode = response.status;
					if (httpStatusCode === 0) {
						$rootScope.$broadcast("ConnectionError", response);
					} else if (httpStatusCode === 500) {
						$rootScope.$broadcast("FatalError", response);
					} else if (httpStatusCode === 401) {
						MessageFactory.error('Die von Ihnen angeforderten Daten benötigen einen aktiven Login. Bitte melden Sie sich an und versuchen Sie es erneut.');
						$location.path(ROUTE_CONFIG.login.path);
					} else if (httpStatusCode === 404) {
						MessageFactory.error('Die am Server angeforderte Seite <strong>' + response.data.path + '</strong> existiert nicht. Bitte überprüfen Sie die Url. <br/> Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an Ihren Systemadministrator.');
					} else if (httpStatusCode === 403) {
						MessageFactory.error('Sie haben nicht die notwendigen Berechtigungen, um auf die von Ihnen angeforderten Daten zuzugreifen.');
					}
				}
			};
	}])

	;

})();
