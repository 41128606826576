(function(){
	'use strict';

	angular.module('Allocation', [
		// AIT modules
		'LocalConfig'
	])

	.directive('allocationInput', ['$compile', 'AllocationFactory', function($compile, AllocationFactory) {
		return {
			scope: {
				ngModel: '=',
				keydownCallback: '&onKeydown',
				okCallback: '&onOk',
				cancelCallback: '&onCancel'
			},
			require: 'ngModel',
			restrict: 'A',
			link: function(scope, element, attrs, ctrl) {
				if (ctrl) { // Don't do anything unless we have a model
					// check if we have got callback functions to call
					scope.hasKeydownCallback = false;
					scope.hasOkCallback = false;
					scope.hasCancelCallback = false;
					if ("onKeydown" in attrs) {
						scope.hasKeydownCallback = true;
					}
					if ("onOk" in attrs) {
						scope.hasOkCallback = true;
					}
					if ("onCancel" in attrs) {
						scope.hasCancelCallback = true;
					}
					if ("maxAllocation" in attrs) {
						scope.maxAllocation = attrs.maxAllocation;
					}

					ctrl.$parsers.push(function (value) {
						var maxValue = scope.maxAllocation; 
						var newValue = AllocationFactory.timeToMinutes(value);
						AllocationFactory.validate(ctrl, newValue, maxValue);
						return newValue;
					});

					ctrl.$formatters.push(function (value) {
						return AllocationFactory.minutesToTime(value);
					});

					// for keyboard control
					element.bind('keydown', function(event) {
						if (event.which === 13) { // needed for callback
							event.preventDefault();
							event.stopPropagation();
						}
						scope.keydown(event);
					} );

					var popupButton = angular.element('<span class="input-group-btn" allocation-selector></span>'); // TODO: correct duration
					  popupButton.attr({
						'ng-model': 'ngModel'
					  });
					var $popup = $compile(popupButton)(scope);
					// Prevent jQuery cache memory leak (template is now redundant after linking)
					popupButton.remove();

					element.after($popup);

					scope.$on('$destroy', function() {
						$popup.remove();
						element.unbind('keydown', scope.keydown);
					});

				}
			},
			controller: 'AllocationController'
		};

	}])

	.controller("AllocationController", ['$scope', '$timeout', 'AllocationFactory',
		function($scope, $timeout, AllocationFactory) {
			$scope.selected = 0;
			// Key event mapper
			$scope.keys = { 13:'enter', 27: 'esc', 32:'space', 37:'left', 38:'up', 39:'right', 40:'down' };
			$scope.hasFocus = false;

			$scope.keydown = function(event) {
				var reactedOnKey = false;
				var key = $scope.keys[event.which];

				if ($scope.hasFocus) {
					if (key === 'down') {
						if ($scope.marked > 25) {
							if ($scope.marked < 33) { // 25-32 hours -> 0 min
								$scope.marked = 36;
							} else if ($scope.marked < 36) { // 33-35 hours -> 15-45 min
								$scope.marked += 4;
							} else { // min -> 2-4 hours
								$scope.marked -= 34;
							}
						} else {
							$scope.marked += 10;
						}
					} else if (key === 'up') {
						if ($scope.marked > 35) { // 0-45 min -> 32-35 hours 
							$scope.marked -= 4;
						} else {
							$scope.marked -= 10;
							if ($scope.marked < 0) { // 0-9 hours -> 0-45 min
								$scope.marked += 44;
								if ($scope.marked < 36 || $scope.marked > 39) { // 0-1 || 6-9 hours -> 0 min
									$scope.marked = 36;
								}
							}
						}
					} else if (key === 'right') {
						$scope.marked++;
						if ($scope.marked > 39) { // 45 min -> 0 hours
							$scope.marked = 0;
						}
					} else if (key === 'left') {
						$scope.marked--;
						if ($scope.marked < 0) { // 0 hours -> 45 min
							$scope.marked = 39;
						}
					} else if (key === 'space') { // select
						if ($scope.marked > 35) { // minutes
							 if ($scope.hours < 35) {
								 $scope.setMinutes(($scope.marked - 36) * 15);
							 } else {
								 $scope.setMinutes(0);
							 }
						} else { // hours
							$scope.setHours($scope.marked);
							if ($scope.hours === 35) {
								$scope.setMinutes(0);
							}
						}
					} else if (key === 'enter') { // close and update model
						if ($scope.marked > 35) { // minutes
							 if ($scope.hours < 35) {
								 $scope.setMinutes(($scope.marked - 36) * 15);
							 } else {
								 $scope.setMinutes(0);
							 }
						} else { // hours
							$scope.setHours($scope.marked);
							if ($scope.hours === 35) {
								$scope.setMinutes(0);
							}
						}
						$scope._ok();
					} else if (key === 'esc') { // close
						$scope.setFocus(false);
					}
					reactedOnKey = true;
				} else { // if allocation popup is closed
					if (key === 'down') { // open
						$scope.setFocus(true);
						$scope.marked = 0;
						reactedOnKey = true;
					} else if (key === 'enter') { // needed for callback
						reactedOnKey = true;
					} else if (key === 'esc') { // needed for callback
						reactedOnKey = true;
					}
				}

				$scope.$digest();
				if (reactedOnKey) {
					event.preventDefault();
					event.stopPropagation();
					if ($scope.hasKeydownCallback) {
						$scope.keydownCallback({event : event});
					}
				}
			};

			$scope.value = "";
			$scope.hours = 0;
			$scope.minutes = 0;
			$scope.minHours = 0;

			// TODO: somehow the controller starts with maxAllocation undefined. use the watch to set it once its available
			$scope.$watch('maxAllocation', function (value) {
				if (value != null) {
					$scope.maxHours = Math.floor(Number.parseInt($scope.maxAllocation) / 60);
				}
			});

			$scope.setHours = function( value ) {
				$scope.hours = value;
				$scope.calcValue();
			};

			$scope.setMinutes = function( value ) {
				$scope.minutes = value;
				$scope.calcValue();
			};

			$scope.calcValue = function() {
				if ($scope.minutes > 59) $scope.minutes = 45;
				if ($scope.minutes < 0) $scope.minutes = 0;

				if ($scope.hours >= $scope.maxHours) {
					$scope.hours = $scope.maxHours;
					$scope.minutes = 0;
				}

				$scope.value = ( $scope.hours * 60 ) + ( $scope.minutes * 1 );
			};

			$scope.switchFocus = function() {
				$scope.setFocus(!$scope.hasFocus);
			}

			$scope.setFocus = function(newFocus) {
				$scope.hasFocus = newFocus;
				if ($scope.hasFocus) {
					$scope.reset();
				}
			};

			$scope.ok = function() {
				$scope._ok();
				if ($scope.hasOkCallback) {
					$scope.okCallback( { callbackValue : $scope.ngModel } );
				}
			};

			$scope._ok = function() {
				$scope.ngModel = AllocationFactory.timeToMinutes($scope.hours + ":" + $scope.minutes);
				$scope.hasFocus = false;
			};

			$scope.cancel = function() {
				$scope.hasFocus = false;
				$scope.reset();
				if ($scope.hasCancelCallback) {
					$scope.cancelCallback();
				}
			};

			$scope.reset = function() {
				$scope.hours = 0;
				$scope.minutes = 0;
				if ($scope.ngModel) {
					var model = AllocationFactory.minutesToHoursAndMinutes($scope.ngModel);
					if (model) {
						$scope.value = parseInt($scope.ngModel);
						$scope.hours = model.hours;
						$scope.minutes = model.minutes;
					} else {
						$scope.value = 0;
						$scope.hours = 0;
						$scope.minutes = 0;
					}
				} else {
					$scope.value = 0;
					$scope.hours = 0;
					$scope.minutes = 0;
				}
			};

			$scope.reset();
		}
	])

	.directive("allocationSelector", [function() {
		return {
			require: 'allocationInput',
			restrict: 'A',
			templateUrl: '/modules/asv/Components/AllocationSelectorTag.html'
		};
	}])

	.filter("allocationTimeAndPercent", ['$filter', function ($filter) {
		return function (input, max) {
			return $filter('allocationTime')(input) + "Std ( = " + $filter('allocationPercent')(input, max) + "% )";
		};
	}])

	.filter("allocationTime", ["AllocationFactory", function (AllocationFactory) {
		return function (input) {
			return AllocationFactory.minutesToTime(input);
		};
	}])

	.filter("allocationPercent", ["AllocationFactory", function (AllocationFactory) {
		return function (input, max) {
			return AllocationFactory.minutesToPercent(input, max);
		};
	}])

	.factory("AllocationFactory", [ '$log', '$filter', 'APP_CONFIG',
		 function($log, $filter, APP_CONFIG) {

		return {
			isNumeric : function(n) {
				return !isNaN(parseFloat(n)) && isFinite(n);
			},

			timeToMinutes : function(value) {
				if (value) {
					var sValue = value.toString();
					if (sValue.indexOf(":") > -1) {
						var time = sValue.split(":");
						if (this.isNumeric(time[0]) && this.isNumeric(time[1])) {
							// * 1 wird benötigt, da sonst eine String Concatination stattfindet!
							return ( time[0] * 60 ) + ( time[1] * 1 );
						}
					}
				}
				return undefined;
			},

			minutesToTime : function(value) {
				if (this.isNumeric(value)) {
					var time = this.minutesToHoursAndMinutes(value);
					return time.hours + ":" + (time.minutes < 10 ? "0" : "") + time.minutes;
				}
				return undefined;
			},

			minutesToPercent : function(value, max) {
				if (this.isNumeric(value)) {
					return $filter('number')((value * 100 / max), 1);
				}
				return undefined;
			},

			minutesToHoursAndMinutes : function(value) {
				if (this.isNumeric(value)) {
					var hours = Math.floor(value / 60);
					var minutes = Math.abs(value) % 60;
					if (value < 0 && minutes !== 0) {
						hours += 1;
					}
					return {hours: hours, minutes: minutes};
				}
				return {hours: 0, minutes: 0};
			},

			validate : function(ctrl, value, maxValue) {
				ctrl.$setValidity("numeric", true);
				ctrl.$setValidity("max", true);
				ctrl.$setValidity("min", true);
				ctrl.$setValidity("steps", true);
				if (value) {
					if (!this.isNumeric(value)) {
						ctrl.$setValidity("numeric", false);
					} else if (value > maxValue) {
						ctrl.$setValidity("max", false);
					} else if (value < APP_CONFIG.allocationTimeSteps) {
						ctrl.$setValidity("min", false);
					} else if (value % APP_CONFIG.allocationTimeSteps !== 0) {
						ctrl.$setValidity("steps", false);
					} else {
						// Do nothing
					}
				}
			},

			getOccupanciesSum : function(occupancies, currentDate) {
				var context = { sum: 0 };

				if (occupancies && occupancies.length > 0) {
					currentDate.setHours(0,0,0,0);
					angular.forEach( occupancies, function(occupancy, key) {
						var dateTo = new Date(occupancy.to);
						var dateFrom = new Date(occupancy.from);
						dateTo.setHours(0,0,0,0);
						dateFrom.setHours(0,0,0,0);
						if (occupancy && occupancy.revocation !== true && (!currentDate
								|| (dateFrom <= currentDate && (!occupancy.to || dateTo >= currentDate )))) {
							context.sum += occupancy.allocation;
						}
					}, context);
				}

				return context.sum;
			}
		};
	}])

	;

})();
